import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//import update from 'immutability-helper';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";
import queryString from 'query-string';
import {logger} from "../../shared/utility";

class AccountResetPassword extends Component {

    constructor(props) {
        super(props);

        //logger(this.props.location);
        const params = queryString.parse(this.props.location.search);
        const act = params.act ? params.act : null;
        const ri = params.ri ? params.ri : null;
        const ra = params.ra ? params.ra : null;

        this.state = {
            isLoading: false,
            user: {
                id: ri,
                resetAuth: ra
            },
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            formErrors: {},
            formValues: {
                new_password: '',
                confirm_new_password: '',
            }
        }
    }

    UNSAFE_componentWillMount() {
        this.initValidation();
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','ResetPassword',Sentry.Severity.Info);
        logger(this.state);
    }

    onFormChange = (key, event) => {
        const valueUpd = Object.assign({}, this.state.formValues);
        valueUpd[key] = event.target.value;
        const errorUpd = this.validateField(key, event.target.value);

        this.setState({
            formValues: valueUpd,
            formErrors: errorUpd
        });
    }

    // set up validation
    initValidation = () => {
        this.validationSettings = {
            new_password: [this.requiredCheck, this.minlengthCheck.bind(this, 6)],
            confirm_new_password: [this.requiredCheck],
        }
    }

    // validation
    validateField = (key, value) => {
        let invalid = false;
        if (this.validationSettings[key] !== undefined) {
            for(let i = 0; i < this.validationSettings[key].length; i++) { // interate on validators
                const validator = this.validationSettings[key][i];
                invalid = invalid || validator.call(this, value);
            }
        }
        let errorUpd = {};
        errorUpd[key] = invalid;
        return errorUpd;
    }

    // validation check functions
    requiredCheck = (value) => {
        if (!!value) {
            return false;
        } else {
            return 'This field is required';
        }
    }

    minlengthCheck = (minlength, value) => {
        if (!value || value.length < minlength) {
            return 'Has to be at least ' + minlength + ' characters long';
        }
        else {
            return false;
        }
    }

    validateAllFields = () => {
        const errorsUpd = Object.assign({}, this.state.formErrors);
        const values = Object.assign({}, this.state.formValues);
        let allValid = true;

        for (let key in values) {
            errorsUpd[key] = this.validateField(key, values[key])[key];
            allValid = allValid && !errorsUpd[key];
        }

        this.setState({
            formErrors: errorsUpd
        });

        if (allValid) {
            // send to server
            this.updatePassword();
        }
    }

    updatePassword = () => {

        const data = {
            RequestAction: 'ResetPassword',
            user_id: this.state.user.id,
            reset_auth: this.state.user.resetAuth,
            new_password: this.state.formValues.new_password,
            confirm_password: this.state.formValues.confirm_new_password,
        };
        //console.log(data);
        mpoSentry.addBreadcrumb('action', data.RequestAction,Sentry.Severity.Info);

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response, this.props);
                if (response.data.ResponseCode === "SUCCESS") {
                    this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                    mobiscroll.toast({message: 'Password updated', color: 'success'});
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                }

            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
            });

    }

    render = () => {

        return (
            <React.Fragment>
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{`${process.env.REACT_APP_APP_TITLE} Account`}</title>
                        </Helmet>
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Reset Password</div>

                            <mobiscroll.Input
                                labelStyle="stacked"
                                value={this.state.formValues.new_password}
                                onChange={this.onFormChange.bind(this, "new_password")}
                                valid={!this.state.formErrors.new_password}
                                errorMessage={this.state.formErrors.new_password}
                                type="password"
                                passwordToggle={true}
                                autoComplete="new-password">New Password</mobiscroll.Input>

                            <mobiscroll.Input
                                labelStyle="stacked"
                                value={this.state.formValues.confirm_new_password}
                                onChange={this.onFormChange.bind(this, "confirm_new_password")}
                                valid={!this.state.formErrors.confirm_new_password}
                                errorMessage={this.state.formErrors.confirm_new_password}
                                type="password"
                                passwordToggle={true}>Confirm New Password</mobiscroll.Input>
                        </div>

                        <div className="mbsc-padding">
                            <mobiscroll.Button block={true} onClick={this.validateAllFields}>Save</mobiscroll.Button>
                        </div>
                    </div>
                </mobiscroll.Form>

                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountResetPassword));