import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";

export default class AccountNotifications extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Notifications',Sentry.Severity.Info);
    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_TITLE} Account`}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-empty">
                        <h3>Currently unavailable</h3>
                    </div>
                </mobiscroll.Form>

                <AccountBottomNav />
            </div>
        );
    }
}