
//import { logger, attachDeviceInfoToData, isCordova } from '../shared/utility';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from './Sentry';
import {attachDeviceInfoToData, isCordova} from "../shared/utility";
import {mpoOneSignal} from "./OneSignal";

export const mpoCheckout = {
    
    updateCheckout: (merchant_id, menu_id, checkout_options, callingComponent = 'CheckoutLib', callback = null) => {

        mpoSentry.addBreadcrumb('cartUpdate','mpoCheckout.updateCheckout',Sentry.Severity.Info);

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'update_checkout',
            store_id: merchant_id,
            menu_id: menu_id,
            checkout_options: checkout_options,
            component: callingComponent
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "SUCCESS") {
                // reload cart
                if (parseInt(response.data.Response.affects_cart_total, 10) === 1 && callback !== undefined && typeof(callback) === 'function') {
                    callback();
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }
        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error UC1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

};

