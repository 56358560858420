import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import { updateObject, logger, jsDateToday, unixDatetimeToDateAuEng, unixDatetimeToDate } from '../../shared/utility';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';

class DeliverySlot extends Component {

    constructor (props) {
        super(props);
        //logger("Checkout/DeliverySlot");
        //logger(props);

        this.state = {
            deliverySlotsOnDate: null,
            deliverySlotsCalendar: null,
            deliverySlotsInvalidDays: null,
            deliverySlotId: this.props.fulfilmentOptions.hasOwnProperty('deliverySlotId') ? this.props.fulfilmentOptions.deliverySlotId : 0,
            deliverySlotDesc: this.props.fulfilmentOptions.hasOwnProperty('deliverySlotDesc') ? this.props.fulfilmentOptions.deliverySlotDesc : ""
        }
    }

    onInit = (event, inst) => {
        //logger('DeliverySlot onInit');
        // logger(inst);
        // logger(inst.getVal(true));
        let datetime = null;
        let datetimeVal = null;

        if (this.props.fulfilmentOptions.datetimeVal === null) {
            const val = inst.getVal(true);
            datetime = val.getTime();
            datetimeVal = unixDatetimeToDateAuEng(datetime);
            this.props.updateStateWithFulfilmentOptions({
                ...this.props.fulfilmentOptions,
                asap: false,
                datetime: datetime,
                datetimeVal: datetimeVal
            });
        } else {
            datetime = this.props.fulfilmentOptions.datetime;
        }

        this.getDeliverySlots(datetime);
    }

    componentDidUpdate = (prevProps) => {
        //logger('DeliverySlot componentDidUpdate');
        if (prevProps.deliveryPostcodeId !== this.props.deliveryPostcodeId && this.props.deliveryPostcodeId > 0) {
            this.getDeliverySlots();
        }
    }

    onSet = (event, inst) => {
        const datetime = inst.getVal().getTime();
        const datetimeVal = event.valueText;

        this.props.updateStateWithFulfilmentOptions({
            ...this.props.fulfilmentOptions,
            asap: false,
            datetime: datetime,
            datetimeVal: datetimeVal
        });

        this.getDeliverySlots(datetime);
    }

    getDeliverySlots = (datetime = null) => {

        const data = {
            RequestAction: 'DeliverySlots',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            fulfilment_date: datetime === null ? unixDatetimeToDate(this.props.fulfilmentOptions.datetime) : unixDatetimeToDate(datetime),
            postcode_id: this.props.deliveryPostcodeId
        };

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    let validSlotId = false;
                    let deliverySlotId = this.state.deliverySlotId;
                    let deliverySlotDesc = this.state.deliverySlotDesc;
                    const deliverySlots = response.data.Response;
                    const deliverySlotsOnDate = deliverySlots.hasOwnProperty('slots_on_date') ? deliverySlots.slots_on_date : null;
                    const deliverySlotsCalendar = deliverySlots.hasOwnProperty('slots_calendar') ? deliverySlots.slots_calendar : null;
                    const deliverySlotsInvalidDays = deliverySlots.hasOwnProperty('slots_invalid_days') ? deliverySlots.slots_invalid_days : null;
                    if (deliverySlotId > 0 && deliverySlotsOnDate && deliverySlotsOnDate.length > 0) {
                        // check current slot id exists in new list of slots
                        for (let slot in deliverySlotsOnDate) {
                            if (parseInt(deliverySlotsOnDate[slot].delivery_slot_id,10) === parseInt(deliverySlotId,10)) {
                                validSlotId = true;
                                break;
                            }
                        }
                    }
                    if (!validSlotId) {
                        if (deliverySlotsOnDate && deliverySlotsOnDate.length > 0) {
                            deliverySlotId = deliverySlotsOnDate[0].delivery_slot_id;
                            deliverySlotDesc = deliverySlotsOnDate[0].display_label;
                        } else {
                            deliverySlotId = 0;
                            deliverySlotDesc = "";
                        }
                    }
                    const updatedState = updateObject(this.state, {
                        deliverySlotsOnDate: deliverySlotsOnDate,
                        deliverySlotsCalendar: deliverySlotsCalendar,
                        deliverySlotsInvalidDays: deliverySlotsInvalidDays
                    });
                    this.setState(updatedState);

                    if (deliverySlotId !== this.state.deliverySlotId || deliverySlotId !== this.props.fulfilmentOptions.deliverySlotId) {
                        this.setDeliverySlotId(deliverySlotId, deliverySlotDesc);
                    }
                } else {
                    let errorMsg = response.data.Response[0];
                    mobiscroll.toast({message: errorMsg, color: 'danger'});
                    mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);
                }
            })
        .catch(error => {
            logger(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    radioChange = (event) => {
        //logger(event);
        this.setDeliverySlotId(parseInt(event.target.value,10), event.target.getAttribute("data-slot-desc"));
    }

    setDeliverySlotId = (id, desc) => {
        const updatedState = updateObject(this.state, {
            deliverySlotId: id,
            deliverySlotDesc: desc
        });
        this.setState(updatedState);

        this.props.updateStateWithFulfilmentOptions({
            ...this.props.fulfilmentOptions,
            deliverySlotId: id,
            deliverySlotDesc: desc
        });
    }

    getDeliverySlotsJsx = () => {
        if (this.props.deliveryPostcodeId === 0) {
            return <div className="mbsc-padding">
                <span className="empty icon fas fa-info-circle" style={{color: "blue"}}></span> <span className="mbsc-txt-s">Specify your delivery address to see available delivery slots</span>
            </div> 
        }

        if (this.state.deliverySlotsOnDate === null || this.state.deliverySlotsOnDate.length === 0) {
            return <div className="mbsc-padding">
                <span className="empty icon fas fa-times-circle" style={{color: "red"}}></span> <span className="mbsc-txt-s">No delivery slots available for the chosen date</span>
            </div> 
        } else {
            const deliverySlots = this.state.deliverySlotsOnDate.map(slot => {
                return <mobiscroll.Radio key={slot.delivery_slot_id} name="delivery_slot" value={slot.delivery_slot_id} data-slot-desc={slot.display_label} checked={parseInt(this.state.deliverySlotId,10) === parseInt(slot.delivery_slot_id,10)} onChange={this.radioChange}>
                    {slot.display_label}
                    <span className="mbsc-desc">{slot.cut_off_time_str} {slot.available < 20 ? "(slot almost full)" : null}</span>
                </mobiscroll.Radio>
            });

            return <mobiscroll.FormGroup>
                <mobiscroll.FormGroupTitle>Delivery Slot</mobiscroll.FormGroupTitle>
                <div className="mbsc-padding" style={{paddingTop: 0}}>
                    <span className="mbsc-txt-s">Choose an available delivery slot on {this.props.fulfilmentOptions.datetimeVal}:</span>
                </div> 
                {deliverySlots}
            </mobiscroll.FormGroup>
        }
    }

    render = () => {

        const deliverySlotsJsx = this.getDeliverySlotsJsx();

        return (
            <mobiscroll.FormGroup inset className="mpo-checkout-form">

                    <mobiscroll.FormGroupTitle>Delivery Date</mobiscroll.FormGroupTitle>

                    <div className="mbsc-padding" style={{paddingTop: 0}}>
                        <span className="mbsc-txt-s">Choose an available delivery date</span>
                    </div> 

                    <mobiscroll.Calendar 
                        display="inline"
                        dateFormat="dd/mm/yy"
                        type="hidden"
                        controls={['calendar']}
                        yearChange={false}
                        min={jsDateToday()}
                        colors={this.state.deliverySlotsCalendar}
                        invalid={this.state.deliverySlotsInvalidDays}
                        onInit={this.onInit}
                        onSet={this.onSet}
                        value={this.props.fulfilmentOptions.datetimeVal}
                    />

                    {deliverySlotsJsx}

            </mobiscroll.FormGroup> 
        )

    }

}

const mapStateToProps = state => {
    return {
        merchant: state.menu.merchant,
        fulfilmentType: state.menu.fulfilmentType,
        fulfilmentOptions: state.menu.fulfilmentOptions,
        cart: state.cart,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCheckout: (checkout) => dispatch(actions.setCheckoutAction(checkout)),
        updateStateWithCustomer: (customer) => dispatch(actions.setCustomerAction(customer, null, null))
    }
}

//export default withRouter(DeliverySlot);
export default withRouter( connect(mapStateToProps, mapDispatchToProps)(DeliverySlot) );

