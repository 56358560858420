import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import {
    updateObject,
    logger,
    jsDateToday,
    unixDatetimeToDateAuEng,
    unixDatetimeToDate,
    attachDeviceInfoToData, isCordova
} from '../../shared/utility';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import {mpoOneSignal} from "../../lib/OneSignal";

class PickupLocation extends Component {

    constructor (props) {
        super(props);
        //logger("PickupLocation");
        //logger(props);

        this.state = {
            pickupLocationsOnDate: null,
            pickupLocationsCalendar: null,
            pickupLocationsInvalidDays: null,
            pickupLocationId: this.props.fulfilmentOptions.hasOwnProperty('pickupLocationId') ? this.props.fulfilmentOptions.pickupLocationId : 0,
            pickupLocationDesc: this.props.fulfilmentOptions.hasOwnProperty('pickupLocationDesc') ? this.props.fulfilmentOptions.pickupLocationDesc : ""
        }
    }

    onInit = (event, inst) => {
        //logger('PickupLocation onInit');
        // logger(inst);
        // logger(inst.getVal(true));
        let datetime = null;
        let datetimeVal = null;

        if (this.props.fulfilmentOptions.datetimeVal === null) {
            const val = inst.getVal(true);
            datetime = val.getTime();
            datetimeVal = unixDatetimeToDateAuEng(datetime);
            this.props.updateStateWithFulfilmentOptions({
                ...this.props.fulfilmentOptions,
                asap: false,
                datetime: datetime,
                datetimeVal: datetimeVal
            });
        } else {
            datetime = this.props.fulfilmentOptions.datetime;
        }

        this.getPickupLocations(datetime);
    }

    onSet = (event, inst) => {
        const datetime = inst.getVal().getTime();
        const datetimeVal = event.valueText;

        this.props.updateStateWithFulfilmentOptions({
            ...this.props.fulfilmentOptions,
            asap: false,
            datetime: datetime,
            datetimeVal: datetimeVal
        });

        this.getPickupLocations(datetime);
    }

    getPickupLocations = (datetime = null) => {

        const data = {
            RequestAction: 'PickupLocations',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            fulfilment_date: datetime === null ? unixDatetimeToDate(this.props.fulfilmentOptions.datetime) : unixDatetimeToDate(datetime)
        };

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    let validLocationId = false;
                    let pickupLocationId = this.state.pickupLocationId;
                    let pickupLocationDesc = this.state.pickupLocationDesc;
                    const pickupLocations = response.data.Response;
                    const pickupLocationsOnDate = pickupLocations.hasOwnProperty('locations_on_date') ? pickupLocations.locations_on_date : null;
                    const pickupLocationsCalendar = pickupLocations.hasOwnProperty('locations_calendar') ? pickupLocations.locations_calendar : null;
                    const pickupLocationsInvalidDays = pickupLocations.hasOwnProperty('locations_invalid_days') ? pickupLocations.locations_invalid_days : null;
                    if (pickupLocationId > 0 && pickupLocationsOnDate && pickupLocationsOnDate.length > 0) {
                        // check current location id exists in new list of locations
                        for (let location in pickupLocationsOnDate) {
                            if (parseInt(pickupLocationsOnDate[location].pickup_location_id,10) === parseInt(pickupLocationId,10)) {
                                validLocationId = true;
                                break;
                            }
                        }
                    }
                    if (!validLocationId) {
                        if (pickupLocationsOnDate && pickupLocationsOnDate.length > 0) {
                            pickupLocationId = pickupLocationsOnDate[0].pickup_location_id;
                            pickupLocationDesc = pickupLocationsOnDate[0].display_label;
                        } else {
                            pickupLocationId = 0;
                            pickupLocationDesc = "";
                        }
                    }
                    const updatedState = updateObject(this.state, {
                        pickupLocationsOnDate: pickupLocationsOnDate,
                        pickupLocationsCalendar: pickupLocationsCalendar,
                        pickupLocationsInvalidDays: pickupLocationsInvalidDays
                    });
                    this.setState(updatedState);

                    if (pickupLocationId !== this.state.pickupLocationId || pickupLocationId !== this.props.fulfilmentOptions.pickupLocationId) {
                        this.setPickupLocationId(pickupLocationId, pickupLocationDesc);
                    }
                } else {
                    let errorMsg = response.data.Response[0];
                    mobiscroll.toast({message: errorMsg, color: 'danger'});
                    mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);
                }
            })
        .catch(error => {
            logger(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    radioChange = (event) => {
        logger(event);
        this.setPickupLocationId(parseInt(event.target.value,10), event.target.getAttribute("data-location-desc"));
    }

    setPickupLocationId = (id, desc) => {
        const updatedState = updateObject(this.state, {
            pickupLocationId: id,
            pickupLocationDesc: desc
        });
        this.setState(updatedState);

        this.props.updateStateWithFulfilmentOptions({
            ...this.props.fulfilmentOptions,
            pickupLocationId: id,
            pickupLocationDesc: desc
        });

        // this.updateCheckout to validate pickup location
        this.updateCheckout({'pickup_location_id': id});
    }

    updateCheckout = (checkout_options) => {

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'update_checkout',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            checkout_options: checkout_options,
            component: 'PickupLocation'
        };
        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        //console.log('updateCheckout', data);
        axiosInstance.post(null, data)
            .then(response => {
                if (response.data.ResponseCode === "SUCCESS") {
                    if (parseInt(response.data.Response.affects_cart_total, 10) === 1) {
                        if (this.props.reloadCart !== undefined && typeof(this.props.reloadCart) === 'function') {
                            this.props.reloadCart();
                        }
                    }
                } else {
                    let errorMsg = response.data.Response[0];
                    mobiscroll.toast({message: errorMsg, color: 'danger'});
                    mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);

                    if (errorMsg.includes('Session expired')) {
                        this.props.updateStateWithCustomer({id: 0, status: 0});
                    }
                }
            })
            .catch(error => {
                logger(error);
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
            });

    }

    getPickupLocationsJsx = () => {
        if (this.state.pickupLocationsOnDate === null || this.state.pickupLocationsOnDate.length === 0) {
            return <div className="mbsc-padding">
                <span className="empty icon fas fa-times-circle" style={{color: "red"}}></span> <span className="mbsc-txt-s">No locations available for the chosen date</span>
            </div> 
        } else {
            const pickupLocations = this.state.pickupLocationsOnDate.map(location => {
                return <mobiscroll.Radio key={location.pickup_location_id} name="pickup_location" value={location.pickup_location_id} data-location-desc={location.display_label} checked={parseInt(this.state.pickupLocationId,10) === parseInt(location.pickup_location_id,10)} onChange={this.radioChange}>
                    {location.display_label}
                    <span className="mbsc-desc">{location.cut_off_time_str} {location.available < 20 ? "(almost full)" : null}</span>
                </mobiscroll.Radio>
            });

            return <mobiscroll.FormGroup>
                <mobiscroll.FormGroupTitle>Location</mobiscroll.FormGroupTitle>
                <div className="mbsc-padding" style={{paddingTop: 0}}>
                    <span className="mbsc-txt-s">Choose an available location on {this.props.fulfilmentOptions.datetimeVal}:</span>
                </div> 
                {pickupLocations}
            </mobiscroll.FormGroup>
        }
    }

    render = () => {

        const pickupLocationsJsx = this.getPickupLocationsJsx();

        return (
            <mobiscroll.FormGroup inset className="mpo-checkout-form">

                    <mobiscroll.FormGroupTitle>Order For Date</mobiscroll.FormGroupTitle>

                    <div className="mbsc-padding" style={{paddingTop: 0}}>
                        <span className="mbsc-txt-s">Choose an available date</span>
                    </div> 

                    <mobiscroll.Calendar 
                        display="inline"
                        dateFormat="dd/mm/yy"
                        type="hidden"
                        controls={['calendar']}
                        yearChange={false}
                        min={jsDateToday()}
                        colors={this.state.pickupLocationsCalendar}
                        invalid={this.state.pickupLocationsInvalidDays}
                        onInit={this.onInit}
                        onSet={this.onSet}
                        value={this.props.fulfilmentOptions.datetimeVal}
                    />

                    {pickupLocationsJsx}

            </mobiscroll.FormGroup> 
        )

    }

}

const mapStateToProps = state => {
    return {
        merchant: state.menu.merchant,
        fulfilmentType: state.menu.fulfilmentType,
        fulfilmentOptions: state.menu.fulfilmentOptions,
        cart: state.cart,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCheckout: (checkout) => dispatch(actions.setCheckoutAction(checkout)),
        updateStateWithCustomer: (customer) => dispatch(actions.setCustomerAction(customer, null, null))
    }
}

//export default withRouter(PickupLocation);
export default withRouter( connect(mapStateToProps, mapDispatchToProps)(PickupLocation) );

