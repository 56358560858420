import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../lib/Sentry';

export default class Terms extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Terms',Sentry.Severity.Info);
    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`Terms and Conditions - ${process.env.REACT_APP_APP_TITLE}`}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <h3>Trading Terms and Conditions</h3>
                    <div className="mbsc-txt-s">
                        {process.env.REACT_APP_APP_COMPANY && process.env.REACT_APP_APP_ABN ?
                            <p>{process.env.REACT_APP_APP_COMPANY} (ABN {process.env.REACT_APP_APP_ABN})</p>
                            :
                            <p>MYPREORDER PTY LTD (ABN 87 611 967 376)</p>
                        }
                        <p>These terms and conditions regulate the business relationship between you and us. When you use this App, you agree to be bound by these terms and conditions. No person under the age of 18 years may use our App or Services. If you are under 18, please ask an adult for help with your purchase.</p>
                        <ol>
                            <li>Definitions<br/>
                                <b>"Order"</b> means an order placed by us on your instructions and on your behalf of a product or products of your choosing from the menu of a Store where we can place such an order.<br/>
                                <b>"App"</b> means the entire computing hardware and software installation that is or supports our Website and mobile applications.<br/>
                                <b>"Services"</b> means any of the services we offer on our App including the placement of an Order on your behalf for the purchase of a product or products at a Store.<br/>
                                <b>"Store"</b> means any retail outlet, Store or business.
                            </li>
                            <li>Our contract with you
                                <ol type="a">
                                    <li>So far as the context allows, to you as a user of our App; and</li>
                                    <li>In any event to you as a buyer or prospective buyer of our Services.</li>
                                    <li>It is within our full and unfettered discretion to accept or reject your instruction to place an Order and a placement of an Order by us will be confirmed to you either on screen, via email, or via push notification, in the case of Orders submitted via our App. That is when our contract is made. Our message will also confirm the estimated time for collection or delivery of your Order.</li>
                                    <li>We cannot guarantee that every Service advertised on our App is available. All reasonable steps are taken to ensure that the Services, our App and servers are available at all time. The Services, our App or servers may be unavailable during scheduled maintenance or backups, or when unexpected issues occur. We will not be liable for any loss or inconvenience caused by any unavailability of the Services, our App or servers.</li>
                                    <li>We may change these terms from time to time. The terms that apply to you are those posted here on our Website on the day you order Services.</li>
                                </ol>
                            </li>
                            <li>Your account with us
                                <ol type="a">
                                    <li>You agree that you have provided, and will continue to provide accurate, up to date, and complete information about yourself. We need this information to provide you with the Services.</li>
                                    <li>If you use the App, you are responsible for maintaining the confidentiality of your account and password and for preventing any unauthorised person from using your computer or mobile device.</li>
                                    <li>You agree to accept responsibility for all activities that occur under your account or password. You should tell us immediately if you believe some person has accessed your account without your authority and also sign in to your account and change your password.</li>
                                </ol>
                            </li>
                            <li>Price, payment and service provision
                                <ol type="a">
                                    <li>All prices are quoted in Australian dollars and are inclusive of applicable taxes, if any, unless specified otherwise.</li>
                                    <li>Surcharges may apply to some payment types and online payment processing. Any surcharge set by us will not exceed the cost of processing the payment.</li>
                                    <li>You may not share or allow others to use the Services in your name.</li>
                                    <li>We will do our best to maintain our App so that you have constant use, but there will be times when your use may be interrupted. Interruption to the Services for reasonable periods for maintenance or causes beyond our control is not a ground for repayment of money you have paid. When we are aware of the likelihood of down time, we will tell you in advance.</li>
                                    <li>We will not be responsible or liable for:
                                        <ol type="i">
                                            <li>Accuracy of menu information, dietary information, or pricing provided by the Store.</li>
                                            <li>Accuracy of opening times provided by the Store.</li>
                                            <li>Any additional surcharges set by the Store.</li>
                                            <li>Orders that are inaccessible, delayed, lost, or incorrectly made by the Store.</li>
                                            <li>The quality of the product sold or prepared by the Store.</li>
                                            <li>You must contact the Store directly with any questions or concerns regarding any information provided in our App.</li>
                                        </ol>
                                    </li>
                                    <li>We may suspend or cancel your account should you be in breach of these terms, or otherwise cause a detrimental impact to our App, or other users, including but not limited to the act of spamming.</li>
                                </ol>
                            </li>
                            <li>Refund policy
                                <ol type="a">
                                    <li>You may only amend or cancel your Order with us prior to the Store commencing preparation of the Order.</li>
                                    <li>You must contact the Store directly and as soon as possible with any request to amend or cancel your Order, or to request a full or partial refund.</li>
                                    <li>Refunds are not applicable in the event that you fail to collect your Order or in the event that you provide an incorrect delivery address.</li>
                                    <li>Partial refunds can be processed in the event that an item or product in your Order is no longer available, out of stock, or is not as advertised.</li>
                                    <li>Full refunds will be available in the instance of major faults in order to comply with Australian Consumer Law.</li>
                                    <li>Refunds will be reimbursed to your payment method and confirmed via email.</li>
                                </ol>
                            </li>
                            <li>Disclaimers
                                <ol type="a">
                                    <li>We may make improvements or changes to our App, the content, or to any of the Services, at any time and without advance notice.</li>
                                    <li>You are advised that content of our App may include technical inaccuracies or typographical errors. This is inevitable in any large App. We would be grateful if you bring to our immediate attention, any that you find.</li>
                                    <li>We give no warranty and make no representation, express or implied, as to:
                                        <ol type="i">
                                            <li>the adequacy or appropriateness of the Services for your purpose;</li>
                                            <li>the truth of any content on our App published by someone other than us;</li>
                                            <li>any implied warranty or condition as to merchantability or fitness of the Services for a purpose other than that for which the Services are commonly used;</li>
                                        </ol>
                                    </li>
                                    <li>our App contains links to other Internet Apps outside our power and control. You acknowledge and agree that we shall not be liable in any way for the content of any such linked App, nor for any loss or damage arising from your use of any such App.</li>
                                    <li>We are not liable in any circumstances for special, indirect or consequential loss or any damages whatsoever resulting from loss of use, loss of data or loss of revenues or profits, whether in an action of contract, negligence or otherwise, arising out of or in connection with your use of our App or the purchase of Services.</li>
                                    <li>We are not liable in any circumstances for any injury, damage or consequential loss whatsoever associated with unwise, inappropriate or illegal use of our App, services or apps.</li>
                                    <li>We are not liable in any circumstances for the advertising or sale of items that require licence or permit to do so. The Store must hold a valid licence or permit from the appropriate authority to advertise for sale such items via our App. The Store is entirely responsible for complying with the terms of any licence or permit and for ensuring that purchasers and consumers also meet relevant requirements.</li>
                                    <li>In any event, including the event that any term or condition or obligation on our part (“Implied Term”) is implied into these conditions by law, then our liability is limited to the maximum extent permitted by law, to the value of the Services you have purchased.</li>
                                </ol>
                            </li>
                            <li>System Security
                                <ol type="a">
                                    <li>You agree that you will not, and will not allow any other person to violate or attempt to violate any aspect of the security of our App.</li>
                                    <li>You may not use any software tool for the purpose of extracting data from our App.</li>
                                    <li>You understand that any such violation is unlawful and that any contravention of law may result in criminal prosecution.</li>
                                </ol>
                            </li>
                            <li>Intellectual Property Rights
                                <ol type="a">
                                    <li>The material contained on our App is protected by copyright. You may use our App only for your personal and non-commercial purposes. Except to the extent permitted by relevant copyright legislation, you must not use, copy, modify, transmit, store, publish or distribute the material on our App, or create any other material using material on our App, without obtaining our prior written consent.</li>
                                    <li>The products, Services, Apps, technology and processes contained in our App may be the subject of other intellectual property rights owned by us. No licence is granted in respect of those intellectual property rights other than as set out in these Terms. Your use of our App must not in any way infringe the intellectual property rights of any person.</li>
                                    <li>We will defend the intellectual property rights in connection with the Services and our App, including copyright whether provided by us or by any other content provider (including copyright in text, graphics, logos, icons, images, audio clips, digital downloads, data, and software).</li>
                                    <li>We also claim copyright in the designs and compilation of all content of our App-title and shall remain the sole property of us and / or the other content provider.</li>
                                    <li>Except as set out below, you may not copy, modify, publish, transmit, transfer or sell, reproduce, create derivative works from, distribute, perform, display, or in any way exploit any of the content of our App, in whole or in part.</li>
                                    <li>You may not use our name or logos or trade marks or any other content on any App of yours or that of any other person.</li>
                                    <li>Subject to the other terms of this agreement, you may download or copy content of our App only for your own personal use, provided that you maintain all copyright and other notices contained in it. You may not store electronically any significant portion of any content of our App.</li>
                                </ol>
                            </li>
                            <li>Your email address
                                <ol type="a">
                                    <li>You represent that any username or email address selected by you, when used alone or combined with a second or third level domain name, does not interfere with the rights of any third party and has not been selected for any unlawful purpose.</li>
                                    <li>You acknowledge and agree that if we believe such selection does interfere with the rights of any third party or is being selected for any unlawful purpose, we may immediately suspend the use of such name or email address, and you will indemnify us for any claim or demand that arises out of your selection.</li>
                                    <li>You acknowledge and agree that we shall not be liable to you in the event that we are ordered or required by a court or judicial authority, to desist from using or permitting the use of a particular domain name as part of a name or email address.</li>
                                </ol>
                            </li>
                            <li>Indemnity
                                <ol type="a">
                                    <li>You agree to indemnify us against any claim or demand, including reasonable lawyers’ fees, made by any third party due to or arising in any way out of your use of our App, your posting any material, or the infringement by you, or by any other person using your computer, of any intellectual property or other right of any person.</li>
                                </ol>
                            </li>
                            <li>Miscellaneous provisions
                                <ol type="a">
                                    <li>Nothing in this agreement or on our App shall confer on any third party any benefit or obligation.</li>
                                    <li>If any of these terms is at any time held to be void, invalid or unenforceable, then it shall be treated as changed or reduced, only to the extent minimally necessary to bring it within the laws of that jurisdiction and to prevent it from being void and it shall be binding in that changed or reduced form. Subject to that, each provision shall be interpreted as severable and shall not in any way affect any other of these terms.</li>
                                    <li>No waiver by us, in exercising any right, power or provision in this agreement shall operate as a waiver of any other right or of that same right at a future time; nor shall any delay in exercise of any power or right be interpreted as a waiver.</li>
                                    <li>In the event of a dispute arising out of or in connection with these terms or any contract between you and us, then you agree to attempt to settle the dispute by engaging in good faith with us in a process of mediation before commencing arbitration or litigation.</li>
                                    <li>We are not liable for any breach of our obligations resulting from causes beyond our reasonable control including strikes of our own employees.</li>
                                    <li>This Agreement shall be governed by and construed in accordance with the laws of Western Australia.</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </mobiscroll.Form>
            </div>
        );
    }
}