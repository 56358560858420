import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import {isCordova, nl2br, openWindow} from "../shared/utility";

const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
//const isResellerApp = process.env.REACT_APP_RESELLER_APP === 'true';

export default class Footer extends Component {

    render = () => {
        //console.log(this.props);
        if (!this.props.hasOwnProperty('merchant')) {
            return null;
        }
        return (
            <div className="mbsc-grid" style={{bottom: 0, borderTop: "1px solid #e5e5e5", background: "#f7f7f7", marginTop: "40px"}}>
                <div className="mbsc-row" style={{maxWidth: "720px", margin: "auto"}}>
                    <div className="mbsc-col-12 mbsc-col-xs-12 mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-6">
                        <h4>{this.props.merchant.name}</h4>
                        {this.props.merchant.address1 ?
                            <p><span className="empty icon fas fa-map-marker-alt" style={{color: "grey"}}></span> {this.props.merchant.address1} {this.props.merchant.suburb}</p>
                            : null }
                        {this.props.merchant.phone ?
                            <p><span className="empty icon fas fa-phone" style={{color: "grey"}}></span> <a href={"tel:"+this.props.merchant.phone.split( " ").join("").split( "(").join("").split( ")").join("")}>{this.props.merchant.phone}</a></p>
                            : null }
                    </div>
                    {this.props.merchant.hasOwnProperty('menus') && this.props.merchant.menus.length > 0 ?
                    <div className="mbsc-col-12 mbsc-col-xs-12 mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-6">
                        <h4>Opening Times</h4>
                        <p dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.menus[0].hours)}}></p>
                    </div>
                        : null}
                </div>
                {isCustomApp ?
                <div className="mbsc-row">
                    <div className="mbsc-col-12 mbsc-txt-muted mbsc-txt-s" style={{textAlign: "center"}}>
                        {isCordova() ?
                            <p>App by <a href="#" onClick={(e) => {
                                e.preventDefault();
                                openWindow("https://www.mypreorder.com.au", '_system', '');
                            }}>mypreorder</a> - online ordering and branded apps</p>
                            :
                            <p>Web App by <a href="https://www.mypreorder.com.au" target="_blank">mypreorder</a> - online ordering and branded apps</p>
                        }
                    </div>
                </div>
                    : null }
            </div>
        );
    }
};
