import { logger } from '../shared/utility';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from './Sentry';

export const mpoPayPal = {
    _inapp_browser: null,
    _inapp_browser_result: null,
    
    _in_app_browser_load_error: function(event) {
        logger('mpoPayPal._in_app_browser_load_error() called');
        logger(event);
        mpoSentry.captureMessage(event.message, Sentry.Severity.Warning);
        //if (ConfigGlobal.Debug()) { displayAlert(event.message, 'Load Error'); }
    },

    _in_app_browser_load_stop: function(event) {
        logger('mpoPayPal._in_app_browser_load_stop() called');
        logger(event);
        if (event.url.indexOf('paypal_success.php') >= 0) {
            //set some kind of topup result flag and show success after close browser
            mpoPayPal._inapp_browser_result = 'pp_success';
            mpoPayPal._inapp_browser.close();
        } else if (event.url.indexOf('paypal_cancel.php') >= 0) {
            //set some kind of topup result flag and display result
            mpoPayPal._inapp_browser_result = 'pp_cancel';
            mpoPayPal._inapp_browser.close();
        } else if (event.url.indexOf('paypal_error.php') >= 0) {
            //set some kind of topup result flag and display result
            mpoPayPal._inapp_browser_result = 'pp_error';
            mpoPayPal._inapp_browser.close();
        }
    },

    _in_app_browser_exit: function(event) {
        // use as template:
        logger('mpoPayPal._in_app_browser_exit() called');
        logger(event);
        mpoPayPal._inapp_browser.removeEventListener('loadstop', mpoPayPal._in_app_browser_load_stop);
        mpoPayPal._inapp_browser.removeEventListener('loaderror', mpoPayPal._in_app_browser_load_error);
        mpoPayPal._inapp_browser.removeEventListener('exit', mpoPayPal._in_app_browser_exit);
        mpoPayPal._inapp_browser = null;

        logger('mpoPayPal._inapp_browser_result = ' + mpoPayPal._inapp_browser_result);
        switch (mpoPayPal._inapp_browser_result) {
            case 'pp_success':
                //all good, show success
                //mobiscroll.toast({message: 'Thank you for your payment', color: 'success'});
                //this.getAccount();
                break;
            case 'pp_cancel':
                //nothing to do, user knows they cancelled
                //mpoSentry.captureMessage('Action cancelled', Sentry.Severity.Warning);
                //mobiscroll.toast({message: 'Action cancelled', color: 'info'});
                break;
            case 'pp_error':
                //fetch and display error to user
                //var msg = "An error occurred while attempting to authenticate with PayPal";
                //mpoSentry.captureMessage(msg, Sentry.Severity.Warning);
                //mobiscroll.toast({message: msg, color: 'error'});
                break;
            default:
                //var msg = "An unknown result was specified";
                //mpoSentry.captureMessage(msg, Sentry.Severity.Warning);
                //mobiscroll.toast({message: msg, color: 'error'});
        }
        mpoPayPal._inapp_browser_result = null;
        //reset payment progress state
    }

}