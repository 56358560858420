import {isProduction, logger, unixDatetimeToDateTime} from '../shared/utility';
import axiosInstance from '../components/axios';
import axios from "axios"; // use axios directly for linkly api calls
import { v4 as uuidv4 } from "uuid";
import mobiscroll from "@mobiscroll/react";
import {mpoSentry} from "./Sentry";
import * as Sentry from "@sentry/browser";
import Cookies from 'universal-cookie';

const TOKEN = "TOKEN";
const PAIR = "PAIR";
const TXN = "TXN";
const REPRINT_RECEIPT = "REPRINT_RECEIPT";
const TXN_STATUS = "TXN_STATUS";
const TXN_STATUS_INFLIGHT = "TXN_STATUS_INFLIGHT";
const PINPAD_STATUS = "PINPAD_STATUS";
const SETTLEMENT = "SETTLEMENT";
const CANCEL = "CANCEL";

const pairingPath = isProduction() ? "https://auth.cloud.pceftpos.com/v1/pairing/cloudpos" :
    "https://auth.sandbox.cloud.pceftpos.com/v1/pairing/cloudpos";
const tokenRequestPath = isProduction() ? "https://auth.cloud.pceftpos.com/v1/tokens/cloudpos" :
    "https://auth.sandbox.cloud.pceftpos.com/v1/tokens/cloudpos";
const linklyEndpoint = isProduction() ? "https://rest.pos.cloud.pceftpos.com/v1/sessions" :
    "https://rest.pos.sandbox.cloud.pceftpos.com/v1/sessions/";
const posName = isProduction() ? "mypreorder pos" :
    "React REST POS";
const posVendorId = isProduction() ? "9035cad7-1004-49fb-9f70-24f26ba6e2f0" :
    "482c12c7-4506-482e-a05a-761c113c9a40";
const requestHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
};
const transactionCookieName = "linklytxn";

export const mpoLinkly = {

    _available: false,
    _pos_guid: "",
    _secret: "",
    _paired: false, // paired once we have secret AND token

    _operator: "",
    _token: "",
    _token_expiry: null,
    _last_session_id: null,
    _last_transaction_ref: null,

    _cut_receipt: "0",
    _receipt_auto_print: "0",

    _country_code: null,
    _currency_code: null,
    _tender_amount: 0,
    _total_amount: 0,
    _callback: null,
    _compare_transaction: null,

    Init: function (pos_guid, secret, op_id, op_name, country_code, currency_code) {
        logger("LINKLY INIT -- " + (isProduction() ? "PRODUCTION" : "SANDBOX"))
        mpoLinkly._pos_guid = pos_guid;
        mpoLinkly._secret = secret;
        mpoLinkly._operator = op_id+"|"+op_name;
        mpoLinkly._country_code = country_code;
        mpoLinkly._currency_code = currency_code;
        mpoLinkly._available = mpoLinkly._pos_guid !== "";
    },

    isLinklyAvailable: function () {
        logger('mpoLinkly.isLinklyAvailable: '+mpoLinkly._available);
        return mpoLinkly._available;
    },

    isPaired: function () {
        logger('mpoLinkly.isPaired: '+mpoLinkly._paired);
        return mpoLinkly._paired;
    },

    getExponentialBackoffMs: function (retry_num = 1) {
        let ms;
        switch (retry_num) {
            case 1:
                ms = 1000;
            case 2:
                ms = 2000;
            case 3:
                ms = 4000;
            case 4:
                ms = 8000;
            case 5:
                ms = 16000;
            default:
                ms = 32000;
        }
        logger('mpoLinkly.getExponentialBackoffMs '+ms+' Retry#'+retry_num)
        return ms;
    },

    updateBackendSettings: function() {
        logger('mpoLinkly.updateSettings called');
        const data = {
            RequestAction: 'UpdateEftpos',
            pos_guid: mpoLinkly._pos_guid,
            payload: {
                secret: mpoLinkly._secret,
                oauth_token: mpoLinkly._token,
                oauth_expiry: unixDatetimeToDateTime(mpoLinkly._token_expiry),
            }
        };
        //attachDeviceInfoToData(data);

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                }

            })
            .catch(error => {
                logger(error);
                mobiscroll.toast({message: 'Error EFT1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });

    },

    setCallback: function (src, callback = null) {
        logger('mpoLinkly.setCallback called from '+src+' '+(callback === null ? "null" : typeof(callback)));
        mpoLinkly._callback = callback;
    },

    sendPairRequest: function (params, callback = null) {
        logger("mpoLinkly.sendPairRequest called");
        logger(params);
        mpoLinkly._paired = false;
        axios
            .post(
                pairingPath,
                {
                    Username: params.username,
                    Password: params.password,
                    PairCode: params.paircode,
                },
                requestHeaders
            )
            .then((response) => {
                logger("SETTING SECRET");
                mpoLinkly._secret = response.data.secret;
                mpoLinkly._pos_guid = params.pos_guid;
                mpoLinkly._available = mpoLinkly._pos_guid !== "";
                mpoLinkly.returnResult(true, response, PAIR, callback);
            })
            .catch((error) => {
                //console.log("error :>> ", error);
                mpoLinkly.returnResult(false, error, PAIR, callback);
            });
    },

    setToken: function (token, token_expiry) {
        logger("mpoLinkly.setToken called");
        if (token && token !== null && token !== "" && token_expiry && token_expiry !== null &&
            parseInt(token_expiry,10) > 0 && Date.now()+300000 < token_expiry) {
            mpoLinkly._token = token;
            mpoLinkly._token_expiry = token_expiry;
            mpoLinkly._paired = true;
            logger('mpoLinkly.setToken OK '+token_expiry);
            return true;
        }
        return false;
   },

    getToken: function (callback = null) {
        logger("mpoLinkly.getToken called");
        //logger("secret :>> "+ mpoLinkly._secret);
        axios
            .post(
                tokenRequestPath,
                {
                    Secret: mpoLinkly._secret,
                    PosName: posName,
                    PosVersion: isProduction() ? process.env.REACT_APP_API_VERSION : "1.0.0",
                    PosId: mpoLinkly._pos_guid,
                    PosVendorId: posVendorId,
                },
                requestHeaders
            )
            .then((response) => {
                //console.log("response :", response);
                logger("PAIRED ... SETTING TOKEN");
                mpoLinkly.setToken(response.data.token, Date.now() + response.data.expirySeconds * 1000);
                mpoLinkly.updateBackendSettings();
                mpoLinkly.returnResult(true, response, TOKEN, callback);
            })
            .catch((error) => {
                //console.log("error :>> ", error);
                mpoLinkly.returnResult(false, error, TOKEN, callback);
            });
    },

    getStatusCodeFromResponse: function(response) {
        const status_code = typeof response === "object" && response.hasOwnProperty('status') && response.status !== undefined && response.status !== null && parseInt(response.status, 10) > 0 ? parseInt(response.status, 10) : 0;
        logger('mpoLinkly.getStatusCodeFromResponse: ' + status_code);
        return status_code;
    },

    returnResult: function(success, response, type, callback = null) {
        logger('mpoLinkly.returnResult called: '+type+' - '+success);
        logger(response);
        //logger(callback);
        //logger(mpoLinkly._callback);

        callback = (callback !== undefined && callback !== null && typeof callback === "function") ? callback : mpoLinkly._callback;
        // do not clear the callback that a TXN will be relying upon
        if (type !== TXN_STATUS_INFLIGHT) {
            mpoLinkly.setCallback('returnResult');
        }

        if (callback !== undefined && callback !== null && typeof(callback) === 'function') {
            // success at this point means non-error response received from linkly, still need to check contents of response for whether the action was actually successful
            if (success) {
                const status_code = mpoLinkly.getStatusCodeFromResponse(response);

                let data = "";
                //let action_success = false;
                if (response.hasOwnProperty('data') && response.data !== undefined && response.data !== null) {
                    data = response.data;
                    // if (data.hasOwnProperty('response') && data.response !== undefined &&
                    //     data.response.hasOwnProperty('success') && data.response.success !== undefined) {
                    //     action_success = data.response.success === true;
                    // }
                    if (typeof data === "object" && (type === TXN || type === TXN_STATUS || type === TXN_STATUS_INFLIGHT)) {
                        if (data.hasOwnProperty('sessionId') === false) {
                            data['sessionId'] = mpoLinkly._last_session_id;
                        }
                        data['posGuid'] = mpoLinkly._pos_guid;
                    }
                }

                if (type !== TXN_STATUS_INFLIGHT) {
                    if (type === TXN_STATUS) {
                        callback(success, status_code, data, mpoLinkly._compare_transaction);
                    } else {
                        callback(success, status_code, data);
                    }
                }

            } else {
                // response is error text
                logger("Error for "+type+": "+response);
                if (type !== TXN_STATUS_INFLIGHT) {
                    callback(success, 0, response);
                }
            }
        } else {
            logger("No callback specified for "+type);
        }
    },

    setTransactionCookie: function (request) {
        logger('mpoLinkly.setTransactionCookie called');
        const cookies = new Cookies();
        const maxAge = parseInt(process.env.REACT_APP_SESSION_MINS,10)*60;
        request['sessionId'] = mpoLinkly._last_session_id;
        request['posGuid'] = mpoLinkly._pos_guid;
        cookies.set(transactionCookieName, JSON.stringify(request), {maxAge: maxAge});
        return true;
    },

    getTransactionCookie: function () {
        logger('mpoLinkly.getTransactionCookie called');
        const cookies = new Cookies();
        const request = cookies.get(transactionCookieName);
        logger(request);
        if (request) {
            return request; //object
        }
        return null;
    },

    deleteTransactionCookie: function () {
        logger('mpoLinkly.deleteTransactionCookie called');
        const cookies = new Cookies();
        cookies.remove(transactionCookieName);
        return true;
    },

    sendRequest: function (request, type) {
        logger("mpoLinkly.sendRequest called: "+type);
        //logger(mpoLinkly._token_expiry);
        //logger(Date.now()+300000);
        //todo: test
        if (mpoLinkly._token === undefined || mpoLinkly._token === null || mpoLinkly._token === "" || Date.now()+300000 > mpoLinkly._token_expiry) {
            logger('mpoLinkly.sendRequest: REFRESH TOKEN');
            mpoLinkly.getToken((success, status_code, response) => {
                if (status_code === 200) {
                    mpoLinkly.sendRequest(request, type);
                } else if (status_code === 408) {
                    // single retry
                    mpoLinkly.getToken((success, status_code, response) => {
                        if (status_code === 200) {
                            mpoLinkly.sendRequest(request, type);
                        } else {
                            mpoLinkly.returnResult(false, response, TOKEN);
                        }
                    });
                } else {
                    mpoLinkly.returnResult(false, response, TOKEN);
                }
            });
            return null;
        }

        // Add a request interceptor
        axios.interceptors.request.use(
            (config) => {
                config.headers["Authorization"] = "Bearer " + mpoLinkly._token;
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );

        let tempSessionId = uuidv4();

        let requestType;
        switch (type) {
            case TXN:
                requestType = "/transaction?async=false";
                break;
            case REPRINT_RECEIPT:
                requestType = "/reprintreceipt?async=false";
                break;
            case TXN_STATUS:
            case TXN_STATUS_INFLIGHT:
                requestType = "/transaction?async=false";
                tempSessionId = mpoLinkly._last_session_id;
                break;
            case PINPAD_STATUS:
                requestType = "/status?async=false";
                break;
            case SETTLEMENT:
                requestType = "/settlement?async=false";
                break;
            case CANCEL:
                requestType = "/sendkey?async=false";
                tempSessionId = mpoLinkly._last_session_id;
        }

        const uri = linklyEndpoint + tempSessionId + requestType;
        //console.log("request :", request);

        //setTxnInProgress(true);

        if (type === TXN_STATUS || type === TXN_STATUS_INFLIGHT) {
            logger(type+' of '+tempSessionId);
            return axios
                .get(uri)
                .then((response) => {
                    //console.log("response :", response);
                    //setTxnInProgress(false);
                    // const selectedData = {
                    //     Status: response.status,
                    //     Message: response.statusText,
                    // };
                    //setTxnResponse(selectedData);
                    mpoLinkly.returnResult(true, response, type);
                })
                .catch((error) => {
                    //console.log("ERROR ", error);
                    //setTxnInProgress(false);
                    //setTxnResponse(error);
                    mpoLinkly.returnResult(false, error, type);
                });
        }

        mpoLinkly._last_session_id = tempSessionId;

        if (type === TXN) {
            // Accreditation 3.1.1, 3.1.2, 4.1.1, 4.1.2
            // set cookie with request data so can get last transaction after power failure mid-transaction
            // check for this cookie on app start and checkout load
            mpoLinkly.setTransactionCookie(request);
        }

        return axios
            .post(
                uri,
                {request} //, txnHeaders
            )
            .then((response) => {
                //console.log("response :", response);
                mpoLinkly.returnResult(true, response, type);
            })
            .catch((error) => {
                //console.log("ERROR :", error);
                mpoLinkly.returnResult(false, error, type);
            });
    },

    getPinPadStatus: function (callback) {
        logger("mpoLinkly.getPinPadStatus called");
        if (mpoLinkly.isLinklyAvailable()) {
            if (mpoLinkly.isPaired()) {
                const request = {
                    Merchant: "00",
                    Application: "00",
                    StatusType: "0"
                };
                mpoLinkly.setCallback('getPinPadStatus', callback);
                mpoLinkly.sendRequest(request, PINPAD_STATUS);
            } else {
                //alert('Eftpos (Linkly) is not paired (MSG-LPPS2). Contact a member of staff for assistance.');
                mpoLinkly.returnResult(false, 'Eftpos (Linkly) is not paired (MSG-LPPS2)', PINPAD_STATUS, callback);
            }
        } else {
            //alert('Eftpos (Linkly) is not currently available (MSG-LPPS1)');
            mpoLinkly.returnResult(false, 'Eftpos (Linkly) is not currently available (MSG-LPPS1)', PINPAD_STATUS, callback);
        }
    },

    getTransactionStatus: function (compare_transaction = null, callback = null) {
        logger("mpoLinkly.getTransactionStatus called");
        mpoLinkly._compare_transaction = compare_transaction;
        if (mpoLinkly.isLinklyAvailable()) {
            if (mpoLinkly.isPaired()) {
                if (mpoLinkly._last_session_id === null || mpoLinkly._last_session_id === "") {
                    if (compare_transaction && compare_transaction.hasOwnProperty('sessionId') && compare_transaction.sessionId !== undefined && compare_transaction.sessionId !== "") {
                        mpoLinkly._last_session_id = compare_transaction.sessionId;
                    } else {
                        //alert('Eftpos (Linkly) sessionId not specified (MSG-LTS3). Contact a member of staff for assistance.');
                        mpoLinkly.returnResult(false, 'Eftpos (Linkly) sessionId not specified (MSG-LTS3). Contact a member of staff for assistance.', TXN_STATUS, callback);
                        return;
                    }
                }
                mpoLinkly.setCallback('getTransactionStatus', callback);
                mpoLinkly.sendRequest({}, TXN_STATUS);
            } else {
                //alert('Eftpos (Linkly) is not paired (MSG-LTS2). Contact a member of staff for assistance.');
                mpoLinkly.returnResult(false, 'Eftpos (Linkly) is not paired (MSG-LTS2). Contact a member of staff for assistance.', TXN_STATUS, callback);
            }
        } else {
            //alert('Eftpos (Linkly) is not currently available (MSG-LTS1). Contact a member of staff for assistance.');
            mpoLinkly.returnResult(false, 'Eftpos (Linkly) is not currently available (MSG-LTS1). Contact a member of staff for assistance.', TXN_STATUS, callback);
        }
    },

    getTransactionStatusInflight: function () {
        logger("mpoLinkly.getTransactionStatusInflight called");
        // DO NOT USE setCallback or returnResult. We do not want to overwrite mpoLinkly._callback and mess up an inflight transaction response
        if (mpoLinkly.isLinklyAvailable() && mpoLinkly.isPaired()) {
            mpoLinkly.sendRequest({}, TXN_STATUS_INFLIGHT);
        }
    },

    printReceipt: function (total_amount, txn_ref, callback = null) {
        logger("mpoLinkly.printReceipt called");
        if (mpoLinkly.isLinklyAvailable()) {
            if (mpoLinkly.isPaired()) {
                const total_amount_int = parseInt(parseFloat(total_amount*100),10);
                const request = {
                    Merchant: "00",
                    Application: "00",
                    ReceiptAutoPrint: mpoLinkly._receipt_auto_print,
                    CutReceipt: mpoLinkly._cut_receipt,
                    ReprintType: "1",
                    OriginalTxnRef: txn_ref,
                    PurchaseAnalysisData: {
                        OPR: mpoLinkly._operator,
                        AMT: total_amount_int.toString(),
                        PCM: "0000",
                    }
                };
                mpoLinkly.setCallback('printReceipt', callback);
                mpoLinkly.sendRequest(request, REPRINT_RECEIPT);
            } else {
                //alert('Eftpos (Linkly) is not paired (MSG-LPR2). Contact a member of staff for assistance.');
                mpoLinkly.returnResult(false, 'Eftpos (Linkly) is not paired (MSG-LPR2). Contact a member of staff for assistance.', REPRINT_RECEIPT, callback);
            }
        } else {
            //alert('Eftpos (Linkly) is not currently available (MSG-LPR1)');
            mpoLinkly.returnResult(false, 'Eftpos (Linkly) is not currently available (MSG-LPR1). Contact a member of staff for assistance.', REPRINT_RECEIPT, callback);
        }
    },

    generateRef: function () {
        // return random 16 char ref
        const tempRef = uuidv4(); // '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
        return tempRef.substr(0,8)+tempRef.substr(9,4)+tempRef.substr(14,4); // '9b1deb4d3b7d4bad'
    },

    commencePayment: function (tender_amount, total_amount, desc, callback) {
        logger("mpoLinkly.commencePayment called");
        if (mpoLinkly.isLinklyAvailable()) {
            if (mpoLinkly.isPaired()) {
                if (tender_amount > 0 && total_amount > 0 && tender_amount <= total_amount) {
                    mpoLinkly._tender_amount = parseInt(parseFloat(tender_amount*100),10);
                    mpoLinkly._total_amount = parseInt(parseFloat(total_amount*100),10);
                    mpoLinkly._last_transaction_ref = mpoLinkly.generateRef();
                    mpoLinkly.setCallback('commencePayment', callback);

                    const request = {
                        Merchant: "00",
                        TxnType: "P",
                        AmtPurchase: mpoLinkly._tender_amount,
                        //amtCash,
                        TxnRef: mpoLinkly._last_transaction_ref,
                        CurrencyCode: mpoLinkly._currency_code,
                        CutReceipt: mpoLinkly._cut_receipt,
                        ReceiptAutoPrint: mpoLinkly._receipt_auto_print,
                        Application: "00",
                        PurchaseAnalysisData: {
                             OPR: mpoLinkly._operator,
                             AMT: mpoLinkly._total_amount.toString(),
                             PCM: "0000",
                        }
                        // Basket: {
                        //     id: "t39kq18134553",
                        //     amt: 2145,
                        //     tax: 200,
                        //     dis: 50,
                        //     sur: 0,
                        //     items: [
                        //         {
                        //             id: "t39kq002",
                        //             sku: "k24086723",
                        //             qty: 2,
                        //             amt: 2145,
                        //             tax: 200,
                        //             dis: 50,
                        //             name: "XData USB Drive",
                        //         },
                        //     ],
                        // },
                    };

                    mpoLinkly.sendRequest(request, TXN);
                } else {
                    //alert('Invalid tender amount (MSG-LCP3)');
                    mpoLinkly.returnResult(false, 'Invalid tender amount (MSG-LCP3)', TXN, callback);
                }
            } else {
                //alert('Eftpos (Linkly) is not paired (MSG-LCP2). Contact a member of staff for assistance.');
                mpoLinkly.returnResult(false, 'Eftpos (Linkly) is not paired (MSG-LCP2). Contact a member of staff for assistance.', TXN, callback);
            }
        } else {
            //alert('Eftpos (Linkly) is not currently available (MSG-LCP1). Contact a member of staff for assistance.');
            mpoLinkly.returnResult(false, 'Eftpos (Linkly) is not currently available (MSG-LCP1). Contact a member of staff for assistance.', TXN, callback);
        }
    }

}
