import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import { validateCardCSC } from '../../shared/validate';
import { updateObject, logger, isCordova, attachDeviceInfoToData, isGoogleMapsLoaded } from '../../shared/utility';
import AccountBottomNav from '../../components/account/BottomNav';
import { mpoAccount } from '../../lib/Account';
import { mpoOneSignal } from '../../lib/OneSignal';
import { client as braintree } from 'braintree-web';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import {Helmet} from "react-helmet";

const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';

class AccountAddCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            toppingUp: false,
            placesSearchOptions: {
                componentRestrictions: {'country': ['AU','NZ']},
                types: ['address']
            },
            account: {},
            customer: {},
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            topup: {
                balance: "0.00",
                balance_debit: "0.00",
                balance_transferable: "0.00",
                amount: "30.00",
                payment_method_id: "CC",
                billing_address: "",
                billing_address_id: 0,
                card_name: "",
                card_number: "",
                card_expiry: "",
                card_expiry_date: "",
                card_csc: "",
                save_card: true,
                auto_topup: true
            }
        };
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','AddCard',Sentry.Severity.Info);
        mpoAccount.getAccount(this.onGetAccount, 'AddCard');
        if (!isGoogleMapsLoaded()) {
            mobiscroll.toast({message: 'Google maps not loaded, try again', color: 'danger'});
            mpoSentry.captureMessage('Google maps not loaded', Sentry.Severity.Warning);
        }
    }

    onGetAccount = (response) => {

        if (response.data.ResponseCode === "AUTH") {
            this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
        } else if (response.data.ResponseCode === "SUCCESS") {
            if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', Sentry.Severity.Warning);
            } else {
                //console.log(this.state);
                this.props.updateStateWithCustomer(response.data.Response.customer, null);

                let defaultPaymentMethodId = "CC";

                this.setState({
                    isLoading: false,
                    customer: response.data.Response.customer,
                    account: response.data.Response.account,
                    topup: {
                        balance: response.data.Response.account.currency_sign+parseFloat(response.data.Response.customer.balance).toFixed(2),
                        balance_debit: response.data.Response.account.currency_sign+parseFloat(response.data.Response.customer.balance_debit).toFixed(2),
                        amount: response.data.Response.customer.auto_topup_amount !== "0.00" ? response.data.Response.customer.auto_topup_amount : "30.00",
                        payment_method_id: defaultPaymentMethodId,
                        billing_address: "",
                        billing_address_id: response.data.Response.customer.billing_addresses.length > 0 ? parseInt(response.data.Response.customer.billing_addresses[0].id,10) : 0,
                        card_name: "",
                        card_number: "",
                        card_expiry: "",
                        card_expiry_date: "",
                        card_csc: ""
                    },
                    modules: {
                        standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                        rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                        topup: response.data.Response.account.is_topup_enabled === 1 && response.data.Response.account.payment_methods.length > 0,
                        facebook: response.data.Response.customer.is_facebook_connected,
                        apple: response.data.Response.customer.is_apple_connected,
                        google: response.data.Response.customer.is_google_connected
                    }
                });
            }
        } else {
            //response.data.ResponseCode === "ERROR"
        }

    }

    onFieldChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = fieldName === 'card_name' ? e.target.value.toUpperCase() : e.target.value;
        //logger('onFieldChange '+fieldName+'='+fieldValue);
        this.setState({topup: updateObject(this.state.topup, {
                [fieldName]: fieldValue
            })
        });
    }

    onCardNumberChange = (e, inst) => {
        this.setState({topup: updateObject(this.state.topup, {
                card_number: e.valueText
            })
        });
    }

    onCardExpiryChange = (e, inst) => {
        //console.log('onCardExpiryChange', e, inst);
        this.setState({topup: updateObject(this.state.topup, {
                card_expiry: e.valueText,
                card_expiry_date: inst.getVal()
            })
        });
    }

    onCardCSCChange = (e, inst) => {
        //logger('onCardCSCChange card_csc='+e.valueText);
        this.setState({topup: updateObject(this.state.topup, {
                card_csc: e.valueText
            })
        });
    }

    setBillingAddress = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.setState({topup: updateObject(this.state.topup, {
                billing_address_id: parseInt(inst.getVal(),10)
            })
        });
    }

    onBillingAddressChange = (address) => {
        this.setState({topup: updateObject(this.state.topup, {
                billing_address: address,
                billing_geo: {},
                billing_latlng: {},
                billing_address1: "",
                billing_address2: "",
                billing_city: "",
                billing_state: "",
                billing_postcode: "",
                billing_country: ""
            })
        });
    }

    onBillingAddressSelect = (address) => {
        //console.log(address);
        this.onBillingAddressChange(address);
        geocodeByAddress(address)
            .then(results => {
                //console.log('geo', results); 
                const addr = {
                    billing_geo: {},
                    billing_latlng: {},
                    billing_address1: "",
                    billing_address2: "",
                    billing_city: "",
                    billing_state: "",
                    billing_postcode: "",
                    billing_country: "",
                };
                if (results !== undefined && results[0] !== undefined) {
                    addr.billing_geo = results[0];
                    if (results[0].address_components !== undefined) {
                        for (let a of results[0].address_components) {
                            switch (a['types'][0]) {
                                case 'street_number':
                                case 'route':
                                    if (addr.billing_address1 !== "") {
                                        addr.billing_address1 += " ";
                                    }
                                    addr.billing_address1 += a['short_name'];
                                    break;
                                case 'locality':
                                    addr.billing_city = a['short_name'];
                                    break;
                                case 'administrative_area_level_1':
                                    addr.billing_state = a['short_name'];
                                    break;
                                case 'postal_code':
                                    addr.billing_postcode = a['short_name'];
                                    break;
                                case 'country':
                                    addr.billing_country = a['short_name'];
                                    break;
                            }
                        }
                    }
                    this.setState({topup: updateObject(this.state.topup, addr)});
        
                    getLatLng(results[0])
                        .then(latLng => {
                            //console.log('Success2', latLng)
                            this.setState({topup: updateObject(this.state.topup, {
                                    billing_latlng: latLng
                                })
                            });
                        })
                        .catch(error => {
                            //console.error('Error2', error)
                            mpoSentry.captureException(error);
                        });
                }
            })
            .catch(error => {
                //console.error('Error1', error)
                mpoSentry.captureException(error);
            });
    }
    
    onAddressError = (status, clearSuggestions) => {
        mpoSentry.captureMessage('Google Maps API returned error with status: '+status, Sentry.Severity.Warning);
        clearSuggestions();
    }

    getPaymentTabContentJsx = (selectedPaymentMethodCode) => {
        //console.log('getPaymentTabContentJsx', selectedPaymentMethodCode);
        let paymentTabContent;

        switch (selectedPaymentMethodCode) {
            case 'CC':
                const showBillingAddressDropdown = parseInt(this.state.account.is_billing_address_required, 10) === 1 && this.state.customer.billing_addresses.length > 0;
                const showBillingAddressLookup = parseInt(this.state.account.is_billing_address_required, 10) === 1 && (!showBillingAddressDropdown || this.state.topup.billing_address_id === 0);

                let billingAddressDropdown = null;
                if (showBillingAddressDropdown) {
                    billingAddressDropdown = <label>
                        Billing address
                        <mobiscroll.Select
                            select="single"
                            value={this.state.topup.billing_address_id}
                            placeholder="Choose address or enter new one"
                            onSet={this.setBillingAddress}
                        >
                            <option key={0} value={0}>New billing address</option>
                            {this.state.customer.billing_addresses.map((addr, idx) => <option key={addr.id} value={addr.id}>{addr.address}</option>)}
                        </mobiscroll.Select>
                    </label>
                }

                paymentTabContent = <div>
                    <mobiscroll.Input 
                        labelStyle="stacked"
                        value={this.state.topup.card_name}
                        onChange={this.onFieldChange}
                        style={{textTransform: "upperCase"}}
                        name="cc-name"
                        autoComplete="cc-name"
                        data-fieldname="card_name">Name on Card</mobiscroll.Input>

                    {/*
                    <mobiscroll.Numpad
                        fill="ltr"
                        template="dddd dddd dddd dddd"
                        allowLeadingZero={true}
                        validate={validateCardNumber}
                        placeholder=""
                        showOnFocus={true}
                        onSet={this.onCardNumberChange}>
                        <mobiscroll.Input
                            labelStyle="stacked"
                            value={this.state.topup.card_number}
                            onChange={this.onFieldChange}
                            name="cc-number"
                            autoComplete="cc-number"
                            data-fieldname="card_number">Card Number
                        </mobiscroll.Input>
                    </mobiscroll.Numpad>
                    */}
                    <mobiscroll.Input
                        labelStyle="stacked"
                        value={this.state.topup.card_number}
                        onChange={this.onFieldChange}
                        name="cc-number"
                        type="number"
                        autoComplete="cc-number"
                        maxLength={20}
                        //pattern="^[0-9]{13,16}$"
                        data-fieldname="card_number">Card Number</mobiscroll.Input>

                    <label>
                    Card Expiry
                        <mobiscroll.Date 
                        labelStyle="stacked" 
                            value={this.state.topup.card_expiry_date} 
                            min={new Date()}
                            max={new Date(2099, 11, 1)}
                            dateFormat="mm/yyyy"
                            //returnFormat="locale"
                            name="cc-exp"
                            autoComplete="cc-exp"
                            showOnFocus={true}
                            onSet={this.onCardExpiryChange} 
                            />
                    </label>
                    {/*
                    <mobiscroll.Input
                        labelStyle="stacked"
                        value={this.state.topup.card_expiry}
                        onChange={this.onFieldChange}
                        name="cc-exp"
                        type="number"
                        autoComplete="cc-exp"
                        maxLength={7}
                        placeHolder="mm/yyyy"
                        //pattern="^(0[1-9]|1[0-2])\/([0-9]{4})$"
                        data-fieldname="card_expiry">Card Expiry</mobiscroll.Input>
                    */}
                    {/*
                    <mobiscroll.Numpad
                        fill="ltr"
                        template="dddd"
                        allowLeadingZero={true}
                        validate={validateCardCSC}
                        placeholder=""
                        showOnFocus={true}
                        onSet={this.onCardCSCChange}>
                        <mobiscroll.Input 
                            labelStyle="stacked"
                            value={this.state.topup.card_csc}
                            onChange={this.onFieldChange}
                            name="cc-csc"
                            autoComplete="cc-csc"
                            data-fieldname="card_csc">Security Code (CVC)</mobiscroll.Input>
                    </mobiscroll.Numpad>
                    */}
                    <mobiscroll.Input
                        labelStyle="stacked"
                        value={this.state.topup.card_csc}
                        onChange={this.onFieldChange}
                        name="cc-csc"
                        type="number"
                        autoComplete="cc-csc"
                        maxLength={4}
                        //pattern="^[0-9]{3,4}$"
                        data-fieldname="card_csc">Security Code (CVC)</mobiscroll.Input>

                    {billingAddressDropdown}

                    {showBillingAddressLookup ? 
                    <PlacesAutocomplete
                        value={this.state.topup.billing_address}
                        onChange={this.onBillingAddressChange}
                        onSelect={this.onBillingAddressSelect}
                        onError={this.onAddressError}
                        searchOptions={this.state.placesSearchOptions}
                        shouldFetchSuggestions={this.state.topup.billing_address.length > 3}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <React.Fragment>
                            <mobiscroll.Input 
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                                labelStyle="stacked" 
                                placeholder="Start typing" 
                                type="text" 
                                name="searchBillingAddress">{showBillingAddressDropdown ? "New billing address" : "Billing address"}</mobiscroll.Input>
                            <div className="autocomplete-dropdown-container">
                            {loading && <div className="suggestion-item">Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active ? 'suggestion-item suggestion-item-active': 'suggestion-item';
                                //console.log(suggestion);
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                                );
                            })}
                            </div>
                        </React.Fragment>
                        )}
                    </PlacesAutocomplete>
                    : null }
                </div>;
                break;
            default:
                paymentTabContent = <div>
                    <span className="mbsc-txt-s">Please choose a payment method.</span>
                </div>;
        }
        return paymentTabContent;

    }

    doTokenize = (nonce = null) => {

        this.setState({
            toppingUp: true
        });
        mpoSentry.addBreadcrumb('addcard','doTokenize',Sentry.Severity.Info);
        mobiscroll.notification.dismiss();
        const isBraintreeEnabled = parseInt(this.state.account.is_braintree_payments_enabled,10) === 1;

        let data = null;
        if (isBraintreeEnabled && nonce === null) {
            // https://developers.braintreepayments.com/guides/client-sdk/setup/javascript/v3
            mobiscroll.toast({message: 'Contacting Braintree...', duration: 3000, display: 'center', color: 'info'});
            braintree.create({
                authorization: this.state.account.braintree_tokenization_key
            }, (err, client) => {
                logger(err);
                //logger(client);
                if (client) {
                    client.request({
                        endpoint: 'payment_methods/credit_cards',
                        method: 'post',
                        data: {
                            creditCard: {
                                number: this.state.topup.card_number,
                                expirationDate: this.state.topup.card_expiry,
                                cvv: this.state.topup.card_csc,
                                billingAddress: {
                                    postalCode: this.state.topup.billing_postcode
                                }
                            }
                        }
                    }, (err, response) => {
                        logger(err);
                        //logger(response);
                        if (err) {
                            mobiscroll.toast({message: "Error contacting Braintree", duration: 6000, color: 'danger'});
                        } else {
                            // Send response.creditCards[0].nonce to your server
                            this.doTokenize(response.creditCards[0].nonce);
                        }
                    });
                }
            });
            return;
        } else {
            mobiscroll.toast({message: 'Contacting Gateway...', duration: 3000, display: 'center', color: 'info'});
            data = {
                RequestAction: 'AddNewCard',
                card_name: this.state.topup.card_name,
                card_number: this.state.topup.card_number,
                card_expiry: this.state.topup.card_expiry,
                card_csc: this.state.topup.card_csc,
                billing_address_id: this.state.topup.billing_address_id,
                billing_address_str: this.state.topup.billing_address,
                billing_address1: this.state.topup.billing_address1,
                billing_address2: this.state.topup.billing_address2,
                billing_city: this.state.topup.billing_city,
                billing_state: this.state.topup.billing_state,
                billing_postcode: this.state.topup.billing_postcode,
                billing_country: this.state.topup.billing_country,
                billing_geo: this.state.topup.billing_geo,
                billing_latlng: this.state.topup.billing_latlng,
                braintree_nonce: isBraintreeEnabled ? nonce : null
            }
        }

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        //logger(data);
        axiosInstance.post(null, data)
        .then(response => {
            logger(response);
            mobiscroll.notification.dismiss();
            if (response.data.ResponseCode === "SUCCESS") {
                this.setState({
                    toppingUp: false
                });
                mobiscroll.toast({message: 'Card added!', color: 'success'});
                mpoAccount.getAccount(this.onGetAccount, 'AddCard');
            } else {
                this.setState({
                    toppingUp: false
                });
                let errorMsg = response.data.Response[0];
                mobiscroll.toast({message: errorMsg, duration: 6000, color: 'danger'});
                mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);
            }
        })
        .catch(error => {
            mobiscroll.notification.dismiss();
            logger(error);
            this.setState({
                toppingUp: false
            });
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });
    }

    render = () => {

        const paymentTabContent = !this.state.isLoading ? this.getPaymentTabContentJsx(this.state.topup.payment_method_id) : null;

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_TITLE} Add Card`}</title>
                </Helmet>
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                {this.state.isLoading ?
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">Loading...</div>
                    </div>
                :
                <React.Fragment>
                    {isCustomApp || this.state.modules.topup ?
                    <React.Fragment>
                        <mobiscroll.FormGroup inset className="mpo-checkout-form">
                            <div className="mbsc-form-group-title">Add Card</div>
                            {paymentTabContent}
                        </mobiscroll.FormGroup>

                        <div className="mbsc-btn-group-block">
                            <React.Fragment>
                                <div className="mbsc-align-center mbsc-txt-s">Any existing card linked to your account will be overwritten. No payment will be processed now.<br />We use a PCI compliant payment gateway to securely store and process credit/debit cards.</div>
                                <mobiscroll.Button onClick={(e) => { e.preventDefault(); this.doTokenize(); }} disabled={this.state.toppingUp} color="success" style={{color: '#fff'}}>Save Card</mobiscroll.Button>
                            </React.Fragment>
                        </div>

                    </React.Fragment>
                    :
                    <div className="mbsc-empty">
                        <h3>Currently unavailable</h3>
                        <p>Please pay at checkout</p>
                    </div>
                    }
                </React.Fragment>
                }
                </mobiscroll.Form>

                {!this.state.isLoading ?
                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
                : null }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/addcard';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountAddCard));
