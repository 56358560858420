import axios from 'axios';
import Cookies from 'universal-cookie';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../lib/Sentry';
import {mpoSession} from "../lib/Session";
import {unixUtcTimeSec} from "../shared/utility";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {
        Token: process.env.REACT_APP_API_TOKEN,
        Version: process.env.REACT_APP_API_VERSION,
        AppId: process.env.REACT_APP_APP_ID,
        AppVersion: process.env.REACT_APP_APP_VERSION,
        AppMode: process.env.REACT_APP_APP_MODE !== undefined ? process.env.REACT_APP_APP_MODE : 'PERSONAL',
    }
});

axiosInstance.interceptors.request.use((config) => {
    const cookies = new Cookies();
    const sessionId = cookies.get(process.env.REACT_APP_APP_ID+'-sid');
    if (sessionId) {
        //logger('read mpo-sid: '+sessionId);
        config.data.sid = sessionId;
    }
    config.data.LocalTimestamp = unixUtcTimeSec();
    //logger('mpo-sid', sessionId);
    //logger(JSON.stringify(config.data));
    mpoSentry.addBreadcrumb('axios',JSON.stringify(config.data),Sentry.Severity.Info);
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use((response) => {
    const sessionId = response.data.SID;
    mpoSession.setSessionCookie(sessionId)
        .then((res) => {
            //logger(res);
            //logger(response);
        }, (err) => {
            //logger(err);
        });
    return response;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance;