import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import ContentLoader from "react-content-loader"
import axiosInstance from '../../components/axios';
//import Products from './Products';
import FulfilmentPopup from '../../components/products/FulfilmentTabs';
import ProductListItem from '../../components/products/ProductListItem';
import CategoryListItem from '../../components/products/CategoryListItem';
import ProductCardItem from "../../components/products/ProductCardItem";
import OrderItem from '../../components/merchant/OrderItem';
import PromoItem from '../../components/merchant/PromoItem';
import DeliveryAddress from '../../components/checkout/DeliveryAddress';
import Modifiers from './Modifiers';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import {
    logger,
    updateObject,
    nl2br,
    unixUtcTimeOffsetMins,
    isProduction,
    openWindow,
    isCordova, attachDeviceInfoToData,
    isIframe, getPopupResponsiveSettings, isAppModePersonal
} from '../../shared/utility';
import { mpoFulfilment } from '../../lib/Fulfilment';
import { mpoCheckout } from '../../lib/Checkout';
import {PullToRefresh, PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";
import DeliverySlot from '../../components/checkout/DeliverySlot';
import DeliveryStop from '../../components/checkout/DeliveryStop';
import PickupLocation from '../../components/checkout/PickupLocation';
import {
    isSafari,
    isMobileSafari,
    isLegacyEdge,
    isIE
} from "react-device-detect";
import {mpoAppStore} from "../../lib/AppStore";
import queryString from "query-string";
import {mpoScanner} from "../../lib/Scanner";
import {mpoFirebase} from "../../lib/Firebase";
import {mpoUpgrade} from "../../lib/Upgrade";
import {mpoOneSignal} from "../../lib/OneSignal";
import Footer from "../../components/Footer";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';
const cacheMinsMenu = isProduction() ? -15 : -5;
const cacheMinsFulfilment = isProduction() ? -15 : -5;
const maxInitialMenuItems = 10000; //isProduction() ? 40 : 10; // breaks search and rendering of menu item
const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
const isSingleStore = isCustomApp && process.env.REACT_APP_SINGLE_STORE === 'true';
const displaySuburbName = process.env.REACT_APP_DISPLAY_SUBURB_NAME === 'true';
const scrollMarginTop = (process.env.REACT_APP_SCROLL_MARGIN_TOP !== undefined && process.env.REACT_APP_SCROLL_MARGIN_TOP !== null && process.env.REACT_APP_SCROLL_MARGIN_TOP !== "" ? process.env.REACT_APP_SCROLL_MARGIN_TOP : "60px");
const placeholderItem = {
    id: "product-placeholder1",
    key: "product-placeholder1",
    product_placeholder: 1
};
//const menuCategoryHierarchyThreshold = 5;
let useMenuCategoryHierarchy = false;
let reloadFromServerCount = 0;

class Menu extends Component {
    
    constructor(props) {

        //logger('Menu constructor');
        //logger(props);
        //mobiscroll.toast({message: "constructor", color: 'info'});

        super(props);

        let isLoading = true;
        let menuSlug = props.hasOwnProperty('match') ? props.match.params.slug : null;
        if (!menuSlug) {
            menuSlug = this.props.slug;
        }
        // remove website link mistakes
        if (!isCordova() && (menuSlug[menuSlug.length-1] === "." || menuSlug[menuSlug.length-1] === ",")) {
            menuSlug = menuSlug.slice(0, -1);
        }

        const params = this.props.hasOwnProperty('location') && this.props.location !== undefined &&
            this.props.location.hasOwnProperty('search') && this.props.location.search !== undefined &&
            this.props.location.search !== "" ? queryString.parse(this.props.location.search) : {};
        const promoCode = params.promo ? params.promo : null;
        const userId = params.userid ? params.userid : null;
        if (userId !== null && debugMode) {
            logger("menucon "+userId);
            //mobiscroll.toast({message: "menucon", color: 'info'});
        }

        // const menuItemLink = props.hasOwnProperty('match') && props.match.params.hasOwnProperty('menuitemlink') ? props.match.params.menuitemlink : false;
        // logger('menuitemlink: '+menuItemLink);

        //const fromDeeplink = props.hasOwnProperty('location') && props.location.state !== undefined && props.location.state.hasOwnProperty('fromDeeplink') ? props.location.state.fromDeeplink : false;
        const fromLogin = props.hasOwnProperty('location') && props.location !== undefined && props.location.hasOwnProperty('state') && props.location.state !== undefined && props.location.state.hasOwnProperty('fromSetCustomerAction') ? props.location.state.fromSetCustomerAction : false;
        let menuExtra = props.hasOwnProperty('location') && props.location !== undefined && props.location.hasOwnProperty('state') && props.location.state !== undefined && props.location.state.hasOwnProperty('menuExtra') ? props.location.state.menuExtra : "";
        let showMenu = menuExtra !== mpoFulfilment.fulfilmentTypeCodeDelivery;

        let menu = [];
        let menu_all = [];
        let menu_categories = [];
        let selected_category = null;
        let hours = null;
        let info = null;
        let display_images = false;
        let is_merchant_ready = false;
        let is_order_for_later_enabled = false;

        if (menuSlug) {
            // cached within past 15 mins
            if (menuSlug === this.props.slug && this.props.menuItems.length > 1 && 
                this.props.merchant.hasOwnProperty('local_ts') && this.props.merchant.local_ts > unixUtcTimeOffsetMins(cacheMinsMenu) &&
                !this.props.menuItems[0].hasOwnProperty('product_placeholder') &&
                mpoFulfilment.validFulfilment(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
                //logger('redux slug');

                const merchant = this.props.merchant;
                for (var i = 0; i < merchant.menus.length; i++) {
                    if (merchant.menus[i].id === this.props.merchant.menu_id) {
                        hours = merchant.menus[i].hasOwnProperty('hours') && merchant.menus[i].hours !== 'undefined' && merchant.menus[i].hours !== 'null' && merchant.menus[i].hours !== null && merchant.menus[i].hours !== '' ? merchant.menus[i].hours : null;
                        info = merchant.menus[i].hasOwnProperty('public_notice') && merchant.menus[i].public_notice !== 'undefined' && merchant.menus[i].public_notice !== 'null' && merchant.menus[i].public_notice !== null && merchant.menus[i].public_notice !== '' ? merchant.menus[i].public_notice : null;
                        display_images = merchant.menus[i].hasOwnProperty('display_images') && parseInt(merchant.menus[i].display_images, 10) === 1;
                        is_merchant_ready = merchant.menus[i].hasOwnProperty('is_merchant_ready') && parseInt(merchant.menus[i].is_merchant_ready, 10) === 1;
                        is_order_for_later_enabled = merchant.menus[i].hasOwnProperty('is_order_for_later_enabled') && parseInt(merchant.menus[i].is_order_for_later_enabled, 10) === 1;
                        break;
                    }
                }

                if (is_merchant_ready || is_order_for_later_enabled) {
                    menu_all = this.props.menuItems;

                    let has_children = false;
                    for (var j = 0; j < menu_all.length; j++) {
                        if (menu_all[j].hasOwnProperty('header') && menu_all[j].header) {
                            has_children = has_children || (menu_all[j].hasOwnProperty('children') && menu_all[j].children.length > 0);
                            menu_categories.push(menu_all[j]);
                            if (selected_category === null) {
                                selected_category = menu_all[j];
                                menu_categories[menu_categories.length-1].default_selection = true;
                            }
                        }
                    }

                    useMenuCategoryHierarchy = has_children && this.props.merchant.hasOwnProperty('group_menu_by_category') && parseInt(this.props.merchant.group_menu_by_category,10) === 1; //menu_categories.length >= menuCategoryHierarchyThreshold;
                    if (debugMode) logger('useMenuCategoryHierarchy='+useMenuCategoryHierarchy)

                    if (useMenuCategoryHierarchy) {
                        menu = menu_categories;
                    } else {
                        let numItems = maxInitialMenuItems;
                        if (numItems+1 >= menu_all.length) {
                            numItems = menu_all.length;
                        }
                        menu = this.props.menuItems.slice(0, numItems);
                        if (menu.length < menu_all.length) {
                            menu.push(placeholderItem);
                        }
                    }

                    //logger(menu);
                    isLoading = false;

                    let deliveryAddressReqd = mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
                    let deliverySlotReqd = mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
                    let deliveryStopReqd = mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidDeliveryStop(this.props.fulfilmentType, this.props.fulfilmentOptions);
                    let pickupLocationReqd = mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidPickupLocation(this.props.fulfilmentType, this.props.fulfilmentOptions);
                    if (deliveryStopReqd || (deliveryAddressReqd && (!this.isValidDeliveryAddress() || (deliverySlotReqd && !mpoFulfilment.isValidDeliverySlot(this.props.fulfilmentType, this.props.fulfilmentOptions)) || menuExtra === mpoFulfilment.fulfilmentTypeCodeDelivery || fromLogin))) {
                        showMenu = false;
                        menuExtra = mpoFulfilment.fulfilmentTypeCodeDelivery;
                    } else if (pickupLocationReqd) {
                        showMenu = false;
                        menuExtra = mpoFulfilment.fulfilmentTypeCodePickup;
                    }
                }
            }
        }

        this.state = {
            isLoading: isLoading,
            menu: menu,
            menu_all: menu_all,
            menu_categories: menu_categories,
            selected_category: selected_category,
            menuSlug: menuSlug,
            menuExtra: menuExtra,
            menuSearch: "",
            merchantCardTab: 'main',
            hours: hours,
            info: info,
            display_images: display_images,
            is_merchant_ready: is_merchant_ready,
            is_order_for_later_enabled: is_order_for_later_enabled,
            coupon_code: promoCode ? promoCode : this.props.cart.order.coupon_code,
            showMenu: showMenu,
            appStore: {
                title: "",
                text: "",
                redirect: false,
                brandName: null,
                urlWebApp: null,
                urlIosApp: null,
                urlAndroidApp: null,
            }
        }

        //this.loadMerchantPostDataHandler = this.loadMerchantPostDataHandler.bind(this);
        //this.updateStateWithOrder = this.updateStateWithOrder.bind(this);
        //this.loadModifiersPostDataHandler = this.loadModifiersPostDataHandler.bind(this);
        //this.onListItemTap = this.onListItemTap.bind(this);
        this.fulfilmentModal = React.createRef();
        this.modifiersModal = React.createRef();
        this.topOfMenu = React.createRef();

    }

    componentDidMount = () => {
        if (debugMode) {
            logger('Menu componentDidMount');
        }
        //logger(this.props.slug);
        //logger(this.props);
        //mobiscroll.toast({message: "componentDidMount", color: 'info'});
        mpoSentry.addBreadcrumb('nav','Menu: ' + this.state.menuSlug,Sentry.Severity.Info);

        if (this.state.menuSlug) {
            Sentry.setExtra("menu_slug", this.state.menuSlug);
            const fromLogin = (this.props.hasOwnProperty('location') && this.props.location.state !== undefined && this.props.location.state.hasOwnProperty('fromSetCustomerAction') && this.props.location.state.fromSetCustomerAction === true);
            if (this.state.isLoading || this.state.menu_all === undefined || this.state.menu_all === null || this.state.menu_all.length === 0 || fromLogin) {
                this.getAccount();
                if (this.props.slug !== this.state.menuSlug || this.props.fulfilmentOptions === undefined ||
                    Object.keys(this.props.fulfilmentOptions).length === 0 || this.props.fulfilmentOptions.code === null ||
                    (this.props.merchant.hasOwnProperty('local_ts') && this.props.merchant.local_ts < unixUtcTimeOffsetMins(cacheMinsMenu))) {
                    logger('componentDidMount: fetch empty menu');
                    this.resetFulfilmentState();
                    this.loadMerchantPostDataHandler(this.state.menuSlug, false, ""); //empty menu
                } else {
                    //mobiscroll.toast({message: 'componentDidMount: fetch menu from server', color: 'info'});
                    logger('componentDidMount: fetch menu from server');
                    let showFulfilmentPopup = /*this.props.merchant.local_ts < unixUtcTimeOffsetMins(cacheMinsMenu) ||*/ (!mpoFulfilment.isValidDateTime(this.props.fulfilmentType, this.props.fulfilmentOptions));
                    this.loadMerchantPostDataHandler(this.state.menuSlug, true, this.props.fulfilmentOptions.code, showFulfilmentPopup); //return menu for selected fulfilment type
                }
            } else {
                // display redux data
                //mobiscroll.toast({message: 'componentDidMount: fetch menu from cache', color: 'info'});
                logger('componentDidMount: fetch menu from cache');
                // check in case table number had changed via external QR code scan
                const params = this.props.hasOwnProperty('location') && this.props.location !== undefined &&
                    this.props.location.hasOwnProperty('search') && this.props.location.search !== undefined &&
                    this.props.location.search !== "" ? queryString.parse(this.props.location.search) : {};
                const tableNum = params.table ? params.table : null;
                const userId = params.userid ? params.userid : null;
                if (userId !== null && debugMode) {
                    logger("menucdm "+userId);
                    //mobiscroll.toast({message: "menucdm", color: 'info'});
                }
                let showFulfilmentPopup = /*this.props.merchant.local_ts < unixUtcTimeOffsetMins(cacheMinsMenu) ||*/ (!mpoFulfilment.isValidDateTime(this.props.fulfilmentType, this.props.fulfilmentOptions)) || tableNum !== null;
                this.props.updateStateWithMenuItems(this.state.menu_all);
                if (showFulfilmentPopup) {
                    this.showFulfilmentPopup();
                }
            }
        } else {
            this.props.history.push('/');            
        }
    }
    
    componentDidUpdate = (prevProps) => {
        // if (debugMode) {
        //     logger('Menu componentDidUpdate');
        // }
        // logger(JSON.stringify(prevProps.fulfilmentOptions));
        // logger(JSON.stringify(this.props.fulfilmentOptions));
        //mobiscroll.toast({message: "componentDidUpdate", color: 'info'});
        if (prevProps.fulfilmentType !== this.props.fulfilmentType && this.props.fulfilmentType !== undefined && Object.keys(this.props.fulfilmentType).length > 0) {
            if (debugMode) {
                logger('Menu componentDidUpdate fulfilmentType');
                logger(this.props.fulfilmentType);
            }
            this.loadMerchantPostDataHandler(this.state.menuSlug, true, this.props.fulfilmentOptions.code);

            mpoFirebase.LogEvent('view_item_list', {
                //items: ,
                //item_list_id: ,
                item_list_name: this.state.menuSlug
            });

        }
    }

    getAccount = () => {

        const data = {
            RequestAction: 'CustomerAccount'
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        axiosInstance.post(null, data)
            .then(response => {
                if (response.data.ResponseCode === "AUTH") {
                    this.props.updateStateWithCustomer({id: 0, status: 0});
                } else if (response.data.ResponseCode === "SUCCESS") {
                    if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                        this.props.updateStateWithCustomer({id: 0, status: 0});
                    } else {
                        // logged in
                    }
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                }
                mpoUpgrade.processUpgradeResponse(response);
            })
        .catch(error => {
            mobiscroll.toast({message: error, color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    resetFulfilmentState = () => {
        this.props.updateStateWithFulfilmentType({});
        this.props.updateStateWithFulfilmentOptions({});
    }

    showFulfilmentPopup = () => {

        //logger(this.props);
        logger('showFulfilmentPopup');
        mpoSentry.addBreadcrumb('nav','showFulfilmentPopup',Sentry.Severity.Info);

        const params = this.props.hasOwnProperty('location') && this.props.location !== undefined &&
            this.props.location.hasOwnProperty('search') && this.props.location.search !== undefined &&
            this.props.location.search !== "" ? queryString.parse(this.props.location.search) : {};
        //logger(params);
        const userId = params.userid ? params.userid : null;
        if (userId !== null && debugMode) {
            logger("menuful "+userId);
            //mobiscroll.toast({message: "menuful", color: 'info'});
        }
        const paramFulfilmentType = params.fulfil ? params.fulfil : null;
        const tableNum = params.table ? params.table : null;
        const bookingName = params.bookingname ? params.bookingname : null;
        const bookingRef = params.bookingref ? params.bookingref : null;
        const bookingPax = params.bookingpax ? params.bookingpax : null;
        //logger("tableNum: "+tableNum);
        const confirmParams = params.confirm && !isNaN(params.confirm) && parseInt(params.confirm,10) === 0 ? false : true;
        //logger("confirmParams: "+confirmParams);

        let merchant = this.props.merchant;
        let fulfilment_types = this.props.merchant.fulfilment;

        if (fulfilment_types === undefined || fulfilment_types.length === 0 ||
            (merchant.hasOwnProperty('local_ts') && merchant.local_ts < unixUtcTimeOffsetMins(cacheMinsFulfilment) && reloadFromServerCount < 5)) {
            // this called purpetually when browser timezone < store timezone
            //logger(merchant);
            //logger(fulfilment_types);
            // if (merchant.hasOwnProperty('ts') && merchant.ts < unixUtcTimeOffsetMins(cacheMinsFulfilment)) {
            //     logger('merchant.ts < cacheMinsFulfilment');
            //     logger(merchant.ts+' < '+unixUtcTimeOffsetMins(cacheMinsFulfilment));
            // }
            if (this.props.fulfilmentOptions === undefined || Object.keys(this.props.fulfilmentOptions).length === 0 || this.props.fulfilmentOptions.code === null) {
                logger('showFulfilmentPopup: reload from server 1');
                //reloadFromServerCount++; // prevent endless loop (shouldn't be necessary now we use local_ts)
                this.loadMerchantPostDataHandler(this.state.menuSlug, false, ""); //empty menu
            } else {
                logger('showFulfilmentPopup: reload from server 2');
                //let showFulfilmentPopup = /*merchant.local_ts < unixUtcTimeOffsetMins(cacheMinsMenu) ||*/ (!mpoFulfilment.isValidDateTime(this.props.fulfilmentType, this.props.fulfilmentOptions));
                this.loadMerchantPostDataHandler(this.state.menuSlug, true, this.props.fulfilmentOptions.code, true);
            }
        } else if (fulfilment_types !== undefined && fulfilment_types.length > 0) {
            //logger(this.fulfilmentModal);
            //logger(fulfilment_types);
            //console.log('Cur', this.props.fulfilmentType);
            //console.log('Opt', this.props.fulfilmentOptions);

            let selectedFulfilmentType = null;
            let selectedFulfilmentOptions = mpoFulfilment.getDefaultFulfilmentOptions(null, true);

            if (tableNum) {
                selectedFulfilmentType = mpoFulfilment.selectFulfilmentType(fulfilment_types, mpoFulfilment.fulfilmentTypeCodeDinein);
                //logger(selectedFulfilmentType);
                if (selectedFulfilmentType !== null) {
                    selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypeDinein;
                    selectedFulfilmentOptions.dineinTableNum = tableNum;
                    //logger(selectedFulfilmentOptions);
                }
            } else if (bookingName || bookingRef || bookingPax) {
                selectedFulfilmentType = mpoFulfilment.selectFulfilmentType(fulfilment_types, mpoFulfilment.fulfilmentTypeCodeBooking);
                //logger(selectedFulfilmentType);
                if (selectedFulfilmentType !== null) {
                    selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypeBooking;
                    selectedFulfilmentOptions.dineinBookingName = bookingName;
                    selectedFulfilmentOptions.dineinBookingRef = bookingRef;
                    selectedFulfilmentOptions.dineinBookingPax = bookingPax;
                    //logger(selectedFulfilmentOptions);
                }
            } else if (paramFulfilmentType) {
                switch (paramFulfilmentType) {
                    case 'pickup':
                    case 'takeaway':
                        selectedFulfilmentType = mpoFulfilment.selectFulfilmentType(fulfilment_types, mpoFulfilment.fulfilmentTypeCodePickup);
                        if (selectedFulfilmentType !== null) {
                            selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypePickup;
                        }
                        break;
                    case 'dine':
                    case 'dinein':
                    case 'dine-in':
                    case 'table':
                        selectedFulfilmentType = mpoFulfilment.selectFulfilmentType(fulfilment_types, mpoFulfilment.fulfilmentTypeCodeDinein);
                        if (selectedFulfilmentType !== null) {
                            selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypeDinein;
                        }
                        break;
                    case 'book':
                    case 'booking':
                    case 'reserve':
                    case 'reservation':
                        selectedFulfilmentType = mpoFulfilment.selectFulfilmentType(fulfilment_types, mpoFulfilment.fulfilmentTypeCodeBooking);
                        if (selectedFulfilmentType !== null) {
                            selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypeBooking;
                        }
                        break;
                    case 'deliver':
                    case 'delivery':
                        selectedFulfilmentType = mpoFulfilment.selectFulfilmentType(fulfilment_types, mpoFulfilment.fulfilmentTypeCodeDelivery);
                        if (selectedFulfilmentType !== null) {
                            selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypeDelivery;
                        }
                        break;
                    case 'custom':
                        selectedFulfilmentType = mpoFulfilment.selectFulfilmentType(fulfilment_types, mpoFulfilment.fulfilmentTypeCodeCustom);
                        if (selectedFulfilmentType !== null) {
                            selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypeCustom;
                        }
                        break;
                }
            }

            if (selectedFulfilmentType === null) {
                if (this.props.fulfilmentType === undefined || Object.keys(this.props.fulfilmentType).length === 0) {
                    //this.props.updateStateWithFulfilmentType(fulfilment_types[0]);
                    if (fulfilment_types.length === 1) {
                        selectedFulfilmentType = fulfilment_types[0];
                    }
                } else {
                    selectedFulfilmentType = this.props.fulfilmentType;
                    // override in case of menu availability changes
                    for (let ft in fulfilment_types) {
                        if (this.props.fulfilmentType.id === fulfilment_types[ft].id) {
                            selectedFulfilmentType = fulfilment_types[ft];
                            break;
                        }
                    }
                }
            }
            //logger(selectedFulfilmentType);
            //logger(selectedFulfilmentOptions);

            if (selectedFulfilmentType !== null) {
                if (this.props.fulfilmentOptions === undefined || Object.keys(this.props.fulfilmentOptions).length === 0) {
                    let selectedFulfilmentTypeCode = mpoFulfilment.fulfilmentTypePickup;
                    for (let code in selectedFulfilmentType.fulfilment_types) {
                        selectedFulfilmentTypeCode = code;
                        break;
                    }
                    //logger(selectedFulfilmentTypeCode);

                    const asap = parseInt(selectedFulfilmentType.fulfilment_types[selectedFulfilmentTypeCode].asap, 10) === 1 &&
                        parseInt(selectedFulfilmentType.availability.available_today, 10) === 1;

                    selectedFulfilmentOptions = {
                        ...selectedFulfilmentOptions,
                        code: selectedFulfilmentTypeCode,
                        asap: asap,
                        datetime: null,
                        datetimeVal: null
                    }
                    //this.props.updateStateWithFulfilmentOptions(selectedFulfilmentOptions);
                    /*
                    } else if (!mpoFulfilment.isValidDateTime(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
                        selectedFulfilmentOptions = {
                            code: this.props.fulfilmentOptions.code,
                            asap: this.props.fulfilmentOptions.asap,
                            datetime: null,
                            datetimeVal: null
                        }
                    */
                } else {
                    selectedFulfilmentOptions = this.props.fulfilmentOptions;

                    if (tableNum) {
                        selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypeDinein;
                        selectedFulfilmentOptions.dineinTableNum = tableNum;
                    } else if (bookingName || bookingRef || bookingPax) {
                        selectedFulfilmentOptions.code = mpoFulfilment.fulfilmentTypeBooking;
                        selectedFulfilmentOptions.dineinBookingName = bookingName;
                        selectedFulfilmentOptions.dineinBookingRef = bookingRef;
                        selectedFulfilmentOptions.dineinBookingPax = bookingPax;
                    }

                    /*
                    const asap = parseInt(selectedFulfilmentType.fulfilment_types[selectedFulfilmentTypeCode].asap, 10) === 1 &&
                                    parseInt(selectedFulfilmentType.availability.available_today, 10) === 1;
                    selectedFulfilmentOptions.asap = asap;
                    */
                }
            }

            // logger(selectedFulfilmentType);
            // logger(selectedFulfilmentOptions);
            //logger(this.fulfilmentModal.current);
            if (this.fulfilmentModal.current !== null) {
                this.fulfilmentModal.current.setState({
                    merchant_id: merchant.id,
                    fulfilment_types: fulfilment_types,
                    selected_fulfilment_type: selectedFulfilmentType === null ? {} : selectedFulfilmentType,
                    selected_fulfilment_options: selectedFulfilmentOptions
                });

                //logger('try show');
                if (selectedFulfilmentType === null || confirmParams) {
                    this.fulfilmentModal.current.refs.popupFulfilment.instance.show();
                } else {
                    this.props.updateStateWithFulfilmentOptions(selectedFulfilmentOptions);
                    this.props.updateStateWithFulfilmentType(selectedFulfilmentType);
                }
            } else {
                // component unmounted? can recreate on android by using back button to locations before selecting a fulfilment type
                //mobiscroll.toast({message: "Error displaying fulfilment options, try again", color: 'danger'});
                mpoSentry.captureException("this.fulfilmentModal.current === null (menu)");
            }
        } else {
            mobiscroll.toast({message: "Fulfilment options not loaded, try again", color: 'danger'});
            mpoSentry.captureException("No fulfilment types (menu)");
        }
    }

    loadMerchantPostDataHandler = (menuSlug, returnMenu, fulfilmentType, forceShowFulfilmentPopup = false) => {
        const loadingMsg = returnMenu ? 'Loading products...' : 'Loading...';
        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: loadingMsg, duration: 3000, display: 'center', color: 'info'});
        //console.log('loadMerchantPostDataHandler', menuSlug, returnMenu, fulfilmentType)
        //logger('loadMerchantPostDataHandler');
        //logger(menuSlug);
        const data = {
            RequestAction: 'Merchant',
            menu_name: menuSlug,
            fulfilment_type: fulfilmentType,
            return_menu: returnMenu ? 1 : 0,
            return_order: 1            
        };
        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }
        if (debugMode) {
            logger('loadMerchantPostDataHandler');
            logger(data);
        }
        axiosInstance.post(null, data)
            .then(response => {
                //logger(response);
                mobiscroll.notification.dismiss();
                if (response.data.ResponseCode === "SUCCESS") {

                    let merchant = response.data.Response.merchant;
                    let order = response.data.Response.hasOwnProperty('order') ? response.data.Response.order : null;

                    // map categories and items
                    let menu = [];
                    let menu_all = [];
                    let menu_categories = [];
                    let selected_category = this.state.selected_category;
                    let hours = null;
                    let info = null;
                    let display_images = false;
                    let is_merchant_ready = false;
                    let is_order_for_later_enabled = false;

                    if (merchant.menu_id) {
                        //logger(merchant.menus);
                        let menuCategoriesAndItems = null;
                        for (var i = 0; i < merchant.menus.length; i++) {
                            if (merchant.menus[i].id === merchant.menu_id) {
                                menuCategoriesAndItems = merchant.menus[i].items;
                                hours = merchant.menus[i].hasOwnProperty('hours') && merchant.menus[i].hours !== 'undefined' && merchant.menus[i].hours !== 'null' && merchant.menus[i].hours !== null && merchant.menus[i].hours !== '' ? merchant.menus[i].hours : null;
                                info = merchant.menus[i].hasOwnProperty('public_notice') && merchant.menus[i].public_notice !== 'undefined' && merchant.menus[i].public_notice !== 'null' && merchant.menus[i].public_notice !== null && merchant.menus[i].public_notice !== '' ? merchant.menus[i].public_notice : null;
                                display_images = merchant.menus[i].hasOwnProperty('display_images') && parseInt(merchant.menus[i].display_images, 10) === 1;
                                is_merchant_ready = merchant.menus[i].hasOwnProperty('is_merchant_ready') && parseInt(merchant.menus[i].is_merchant_ready, 10) === 1;
                                is_order_for_later_enabled = merchant.menus[i].hasOwnProperty('is_order_for_later_enabled') && parseInt(merchant.menus[i].is_order_for_later_enabled, 10) === 1;
                                break;
                            }
                        }
                        if (menuCategoriesAndItems) {
                            useMenuCategoryHierarchy = merchant.hasOwnProperty('group_menu_by_category') && parseInt(merchant.group_menu_by_category,10) === 1; //menu_categories.length >= menuCategoryHierarchyThreshold;

                            // if (debugMode) {
                            //     logger('selected_category');
                            //     logger(selected_category);
                            // }

                            for (var j = 0; j < menuCategoriesAndItems.length; j++) {
                                let category = menuCategoriesAndItems[j];
                                //logger(category);
                                //logger(category.items);
                                let catItem = {
                                    header: true,
                                    id: "cat-"+category.id,
                                    name: category.name,
                                    desc: category.desc,
                                    img_url: category.img_url,
                                    children: category.items, //useMenuCategoryHierarchy ? category.items : []
                                    hierarchy: useMenuCategoryHierarchy
                                };
                                menu_all.push(catItem);
                                menu_categories.push(catItem);
                                if (selected_category === null || selected_category.id === catItem.id) {
                                    selected_category = catItem;
                                    menu_categories[menu_categories.length-1].default_selection = true;
                                }
                                for (var k = 0; k < category.items.length; k++) {
                                    let item = category.items[k];
                                    item.header = false;
                                    item.cat_id = category.id;
                                    //logger(item);
                                    menu_all.push(item);
                                }
                            }
                            if (useMenuCategoryHierarchy) {
                                menu = menu_categories;
                            } else {
                                let numItems = maxInitialMenuItems;
                                if (numItems+1 >= menu_all.length) {
                                    numItems = menu_all.length;
                                }
                                menu = menu_all.slice(0, numItems);
                                if (menu.length < menu_all.length) {
                                    menu.push(placeholderItem);
                                }
                            }
                        }
                    }

                    this.props.updateStateWithSlug(menuSlug);
                    this.props.updateStateWithMerchant(merchant);
                    this.props.updateStateWithOrder(order);
                    //logger('menu.len');
                    //logger(menu);
                    //logger(order);

                    // handle promo code passed in via get param
                    if (this.state.coupon_code !== undefined && this.state.coupon_code !== null && this.state.coupon_code !== "" && this.state.coupon_code !== order.coupon_code) {
                        mpoCheckout.updateCheckout(
                            merchant.id,
                            merchant.menu_id,
                            { coupon_code: this.state.coupon_code },
                            'Menu.loadMerchantPostDataHandler'
                        );
                        mobiscroll.toast({message: "Promo code "+this.state.coupon_code+ " applied", duration: 3000, color: 'info'});
                    }

                    let showMenu = true;
                    let menuExtra = this.state.menuExtra;
                    let deliveryAddressReqd = mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
                    let deliverySlotReqd = mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
                    let deliveryStopReqd = mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions); // && !mpoFulfilment.isValidDeliveryStop(this.props.fulfilmentType, this.props.fulfilmentOptions);
                    let pickupLocationReqd = mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions); // && !mpoFulfilment.isValidPickupLocation(this.props.fulfilmentType, this.props.fulfilmentOptions);
                    if (deliveryStopReqd || (deliveryAddressReqd && (!this.isValidDeliveryAddress() || (deliverySlotReqd && !mpoFulfilment.isValidDeliverySlot(this.props.fulfilmentType, this.props.fulfilmentOptions)) || menuExtra === mpoFulfilment.fulfilmentTypeCodeDelivery))) {
                        showMenu = false;
                        menuExtra = mpoFulfilment.fulfilmentTypeCodeDelivery;
                    } else if (pickupLocationReqd) {
                        showMenu = false;
                        menuExtra = mpoFulfilment.fulfilmentTypeCodePickup;
                    } else {
                        menuExtra = "";
                    }
                    if (debugMode) logger('showMenu loadMerchantPostDataHandler ' + showMenu + ' ' + this.state.menuExtra);

                    if (merchant.hasOwnProperty('brand') && Object.keys(merchant.brand).length > 0 && merchant.brand.hasOwnProperty('redirect') && parseInt(merchant.brand.redirect, 10) === 1) {
                        const updatedState = updateObject(this.state, {
                            isLoading: false,
                            appStore: {
                                title: merchant.brand.title,
                                text: merchant.brand.text,
                                redirect: true,
                                brandName: merchant.brand.brand_name,
                                urlWebApp: merchant.brand.hasOwnProperty('url_web_app') ? merchant.brand.url_web_app : '',
                                urlIosApp: merchant.brand.url_ios_app,
                                urlAndroidApp: merchant.brand.url_android_app
                            }
                        });
                        if (debugMode) logger('setState loadMerchantPostDataHandler b1');
                        this.setState(updatedState);
                    } else if (menu.length > 0) {
                        logger('m1');
                        this.props.updateStateWithMenuItems(menu_all);
                        const updatedState = updateObject(this.state, {
                            isLoading: false,
                            menu: menu,
                            menu_all: menu_all,
                            menu_categories: menu_categories,
                            selected_category: selected_category,
                            hours: hours,
                            info: info,
                            display_images: display_images,
                            is_merchant_ready: is_merchant_ready,
                            is_order_for_later_enabled: is_order_for_later_enabled,
                            showMenu: showMenu,
                            menuExtra: menuExtra,
                            appStore: {
                                title: "",
                                text: "",
                                redirect: false,
                                brandName: null,
                                urlWebApp: null,
                                urlIosApp: null,
                                urlAndroidApp: null
                            }
                        });
                        if (debugMode) {
                            logger('setState loadMerchantPostDataHandler m1');
                            logger(this.state);
                            logger(updatedState);
                        }
                        if (forceShowFulfilmentPopup) {
                            this.setState(updatedState, this.showFulfilmentPopup);
                        } else {
                            this.setState(updatedState);
                        }
                    } else {
                        logger('m2');
                        const fulfilmentTypes = this.props.merchant.hasOwnProperty('fulfilment') && this.props.merchant.fulfilment !== undefined ? this.props.merchant.fulfilment : null;
                        //logger(fulfilmentTypes);
                        if (fulfilmentTypes === null || fulfilmentTypes.length === 0) {
                            logger('m2a');
                            mobiscroll.toast({message: "No fulfilment types, please try again", color: 'danger'});
                            //logger(this.props.merchant);
                            mpoSentry.captureException("No fulfilment type");
                        } else if (!returnMenu && fulfilmentTypes.length === 1 && Object.keys(fulfilmentTypes[0].fulfilment_types).length === 1 && 
                                    (parseInt(fulfilmentTypes[0].fulfilment_types[Object.keys(fulfilmentTypes[0].fulfilment_types)[0]].asap,10) === 1) &&
                                    fulfilmentTypes[0].availability.available === 1 && fulfilmentTypes[0].availability.available_today === 1) {
                            logger('m2b (default to asap today)');
                            this.props.updateStateWithFulfilmentOptions(mpoFulfilment.getDefaultFulfilmentOptions(fulfilmentTypes[0].fulfilment_types[Object.keys(fulfilmentTypes[0].fulfilment_types)[0]].code, true));
                            this.props.updateStateWithFulfilmentType(fulfilmentTypes[0]);

                            if ((mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !this.isValidDeliveryAddress()) ||
                                (mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidDeliverySlot(this.props.fulfilmentType, this.props.fulfilmentOptions)) ||
                                (mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidDeliveryStop(this.props.fulfilmentType, this.props.fulfilmentOptions)) ||
                                this.state.menuExtra === mpoFulfilment.fulfilmentTypeCodeDelivery) {
                                this.setShowMenu(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
                            } else if (mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidPickupLocation(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
                                this.setShowMenu(false, mpoFulfilment.fulfilmentTypeCodePickup);
                            }
                        } else if (is_merchant_ready || is_order_for_later_enabled) {
                            logger('m2c');
                            const updatedState = updateObject(this.state, {
                                isLoading: true,
                                menu: menu,
                                menu_all: menu_all,
                                menu_categories: menu_categories,
                                selected_category: selected_category,
                                hours: hours,
                                info: info,
                                display_images: display_images,
                                is_merchant_ready: is_merchant_ready,
                                is_order_for_later_enabled: is_order_for_later_enabled,
                                showMenu: showMenu,
                                menuExtra: menuExtra,
                                appStore: {
                                    title: "",
                                    text: "",
                                    redirect: false,
                                    brandName: null,
                                    urlWebApp: null,
                                    urlIosApp: null,
                                    urlAndroidApp: null
                                }
                            });
                            if (debugMode) logger('setState loadMerchantPostDataHandler m2');
                            this.setState(updatedState, this.showFulfilmentPopup);
                        } else {
                            logger('m2d');
                            const updatedState = updateObject(this.state, {
                                isLoading: false,
                                hours: hours,
                                info: info,
                                display_images: display_images,
                                is_merchant_ready: is_merchant_ready,
                                is_order_for_later_enabled: is_order_for_later_enabled,
                                showMenu: showMenu,
                                menuExtra: menuExtra,
                                appStore: {
                                    title: "",
                                    text: "",
                                    redirect: false,
                                    brandName: null,
                                    urlWebApp: null,
                                    urlIosApp: null,
                                    urlAndroidApp: null
                                }
                            });
                            if (debugMode) logger('setState loadMerchantPostDataHandler m2d');
                            this.setState(updatedState);
                        }
                    }
                } else {
                    mobiscroll.toast({message: response.data.Response[0], duration: 6000, color: 'danger'});
                    if (isCustomApp) {
                        const updatedState = updateObject(this.state, {
                            isLoading: false
                        });
                        if (debugMode) logger('setState loadMerchantPostDataHandler e1');
                        this.setState(updatedState);
                    }
                    if (response.data.Response[0] === 'Invalid menu name') {
                        mpoSentry.captureException("Invalid menu name");
                        if (!isCustomApp) {
                            this.props.history.push('/');
                        }
                    } else if (response.data.Response[0] === 'Merchant does not exist') {
                        mpoSentry.captureException("Invalid merchant");
                        if (!isCustomApp) {
                            this.props.history.push('/');
                        }
                    } else {
                        mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                    }
                }

            })
            .catch(error => {
                mobiscroll.notification.dismiss();
                logger(error);
                mobiscroll.toast({message: 'Error ME1, please try again. If the problem continues, please log out and log back in again.', duration: 6000, color: 'danger'});
                mpoSentry.captureException(error);
                const updatedState = updateObject(this.state, {
                    isLoading: false
                });
                if (debugMode) logger('setState loadMerchantPostDataHandler e2');
                this.setState(updatedState);
            });
    }

    loadModifiersPostDataHandler = (menuItemId) => {
        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: 'Loading product...', duration: 3000, display: 'center', color: 'info'});
        const data = {
            RequestAction: 'MenuItemDetails',
            menu_item_id: menuItemId,
            cart_item_slot_id: -1
        };
        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                //mobiscroll.notification.dismiss();
                if (response.data.ResponseCode === "SUCCESS") {
                    this.showModifiersPopup(response.data.Response);
                } else {
                    mobiscroll.notification.dismiss();
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                }
            })
            .catch(error => {
                //logger(error);
                mobiscroll.notification.dismiss();
                mobiscroll.toast({message: 'Error ME2, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });            
    }

    showModifiersPopup = (data) => {

        mpoSentry.addBreadcrumb('nav','showModifiersPopup',Sentry.Severity.Info);

        let modifiers = data.options;
        /*
        let modifiers_formatted = [];

        modifiers.map((modifier, idx)=> {
            let options = modifier.options;
            let items = [];
            let modifier_data = [];
            let defaults = [];
            options.map((option, idx) => {
                let data = {

                }
                if (option.is_default) {
                    defaults.push(option.id);
                }
            });
        });
        */

        // console.log(this.modifiersModal);
        // if (this.modifiersModal.current === null) {
        //     this.modifiersModal = React.createRef();
        //     console.log(this.modifiersModal);
        // }

        if (this.modifiersModal.current !== null) {
            this.modifiersModal.current.setState({
                isLoading: false,
                store_id: this.props.merchant.id,
                menu_id: this.props.merchant.menu_id,
                menu_item_id: parseInt(data.item_id, 10),
                name: data.name,
                desc: data.desc,
                qty: 1,
                min_qty: parseInt(data.min_qty, 10),
                max_qty: data.max_qty && parseInt(data.max_qty, 10) > 0 ? data.max_qty : 999,
                qty_label: data.qty_label,
                availability_notice: data.availability_notice,
                base_price: parseFloat(data.base_price),
                thumb_url: this.state.hasOwnProperty('display_images') && this.state.display_images ? data.thumb_url : "",
                image_url: this.state.hasOwnProperty('display_images') && this.state.display_images ? data.image_url : "",
                cart_item_slot_id: parseInt(data.cart_item_slot_id, 10),
                modifiers: modifiers,
                selectedOptions: null,
                addToCartLabel: "Add To Cart",
                addToCartIcon: "cart",
                goToCart: false,
                displayQty: true,
                validateOnly: false
            });

            this.modifiersModal.current.refs.popupModifiers.instance.show();
        } else {
            // component unmounted?
            mobiscroll.toast({message: "Error displaying modifiers, try again", color: 'danger'});
            mpoSentry.captureException("this.modifiersModal.current === null (menu)");
        }

    }

    onListItemTap = (event, inst) => {

        if (!mpoFulfilment.validFulfilment(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            this.showFulfilmentPopup();
        } else if (this.props.menuItems && this.props.menuItems.length > 0) {

            //logger(event.target);
            //logger(this.props.menuItems[event.index]);
            //logger(this.props.menuItems);
            //logger(this.state.menu_categories);

            let menuItem = null;
            const componentUsed = event.target.getAttribute("data-component");
            //logger("componentUsed: "+componentUsed);

            if (useMenuCategoryHierarchy || componentUsed === "CategoryListItem" || componentUsed === "ProductCardItem") {
                let menuItemId = event.target.getAttribute("data-itemid");
                if (menuItemId && !isNaN(menuItemId) && parseInt(menuItemId, 10) > 0) {

                    for (var j = 0; j < this.props.menuItems.length; j++) {
                        if (this.props.menuItems[j].hasOwnProperty('id') && this.props.menuItems[j].id === menuItemId) {
                            menuItem = this.props.menuItems[j];
                            break;
                        }
                    }

                } else {
                    //logger('header');
                    if (componentUsed === "CategoryListItem") {
                        let selectedCategory = null;
                        const catId = event.target.getAttribute('data-catid');
                        for (var k = 0; k < this.state.menu_categories.length; k++) {
                            if (this.state.menu_categories[k].hasOwnProperty('id') && this.state.menu_categories[k].id === catId) {
                                selectedCategory = this.state.menu_categories[k];
                                break;
                            }
                        }
                        if (selectedCategory !== null && selectedCategory !== this.state.selected_category) {
                            const updatedState = updateObject(this.state, {
                                selected_category: selectedCategory
                            });
                            if (debugMode) logger('setState onListItemTap');
                            this.setState(updatedState);
                            if (!isSafari && !isMobileSafari && !isLegacyEdge && !isIE) {
                                //console.log(typeof this.topOfMenu.current.scrollIntoView === 'function');
                                if (this.topOfMenu && this.topOfMenu.current && typeof this.topOfMenu.current.scrollIntoView === 'function')
                                    this.topOfMenu.current.scrollIntoView({behavior: 'smooth'});
                            }
                        }
                    }
                    return;
                }

            } else if (this.props.menuItems[event.index] && this.props.menuItems[event.index].hasOwnProperty('id')) {

                // ProductListItem

                if (this.props.menuItems[event.index].hasOwnProperty('header') && this.props.menuItems[event.index].header) {
                    //logger('header');
                    return;
                }
    
                menuItem = this.props.menuItems[event.index];

            } else {

                //logger('no item')
                return;

            }

            //console.log(event, inst);
            //console.log("menu_item_id", menuItemId);
            if (menuItem && menuItem.hasOwnProperty('id') && !isNaN(menuItem.id) && parseInt(menuItem.id, 10) > 0) {
                if (menuItem.hasOwnProperty('is_available') && menuItem.is_available) {
                    //let optionCount = event.target.getAttribute("data-mpo-option-count");
                    //console.log("option_count", optionCount);
                    /*
                    if (optionCount > 0) {
                        // show modifiers
                        this.loadModifiersPostDataHandler(menuItemId);
                    } else {
                        // add to cart immediately
                        this.addToCartPostDataHandler(menuItemId);
                    }
                    */
                   this.loadModifiersPostDataHandler(menuItem.id);
                } else if (menuItem.hasOwnProperty('available_str') && menuItem.available_str !== "") {
                    mobiscroll.toast({message: menuItem.available_str, color: 'info'});
                } else {
                    mobiscroll.toast({message: "Product unavailable", color: 'info'});
                }
            } else {
                //console.error(event.target);
                mobiscroll.toast({message: 'Error ME3, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
            }
        } else {
            // logger(event);
            // logger(this.props.menuItems);
        }
    }

    selectMerchantCardTab = (tabName) => {
        const updatedState = updateObject(this.state, {
            merchantCardTab: tabName
        });
        if (debugMode) logger('setState selectMerchantCardTab');
        this.setState(updatedState);
    }

    onPromoCodeChange = (e) => {
        const updatedState = updateObject(this.state, {
            coupon_code: e.target.value.toUpperCase()
        });
        if (debugMode) logger('setState onPromoCodeChange');
        this.setState(updatedState);
    }

    updatePromoCode = (event, inst) => {
        if (event.button === 'set') {
            mpoCheckout.updateCheckout(
                this.props.merchant.id,
                this.props.merchant.menu_id,
                { coupon_code: this.state.coupon_code },
                'Menu.updatePromoCode'
            );
        }
    }

    setListRef = (comp) => {
        this.listRef = comp;
    }

    onListEnd = (event, inst) => {
        if (debugMode) logger('onListEnd '+useMenuCategoryHierarchy+' '+maxInitialMenuItems);
        if (!useMenuCategoryHierarchy && this.state.hasOwnProperty('menu') && this.state.hasOwnProperty('menu_all') &&
            this.state.menu_all.length > 0 && this.state.menu_all.length > this.state.menu.length) {
            let numItems = this.state.menu.length+maxInitialMenuItems;
            if (numItems+1 >= this.state.menu_all.length) {
                numItems = this.state.menu_all.length;
            }
            let menu = this.state.menu_all.slice(0,numItems);
            if (menu.length < this.state.menu_all.length) {
                menu.push(placeholderItem);
            }
            const updatedState = updateObject(this.state, {
                menu: menu
            });
            if (debugMode) logger('setState onListEnd');
            this.setState(updatedState);
        }

    }

    setShowMenu = (showMenu, menuExtra) => {
        const updatedState = updateObject(this.state, {
            showMenu: showMenu,
            menuExtra: menuExtra
        });
        if (debugMode) logger('setState setShowMenu ' + showMenu + ' ' + menuExtra);
        this.setState(updatedState);
    }

    onMenuCategoryTap = (event, inst) => {
        const catId = event.target.getAttribute('data-catid');
        const list = document.querySelector('.mbsc-product-list');
        if (typeof list.querySelector('.' + catId).scrollIntoView === 'function') {
            list.querySelector('.' + catId).scrollIntoView();
        } else {
            this.listRef.instance.navigate(list.querySelector('.' + catId).nextElementSibling);
        }
    }

    isOrderPresent = () => {
        return this.props.order !== undefined && this.props.order.hasOwnProperty('num_items') && this.props.order.num_items !== undefined && parseInt(this.props.order.num_items,10) > 0;
    }

    hasOrderHistory = () => {
        return this.props.user.auth.isLoggedIn && this.props.user.auth.isMember && this.props.merchant !== undefined &&
            this.props.merchant.hasOwnProperty('history') && this.props.merchant.history !== undefined && this.props.merchant.history.length > 0;
    }

    isValidDeliveryAddress = () => {
        return (this.props.user.checkout.delivery_address_id > 0 || this.props.user.checkout.delivery_postcode !== "") && this.props.user.checkout.delivery_validated && this.props.user.checkout.delivery_postcode_id > 0;
    }

    onFulfilmentPopupClose = () => {
        // if (debugMode) {
        //     logger('onFulfilmentPopupClose');
        // }
        //logger(this.state);
        if (!mpoFulfilment.validFulfilment(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            //logger('show again');
            if (this.fulfilmentModal.current !== null) {
                mobiscroll.toast({message: "Fulfilment type is required", duration: 1500, color: 'danger'});
                setTimeout(() => {
                    this.showFulfilmentPopup();
                }, 500);
            } // else component unmounted, e.g. clicking back button on android and going back to locations
        } else if (mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            this.setShowMenu(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
        } else if (mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) || mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            this.setShowMenu(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
        } else if (mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            this.setShowMenu(false, mpoFulfilment.fulfilmentTypeCodePickup);
        // } else if (this.props.fulfilmentType !== undefined && Object.keys(this.props.fulfilmentType).length > 0) {
        //     logger('onFulfilmentPopupClose: load menu '+this.state.menuSlug+ ' for ' +this.props.fulfilmentOptions.code);
        //     this.loadMerchantPostDataHandler(this.state.menuSlug, true, this.props.fulfilmentOptions.code);
        } else if (debugMode) {
            logger('onFulfilmentPopupClose: no action');
        }
    }

    onMenuSearchChange = (e) => {
        const updatedState = updateObject(this.state, {
            menuSearch: e.target.value
        });
        if (debugMode) logger('setState onMenuSearchChange');
        this.setState(updatedState);
    }

    render = () => {
        //logger('Menu render');

        const defaultLogoSquare = process.env.REACT_APP_DEFAULT_LOGO_SQUARE;
        const displayLogoSquare = process.env.REACT_APP_DISPLAY_LOGO_SQUARE === 'true';

        const isOrderPresent = !this.state.isLoading && this.isOrderPresent();

        const fulfilmentDesc = mpoFulfilment.getFulfilmentDesc(this.props.fulfilmentType, this.props.fulfilmentOptions);

        const isDeliveryAddressRequired = mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showDeliverySlots = mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions); //&& this.isValidDeliveryAddress();
        const showDeliveryStops = mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showPickupLocations = mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showDeliveryOptions = isDeliveryAddressRequired || showDeliverySlots || showDeliveryStops || showPickupLocations;

        const placeholderMenuItems = [
            placeholderItem,
            {
                id: "product-placeholder2",
                key: "product-placeholder2",
                product_placeholder: 1
            },
            {
                id: "product-placeholder3",
                key: "product-placeholder3",
                product_placeholder: 1
            }
        ];

        //const menuItems = this.state.isLoading ? placeholderMenuItems : this.state.menu;
        let menuItems = this.state.isLoading ? placeholderMenuItems :
            (this.state.menuSearch === "" ?
                this.state.menu
                /*
                this.state.menu.filter((menuItem) => {
                    logger(menuItem);
                    return (menuItem.hasOwnProperty('children') && menuItem.children !== null) ||
                        (mpoFulfilment.isPickupFulfilmentType(this.props.fulfilmentType, this.props.fulfilmentOptions) && menuItem.available_pickup) ||
                        (mpoFulfilment.isDeliveryFulfilmentType(this.props.fulfilmentType, this.props.fulfilmentOptions) && menuItem.available_deliver) ||
                        (mpoFulfilment.isDineinFulfilmentType(this.props.fulfilmentType, this.props.fulfilmentOptions) && menuItem.available_dinein);
                })*/ :
                this.state.menu_all.filter((menuItem) => {
                    //logger(menuItem);
                    return !menuItem.hasOwnProperty('children') && menuItem.name.toLowerCase().includes(this.state.menuSearch.toLowerCase());
                })
            );
        //logger(menuItems);

        let menuCategoryList = null;
        //if (this.state.menu_categories.length > 3) {}
        //logger(this.state.menu_categories);
        /*
        menuCategoryList = this.state.menu_categories.map((cat) => <mobiscroll.OptionItem key={cat.id} data-catid={cat.id}>{cat.name}</mobiscroll.OptionItem>);
        if (menuCategoryList) {
            menuCategoryList = <mobiscroll.Optionlist
                type="tabs"
                display="top"
                select="single"
                onItemTap={this.onMenuCategoryTap}>
                {menuCategoryList}
            </mobiscroll.Optionlist>
        }
         */

        //const lastOrder = this.props.user.auth.isLoggedIn && this.props.user.auth.isMember ? '' : 'Sign in to repeat your last order';
        let lastOrder = isAppModePersonal() ? <div style={{padding: "0.5em 0", borderTop: "#efeff4 solid 1px", marginTop: "1em"}}><span className="empty icon fas fa-file-alt"></span> <span className="mbsc-txt-s"><a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/login') }}>Sign in</a> to repeat previous orders</span></div> : null;
        if (this.props.user.auth.isLoggedIn && this.props.user.auth.isMember) {
            if (this.state.showMenu && this.hasOrderHistory()) {
                lastOrder = this.props.merchant.history.map((order) => {
                    return <OrderItem key={order.id} item={order} merchantId={this.props.merchant.id} menuId={this.props.merchant.menu_id} borderPos="top" />
                })[0];
            } else {
                lastOrder = null;
            }
        }

        const defaultMerchantCardTabContent = <div>
            <span className="mbsc-txt-s mbsc-pull-right"><mobiscroll.Button flat={true} onClick={() => {this.showFulfilmentPopup();}}>{fulfilmentDesc !== "" ? "Change" : "Choose" }</mobiscroll.Button></span>
            <span className="mbsc-txt-s"><span className="empty icon fas fa-clock"></span> {fulfilmentDesc !== "" ? (!mpoFulfilment.isValidDateTime(this.props.fulfilmentType, this.props.fulfilmentOptions) ? <span style={{color:"red"}}>{fulfilmentDesc}</span> : <span>{fulfilmentDesc}</span>) : <span style={{color:"red"}}>Choose fulfilment type:</span> }</span>
            {lastOrder}
        </div>

        const infoText = this.state.info ? <div className="mbsc-txt-s" dangerouslySetInnerHTML={{__html: nl2br(this.state.info)}}></div> : null;
        const promoText = this.props.merchant.hasOwnProperty('promo_text') && this.props.merchant.promo_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-bullhorn"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.promo_text)}}></span></mobiscroll.Note> : null;
        const paymentText = this.props.merchant.hasOwnProperty('payment_text') && this.props.merchant.payment_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-credit-card"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.payment_text)}}></span></mobiscroll.Note> : null;
        const deliveryText = this.props.merchant.hasOwnProperty('delivery_text') && this.props.merchant.delivery_text ? <mobiscroll.Note color="info"><span className="empty icon fas fa-motorcycle"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.delivery_text)}}></span></mobiscroll.Note> : null;
        const warningText = this.props.merchant.hasOwnProperty('warning_text') && this.props.merchant.warning_text ? <mobiscroll.Note color="warning"><span className="empty icon fas fa-exclamation-triangle"></span> <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.warning_text)}}></span></mobiscroll.Note> : null;

        let merchantCardTabContent = null;

        if (this.props.slug === this.state.menuSlug) {
            switch (this.state.merchantCardTab) {
                case 'hours':
                    if (fulfilmentDesc !== "") {
                        const hours = this.state.hours ? this.state.hours : "Trading hours currently unavailable.";
                        merchantCardTabContent = <div>
                            <span className="mbsc-txt-s" dangerouslySetInnerHTML={{__html: nl2br(hours)}}></span>
                        </div>
                    } else {
                        merchantCardTabContent = defaultMerchantCardTabContent
                    }
                    break;
                case 'promos':
                    let promos = this.props.merchant.hasOwnProperty('promos') && this.props.merchant.promos !== undefined && this.props.merchant.promos.length > 0 ? this.props.merchant.promos.map((promo) => {
                        return <PromoItem key={promo.id} item={promo} authed={this.props.user.auth.isLoggedIn && this.props.user.auth.isMember} />
                    }) : null;

                    merchantCardTabContent = <div>
                        {promos}
                        <div className="mbsc-btn-group" style={{textAlign: "center", margin: 0, marginTop: "0.5em"}}>
                            <span className="mbsc-txt-s">
                                <mobiscroll.Button icon="bullhorn" flat={true} onClick={() => {this.refs.popupPromoCode.instance.show()}}>{this.state.coupon_code !== undefined && this.state.coupon_code !== null && this.state.coupon_code !== "" ? this.state.coupon_code : "Enter voucher or promo code"} </mobiscroll.Button>
                            </span>
                        </div> 
                    </div>
                    break;
                case 'history':
                    let history = isAppModePersonal() ? <div><span className="empty icon fas fa-file-alt"></span> <span className="mbsc-txt-s"><a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/login') }}>Sign in</a> to repeat previous orders</span></div> : null;
                    let fullHistory = null;

                    if (this.props.user.auth.isLoggedIn && this.props.user.auth.isMember) {
                        if (this.hasOrderHistory()) {
                            history = this.props.merchant.history.map((order) => {
                                return <OrderItem key={order.id} item={order} merchantId={this.props.merchant.id} menuId={this.props.merchant.menu_id} />
                            });

                            //fullHistory = <div className="mbsc-align-center mbsc-txt-s" style={{paddingTop: "0.5em"}}><a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push('/account/orders/'+this.props.slug) }}>View all previous orders</a></div>
                        } else {
                            history = <span className="mbsc-txt-s">No previous orders found</span>
                        }
                    }
                
                    merchantCardTabContent = <div>
                        {history}
                        {fullHistory}
                    </div>
                    /*
                    if (this.props.user.auth.isLoggedIn && this.props.user.auth.isMember) {

                    } else {
                        merchantCardTabContent = <div>
                            <span className="mbsc-txt-s mbsc-pull-right"><mobiscroll.Button flat={true} onClick={() => {this.props.user.auth.isLoggedIn && this.props.user.auth.isMember ? this.props.history.push('/account/orders') : this.props.history.push('/login') }}>{lastOrderBtn}</mobiscroll.Button></span>
                            <span className="mbsc-txt-s">{lastOrder}</span>
                            <mobiscroll.Button onClick={() => { this.props.user.auth.isLoggedIn && this.props.user.auth.isMember ? this.props.history.push('/checkout') : this.refs.popupProceed.instance.show() }} color="success" style={{color: '#fff'}}>Proceed to Checkout</mobiscroll.Button>
                        </div>
                    }
                    */
                    break;
                case 'dietary':
                    merchantCardTabContent = <div><span className="mbsc-txt-s">
                        (GF) = Gluten Free, (EF) = Egg Free, (DF) = Dairy Free, (LF) = Lactose Free, (V) = Vegetarian, (VE) = Vegan, (Halal) = Halal<br />
                        </span>
                        <span className="mbsc-desc" style={{padding: "0.5em 0"}}>
                        Dietary information is provided as a reference only. If you have any questions about ingredients, please ask in-store for assistance. The merchant and the developer are not responsible for the accuracy of the information provided.
                    </span></div>
                    break;
                case 'info':
                    merchantCardTabContent = <div>
                        {warningText}
                        {infoText}
                        {promoText}
                        {paymentText}
                        {deliveryText}
                    </div>
                    break;
                default:
                    merchantCardTabContent = defaultMerchantCardTabContent
            }
        }

        let downloadAppJsx = null;
        const appDownloadInfo = isCustomApp && !isIframe() && mobiscroll.platform.name !== 'ios' ? mpoAppStore.getAppDownloadInfo() : null;

        if (appDownloadInfo !== null && appDownloadInfo.downloadAppUrl !== "" && !this.state.isLoading) {
            downloadAppJsx = <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                <mobiscroll.Button block={true} data-icon={appDownloadInfo.downloadAppIcon} onClick={(e) => { e.preventDefault(); mpoAppStore.downloadApp(appDownloadInfo.downloadAppUrl); }}>{appDownloadInfo.downloadAppText}</mobiscroll.Button>
            </div>
        }

        // web app contains footer
        const appTabStyle = isCordova() ? {} : {paddingBottom: 0};

        return (
            <div className="app-tab" style={appTabStyle}>
                <Helmet>
                    <title>{`${this.props.merchant.name} ${this.props.merchant.suburb} Online Menu`}</title>
                </Helmet>
                <PullToRefresh
                    pullDownContent={<PullDownContent />}
                    releaseContent={<ReleaseContent />}
                    refreshContent={<RefreshContent />}
                    pullDownThreshold={100}
                    onRefresh={() => new Promise((resolve) => {
                        //this.loadMerchantPostDataHandler(this.state.menuSlug, false, "");
                        window.location.reload();
                        resolve();
                    })}
                    triggerHeight={500}
                    startInvisible={true}>

                <mobiscroll.Form className="mpo-form-width-responsive" labelStyle="stacked">

                    {this.props.slug === this.state.menuSlug ?
                    <mobiscroll.Card>
                        <mobiscroll.CardContent>
                            {displayLogoSquare ? <img className="mbsc-pull-left" style={{maxWidth: "80px", paddingRight: "1em"}} src={this.props.merchant.logo_square_url} alt="Logo" onError={(e)=>{e.target.onerror = null; e.target.src=defaultLogoSquare}} /> : null }
                            <mobiscroll.CardTitle>{this.props.merchant.name} {isCustomApp && !isSingleStore && displaySuburbName ? this.props.merchant.suburb : null}</mobiscroll.CardTitle>
                            <mobiscroll.CardSubtitle>
                                <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.address1)}}></span> {!isCustomApp || isSingleStore || !displaySuburbName ? this.props.merchant.suburb : null}
                                {this.props.merchant.phone ? <span style={{fontSize: "0.86em"}}><br/>Ph: {this.props.merchant.phone}</span> : null}
                            </mobiscroll.CardSubtitle>
                        </mobiscroll.CardContent>
                        {!this.state.appStore.redirect && (this.state.is_merchant_ready || this.state.is_order_for_later_enabled) ?
                        <React.Fragment>
                            <mobiscroll.TabNav display="inline" className="md-card-tab-nav">
                                <mobiscroll.NavItem isActive={() => this.state.merchantCardTab === 'main'} selected={this.state.merchantCardTab === 'main'} onClick={this.selectMerchantCardTab.bind(null, 'main')}>Fulfilment</mobiscroll.NavItem>
                                {this.props.merchant.hasOwnProperty('promos') && this.props.merchant.promos !== undefined && this.props.merchant.promos.length > 0 ? <mobiscroll.NavItem isActive={() => this.state.merchantCardTab === 'promos'} selected={this.state.merchantCardTab === 'promos'} onClick={this.selectMerchantCardTab.bind(null, 'promos')}>Promos</mobiscroll.NavItem> : null }
                                {isAppModePersonal() && this.state.showMenu ? <mobiscroll.NavItem isActive={() => this.state.merchantCardTab === 'history'} selected={this.state.merchantCardTab === 'history'} onClick={this.selectMerchantCardTab.bind(null, 'history')}>History</mobiscroll.NavItem> : null }
                                {/*<mobiscroll.NavItem isActive={() => this.state.merchantCardTab === 'hours'} selected={this.state.merchantCardTab === 'hours'} onClick={this.selectMerchantCardTab.bind(null, 'hours')}>Hours</mobiscroll.NavItem>*/}
                                <mobiscroll.NavItem isActive={() => this.state.merchantCardTab === 'dietary'} selected={this.state.merchantCardTab === 'dietary'} onClick={this.selectMerchantCardTab.bind(null, 'dietary')}>Dietary</mobiscroll.NavItem>
                                {/*{this.state.info ? <mobiscroll.NavItem isActive={() => this.state.merchantCardTab === 'info'} selected={this.state.merchantCardTab === 'info'} onClick={this.selectMerchantCardTab.bind(null, 'info')}>Info</mobiscroll.NavItem> : null }*/}
                            </mobiscroll.TabNav>
                            <mobiscroll.CardFooter style={{borderTop: "none"}}>
                                {merchantCardTabContent}
                            </mobiscroll.CardFooter>
                            {/*
                            <mobiscroll.CardFooter>
                                <span className="mbsc-txt-s mbsc-pull-right"><mobiscroll.Button flat={true} onClick={() => {this.props.user.auth.isLoggedIn && this.props.user.auth.isMember ? this.props.history.push('/checkout') : this.props.history.push('/login') }}>{lastOrderBtn}</mobiscroll.Button></span>
                                <span className="mbsc-txt-s">{lastOrder}</span>
                            </mobiscroll.CardFooter>
                            */}
                            {this.state.merchantCardTab === 'main' && infoText ?
                            <mobiscroll.CardFooter>
                                {infoText}
                            </mobiscroll.CardFooter>
                            : null }
                        </React.Fragment>
                        : null }
                    </mobiscroll.Card>
                    :
                    <mobiscroll.Card>
                        <mobiscroll.CardContent>
                            <ContentLoader 
                                height={80}
                                width={400}
                                speed={2}
                                primaryColor="#f3f3f3"
                                secondaryColor="#a8a8a8">
                                <rect x="100" y="15" rx="5" ry="5" width="240" height="11" /> 
                                <rect x="100" y="35" rx="5" ry="5" width="207" height="9" /> 
                                <rect x="0" y="5" rx="0" ry="0" width="80" height="80" />
                            </ContentLoader>
                        </mobiscroll.CardContent>
                    </mobiscroll.Card>
                    }

                    {this.state.appStore.redirect ? 
                    <React.Fragment>
                        <div className="mbsc-empty">
                            <h3>{this.state.appStore.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: nl2br(this.state.appStore.text)}}></p>
                            <div className="mbsc-btn-group-block">
                                {this.state.appStore.urlIosApp ? <mobiscroll.Button color="secondary" data-icon="empty icon fas fa-apple" onClick={()=>{openWindow(this.state.appStore.urlIosApp, '_system', '', isCustomApp)}}>App Store</mobiscroll.Button> : null }
                                {this.state.appStore.urlAndroidApp ? <mobiscroll.Button color="secondary" data-icon="empty icon fas fa-google-play" onClick={()=>{openWindow(this.state.appStore.urlAndroidApp, '_system', '', isCustomApp)}}>Google Play</mobiscroll.Button> : null }
                                {this.state.appStore.urlWebApp ? <mobiscroll.Button color="primary" data-icon="empty icon fas fa-globe" onClick={()=>{openWindow(this.state.appStore.urlWebApp, '_system', '', isCustomApp)}}>Open Web Site</mobiscroll.Button> : null }
                            </div>
                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/') }}>Back to Home</mobiscroll.Button>
                            </div> 
                        </div>
                    </React.Fragment>    
                    :
                    <React.Fragment>
                        {warningText}
                        {promoText}
                        {paymentText}
                        {deliveryText}
                        {downloadAppJsx}
                        {this.state.isLoading || this.state.is_merchant_ready || this.state.is_order_for_later_enabled ?
                        <React.Fragment>
                            {this.state.showMenu ?
                            <React.Fragment>

                                {useMenuCategoryHierarchy ?
                                    <React.Fragment>
                                        <div className="show-on-small-device">

                                            {menuCategoryList}

                                            <mobiscroll.Listview
                                                ref={this.setListRef}
                                                className="mbsc-product-list"
                                                itemType={ProductListItem}
                                                data={menuItems}
                                                fixedHeader={true}
                                                enhance={true}
                                                swipe={false}
                                                onItemTap={this.onListItemTap}
                                                onListEnd={this.onListEnd}
                                            />

                                            <div className="mbsc-btn-group-block">
                                                <mobiscroll.Button color="success" style={{color: '#fff'}} disabled={!isOrderPresent} onClick={() => { this.props.history.push('/cart') }}>Proceed to Cart</mobiscroll.Button>
                                            </div>
                                        </div>
                                        <div className="show-on-large-device" style={{scrollMarginTop: scrollMarginTop}} ref={this.topOfMenu}>
                                            <div className="mbsc-grid-fixed">
                                                <div className="mbsc-row">
                                                    <div className="mbsc-col-3" style={{paddingRight: 0}}>
                                                        <mobiscroll.Listview
                                                            ref={this.setListRef}
                                                            className="mbsc-product-list"
                                                            itemType={CategoryListItem}
                                                            data={this.state.menu_categories}
                                                            fixedHeader={true}
                                                            enhance={true}
                                                            swipe={false}
                                                            onItemTap={this.onListItemTap}
                                                            select="single"
                                                        />

                                                        <div className="mbsc-btn-group-block">
                                                            <mobiscroll.Button color="success" style={{color: '#fff'}} disabled={!isOrderPresent} onClick={() => { this.props.history.push('/cart') }}>Proceed to Cart</mobiscroll.Button>
                                                        </div>
                                                    </div>
                                                    <div className="mbsc-col-9" style={{paddingLeft: 0}}>
                                                        <div className="mbsc-grid">
                                                            <div className="mbsc-row mbsc-no-padding">
                                                                {this.state.selected_category !== null && this.state.selected_category.hasOwnProperty('children') ?
                                                                    this.state.selected_category.children.map((menuItem) => {
                                                                        if (this.state.menuSearch === "" || menuItem.name.toLowerCase().includes(this.state.menuSearch.toLowerCase())) {
                                                                            return <ProductCardItem item={menuItem}
                                                                                                    key={menuItem.id}
                                                                                                    displayImages={this.state.display_images}
                                                                                                    locale={this.props.merchant.locale}
                                                                                                    onClick={this.onListItemTap}/>;
                                                                        }
                                                                        return null;
                                                                    })
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                :
                                    <React.Fragment>
                                        <mobiscroll.FormGroup className="mpo-checkout-form">
                                            <mobiscroll.Input labelStyle="floating" value={this.state.menuSearch} onChange={this.onMenuSearchChange} name="menuSearch">Search product</mobiscroll.Input>
                                        </mobiscroll.FormGroup>

                                        <div className="show-on-small-device">
                                            {menuCategoryList}

                                            <mobiscroll.Listview
                                                ref={this.setListRef}
                                                className="mbsc-product-list"
                                                itemType={ProductListItem}
                                                data={menuItems}
                                                fixedHeader={true}
                                                enhance={true}
                                                swipe={false}
                                                onItemTap={this.onListItemTap}
                                                onListEnd={this.onListEnd}
                                            />
                                        </div>
                                        <div className="show-on-large-device" style={{scrollMarginTop: scrollMarginTop}} ref={this.topOfMenu}>
                                            <div className="mbsc-grid-fixed">
                                                <div className="mbsc-row">
                                                    <div className="mbsc-col-12" style={{paddingLeft: 0}}>
                                                        <div className="mbsc-grid">
                                                            <div className="mbsc-row mbsc-no-padding">
                                                                {menuItems.length === 0 ?
                                                                    <div className="mbsc-empty">
                                                                        <h3>No results</h3>
                                                                        <p></p>
                                                                    </div>
                                                                    :
                                                                    menuItems.map((item, index) => {
                                                                    if (this.state.menuSearch === "" || item.name.toLowerCase().includes(this.state.menuSearch.toLowerCase())) {
                                                                        if (item.header) {
                                                                            return <div className="mbsc-col-12" key={item.id} style={{background: "#f2f2f2", marginBottom: "10px"}}>
                                                                                <mobiscroll.FormGroup inset className="mpo-checkout-form">
                                                                                    <div><h3>{item.name}</h3>{item.desc ? <h5>{item.desc}</h5> : null}</div>
                                                                                </mobiscroll.FormGroup>
                                                                            </div>
                                                                        } else {
                                                                            return <ProductCardItem item={item}
                                                                                                    key={item.id}
                                                                                                    displayImages={this.state.display_images}
                                                                                                    locale={this.props.merchant.locale}
                                                                                                    onClick={this.onListItemTap}
                                                                                                    fullScreen={true} />;
                                                                        }
                                                                    }
                                                                    return null;
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mbsc-btn-group-block">
                                            <mobiscroll.Button color="success" style={{color: '#fff'}} disabled={!isOrderPresent} onClick={() => { this.props.history.push('/cart') }}>Proceed to Cart</mobiscroll.Button>
                                        </div>
                                    </React.Fragment>
                                }

                            </React.Fragment>
                            :
                            <React.Fragment>
                                {showDeliveryOptions ?
                                <React.Fragment>
                                    {isDeliveryAddressRequired ?
                                    <DeliveryAddress headerText="Delivery" showTick={this.isValidDeliveryAddress()} bodyText={this.isValidDeliveryAddress() ? "We deliver to your address. |deliveryEstimate|" : "Enter your delivery address to check we deliver to your area"} showValidateButton={true} />
                                    : null}

                                    {showDeliverySlots ?
                                    <DeliverySlot deliveryPostcodeId={this.props.user.checkout.delivery_postcode_id} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Delivery Slot" bodyText="Choose an available delivery date and time slot" />
                                    : null }

                                    {showDeliveryStops ?
                                    <DeliveryStop updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Delivery Location" bodyText="Choose an available delivery date and location" />
                                    : null }

                                    {showPickupLocations ?
                                    <PickupLocation updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Pickup Location" bodyText="Choose an available date and location" />
                                    : null }

                                    <div className="mbsc-btn-group-block">
                                        <mobiscroll.Button color="success" style={{color: '#fff'}} disabled={(isDeliveryAddressRequired && !this.isValidDeliveryAddress()) || (showDeliverySlots && !mpoFulfilment.isValidDeliverySlot(this.props.fulfilmentType, this.props.fulfilmentOptions)) || (showDeliveryStops && !mpoFulfilment.isValidDeliveryStop(this.props.fulfilmentType, this.props.fulfilmentOptions)) || (showPickupLocations && !mpoFulfilment.isValidPickupLocation(this.props.fulfilmentType, this.props.fulfilmentOptions))} onClick={() => { this.setShowMenu(true, ''); }}>Proceed to Menu</mobiscroll.Button>
                                    </div>
                                    {isDeliveryAddressRequired && !this.isValidDeliveryAddress() ?
                                    <div className="mbsc-btn-group-block">
                                        <mobiscroll.Button color="info" onClick={() => { this.setShowMenu(true, ''); }}>Skip Address</mobiscroll.Button>
                                    </div>
                                    : null}
                                </React.Fragment>
                                : null }
                            </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {this.state.hours !== null ?
                                <mobiscroll.Card className="mbsc-align-center">
                                    <mobiscroll.CardHeader>
                                        <mobiscroll.CardTitle>Trading Hours</mobiscroll.CardTitle>
                                        <mobiscroll.CardSubtitle>The store is currently closed.</mobiscroll.CardSubtitle>
                                    </mobiscroll.CardHeader>
                                    <mobiscroll.CardContent>
                                        <span dangerouslySetInnerHTML={{__html: nl2br(this.state.hours)}}></span>
                                    </mobiscroll.CardContent>
                                    <mobiscroll.CardFooter>Pull down to refresh.</mobiscroll.CardFooter>
                                </mobiscroll.Card>
                                :
                                <mobiscroll.Note color="info">
                                    <p className="mbsc-align-center">
                                        <b>The store is currently closed.</b>
                                        <br/><br/>
                                        (pull down to refresh)
                                    </p>
                                </mobiscroll.Note>
                            }
                            <br /><br /><br /><br />
                        </React.Fragment>
                        }
                    </React.Fragment>
                    }
                </mobiscroll.Form>

                {!isCordova() ?
                    <Footer merchant={this.props.merchant} />
                    : null }

                </PullToRefresh>

                <Modifiers ref={this.modifiersModal} updateStateWithOrder={this.props.updateStateWithOrder} history={this.props.history} locale={this.props.merchant.locale} />

                <FulfilmentPopup ref={this.fulfilmentModal} onClose={this.onFulfilmentPopupClose} updateStateWithFulfilmentTypeAndOptions={this.props.updateStateWithFulfilmentTypeAndOptions} updateStateWithFulfilmentType={this.props.updateStateWithFulfilmentType} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} />

                <mobiscroll.Popup
                    ref="popupPromoCode"
                    headerText={mobiscroll.platform.name !== 'ios' ? "Voucher/Promo Code" : null}
                    responsive={getPopupResponsiveSettings(mobiscroll.platform.name === 'ios')}
                    layout="liquid"
                    buttons={[{text: 'Save', handler: 'set'}]}
                    onBeforeClose={this.updatePromoCode}
                >
                    <mobiscroll.Form>
                        <mobiscroll.Input inputStyle="box" labelStyle="stacked" icon="bullhorn" iconAlign="left" placeholder=""
                        type="text" name="promoCode" style={{textTransform: "uppercase"}}
                        value={this.state.coupon_code} onChange={this.onPromoCodeChange}>Enter voucher or promo code</mobiscroll.Input>
                        { isCordova() ?
                        <div className="mbsc-btn-group-block">
                            <mobiscroll.Button icon="empty icon fas fa-qrcode" onClick={() => {mpoScanner.ScanQrCode(this.scanPromoCodeSuccess);}}>Scan QR Code</mobiscroll.Button>
                        </div>
                        : null }
                    </mobiscroll.Form>
                </mobiscroll.Popup>

            </div>
        );
    }

    scanPromoCodeSuccess = (result) => {
        if (!result.cancelled && result.format === 'QR_CODE') {
            const query = result.text.substring(result.text.lastIndexOf("?"));
            const params = queryString.parse(query);
            const promoCode = params.promo ? params.promo : null;

            if (promoCode) {
                const updatedState = updateObject(this.state, {
                    coupon_code: promoCode
                });
                this.setState(updatedState);
            }
        }
    }

}

const mapStateToProps = state => {
    return {
        slug: state.menu.slug,
        merchant: state.menu.merchant,
        menuItems: state.menu.menuItems,
        fulfilmentType: state.menu.fulfilmentType,
        fulfilmentOptions: state.menu.fulfilmentOptions,
        order: state.menu.order,
        cart: state.cart,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithSlug: (slug) => dispatch(actions.setSlugAction(slug)),
        updateStateWithMerchant: (merchant) => dispatch(actions.setMerchantAction(merchant)),
        updateStateWithMenuItems: (menuItems) => dispatch(actions.setMenuItemsAction(menuItems)),
        updateStateWithFulfilmentType: (fulfilmentType) => dispatch(actions.setFulfilmentTypeAction(fulfilmentType)),
        updateStateWithFulfilmentOptions: (fulfilmentOptions) => dispatch(actions.setFulfilmentOptionsAction(fulfilmentOptions)),
        updateStateWithFulfilmentTypeAndOptions: (fulfilmentType, fulfilmentOptions) => {
            dispatch(actions.setFulfilmentOptionsAction(fulfilmentOptions));
            dispatch(actions.setFulfilmentTypeAction(fulfilmentType));
        },
        updateStateWithOrder: (order) => dispatch(actions.setOrderAction(order)),
        updateStateWithCart: (cart) => {
            dispatch(actions.setCartAction(cart));
            dispatch(actions.setOrderAction(cart.order));
        },
        updateStateWithCheckout: (checkout) => dispatch(actions.setCheckoutAction(checkout)),
        updateStateWithCustomer: (customer) => dispatch(actions.setCustomerAction(customer, null, null))
    }
}

//export default Menu;
//export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler( Menu, axiosInstance ));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
