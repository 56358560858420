import React, { Component } from 'react';
import ContentLoader from "react-content-loader"
//import { logger } from '../../shared/utility';
import mobiscroll from "@mobiscroll/react";

class ProductListItem extends Component {

    render = () => {
        //logger(this.props);
        const defaultProductSquare = process.env.REACT_APP_DEFAULT_PRODUCT_SQUARE;
        //const defaultCategorySquare = process.env.REACT_APP_DEFAULT_PRODUCT_SQUARE;

        if (this.props.item.hasOwnProperty('product_placeholder')) {
            return (
                <li data-component="ProductListItem">
                    <ContentLoader 
                        key={this.props.item.key}
                        height={80}
                        width={400}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#a8a8a8"
                    >
                        <rect x="83" y="17" rx="5" ry="5" width="240" height="11" /> 
                        <rect x="84" y="45" rx="5" ry="5" width="207" height="9" /> 
                        <rect x="10" y="14" rx="0" ry="0" width="46" height="45" />
                    </ContentLoader>
                </li>
            )
        }

        //logger(this.props.item);
        if (this.props.item.header) {
            if (this.props.item.hasOwnProperty('hierarchy') && this.props.item.hierarchy && this.props.item.hasOwnProperty('children') && this.props.item.children !== null && this.props.item.children.length > 0) {
                /*
                let imgUrl = this.props.item.hasOwnProperty('img_url') && this.props.item.img_url ? this.props.item.img_url : null;
                const groupHeadClassName = imgUrl ? "mpo-product-group-head-with-image" : "mpo-product-group-head-no-image";
                return (<li key={this.props.item.id} data-component="ProductListItem" data-itemid="0" data-catid={this.props.item.id} className={groupHeadClassName}>
                    <span className="mbsc-pull-left">{imgUrl ? <img src={imgUrl} alt="img" onError={(e)=>{e.target.onerror = null; e.target.src=defaultCategorySquare}} /> : ''}</span>
                    <h4 className="mpo-product-group-head">{this.props.item.name}</h4>
                    {this.props.item.desc ? <p className="mpo-product-group-head-desc">{this.props.item.desc}</p> : ''}
                    {this.props.children}
                </li>);
                */
                //logger(this.props);
                let imgUrl = this.props.item.hasOwnProperty('img_url') && this.props.item.img_url ? this.props.item.img_url : null;
                //let imgUrl = this.props.item.img_url || this.props.item.img_url_lge;
                const bgColor = "#808080"; // "#4FBF39"
                const groupHeadClassName = imgUrl ? "mpo-product-group-head-with-image-bg" : "mpo-product-group-head-no-image";
                const lihStyle = imgUrl ? {backgroundColor: bgColor, backgroundSize: 'cover', backgroundImage: `url("${imgUrl}")`} : {};

                // iterate children and hide category if no children visible
                //logger(this.props.item.children);
                let numVisibleChildren = this.props.item.children.length;
                for (var i = 0; i < this.props.item.children.length; i++) {
                    if (this.props.item.children[i].hasOwnProperty('is_visible') && this.props.item.children[i].is_visible === false) {
                        numVisibleChildren--;
                    }
                }
                //logger(numVisibleChildren+'/'+this.props.item.children.length+' visible children')
                if (numVisibleChildren === 0 || (this.props.item.hasOwnProperty('is_visible') && this.props.item.is_visible === false)) {
                    lihStyle.display = 'none';
                    //logger(lihStyle);
                }

                return (<li key={this.props.item.id} data-component="ProductListItem" data-itemid="0" data-catid={this.props.item.id} className={groupHeadClassName} style={lihStyle}>
                    <h4 className="mpo-product-group-head">{this.props.item.name}</h4>
                    {this.props.item.desc ? <p className="mpo-product-group-head-desc">{this.props.item.desc}</p> : ''}
                    {this.props.children}
                </li>);
            } else {
                return (<li data-role="list-divider" data-component="ProductListItem" data-itemid="0" data-catid={this.props.item.id} className={this.props.item.id}>
                        <h4 className="mpo-product-group-head">{this.props.item.name}</h4>
                        {this.props.item.desc ? <p className="mpo-product-group-head-desc">{this.props.item.desc}</p> : ''}
                    </li>
                );
            }
        }

        //console.log(this.props.item);
        let dietary = "";
        if (this.props.item.is_gluten_free) {
            dietary += "(GF) ";
        }
        if (this.props.item.is_egg_free) {
            dietary += "(EF) ";
        }
        if (this.props.item.is_dairy_free) {
            dietary += "(DF) ";
        }
        if (this.props.item.is_lactose_free) {
            dietary += "(LF) ";
        }
        if (this.props.item.is_vegetarian) {
            dietary += "(V) ";
        }
        if (this.props.item.is_vegan) {
            dietary += "(VE) ";
        }
        if (this.props.item.is_halal) {
            dietary += "(Halal) ";
        }

        /*
        return (
            <li className="mpo-product-item" key={this.props.item.id} data-component="ProductListItem" data-itemid={this.props.item.id} data-catid={this.props.item.cat_id}>
                <span className="mpo-product-price">${this.props.item.base_price}</span>
                <span className="mbsc-stepper">
                    <span className="mbsc-segmented-item mbsc-stepper-control mbsc-stepper-plus" data-step="1" tabIndex="0" data-mpo-menu-item-id={this.props.item.id} data-mpo-option-count={this.props.item.option_count}>
                        <span className="mbsc-segmented-content" data-mpo-menu-item-id={this.props.item.id} data-mpo-option-count={this.props.item.option_count}> 
                            <span className="mbsc-ic mbsc-ic-plus" data-mpo-menu-item-id={this.props.item.id} data-mpo-option-count={this.props.item.option_count}></span>
                        </span>
                    </span>
                </span>
                <h3 className="mpo-product-head">{this.props.item.name}</h3>
                <span className="mbsc-pull-left">{this.props.item.img_url ? <img src={this.props.item.img_url} alt="Product" className="mbsc-lv-img" onError={(e)=>{e.target.onerror = null; e.target.src=defaultProductSquare}} /> : ''}</span>
                {this.props.item.is_available ? 
                <span className="mbsc-desc mpo-product-desc">{this.props.item.desc}</span>
                : 
                <span className="mbsc-desc mpo-product-desc">{this.props.item.available_str}</span> 
                }
                {this.props.item.is_available && dietary !== "" ? <span className="mbsc-desc mpo-product-dietary">{dietary}</span> : null}
            </li>
        );
        */

        const liStyle = this.props.item.hasOwnProperty('is_visible') && this.props.item.is_visible === false ? {display: 'none'} : {};
        let liClassNames = "mpo-product-item-large";
        let productImgUrl = this.props.item.img_url || this.props.item.img_url_lge;
        if (productImgUrl && this.props.item.hasOwnProperty('is_available') && this.props.item.is_available && this.props.item.desc !== null && this.props.item.desc !== "") {
            liClassNames += " mpo-product-item-large-reduce-padding";
        }

        return (
            <li className={liClassNames} key={this.props.item.cat_id+"_"+this.props.item.id} data-component="ProductListItem" data-key={this.props.item.cat_id+"_"+this.props.item.id} data-itemid={this.props.item.id} data-catid={this.props.item.cat_id} style={liStyle}>
                <h3 className="mpo-product-head-full">{this.props.item.name}</h3>
                <span>{productImgUrl ? <img src={productImgUrl} alt="Product" className="mbsc-lv-img-large" onError={(e)=>{e.target.onerror = null; e.target.src=defaultProductSquare}} /> : ''}</span>
                {this.props.item.is_available ?
                    <span className="mbsc-desc mpo-product-desc-full">{this.props.item.desc}</span>
                    :
                    <span className="mbsc-desc mpo-product-desc-full">{this.props.item.available_str}</span>
                }
                {this.props.item.is_available && dietary !== "" ? <span className="mbsc-desc mpo-product-dietary">{dietary}</span> : null}

                <div style={{clear: "Both"}}>
                {productImgUrl ?
                    <span className="mpo-product-price-bottom">${this.props.item.base_price}</span>
                    :
                    <span className="mpo-product-price-bottom-no-image">${this.props.item.base_price}</span>
                }

                <mobiscroll.Button className="mbsc-pull-right" disabled={!this.props.item.is_available} icon="plus" color="success" style={{color: '#fff', padding: "0.5em", position: 'absolute', bottom: '0.1em', right: '0.2em'}} data-component="ProductListItem" data-itemid={this.props.item.id} data-catid={this.props.item.cat_id} data-mpo-menu-item-id={this.props.item.id} data-mpo-option-count={this.props.item.option_count} onClick={(e,i) => { e.preventDefault(); }} />

                {/*this.props.item.is_available ?
                <span className="mbsc-stepper" style={{top: 'auto', bottom: '10px'}}>
                    <span className="mbsc-segmented-item mbsc-stepper-control mbsc-stepper-plus" data-step="1" tabIndex="0" data-mpo-menu-item-id={this.props.item.id} data-mpo-option-count={this.props.item.option_count}>
                        <span className="mbsc-segmented-content" data-mpo-menu-item-id={this.props.item.id} data-mpo-option-count={this.props.item.option_count}>
                            <span className="mbsc-ic mbsc-ic-plus" data-mpo-menu-item-id={this.props.item.id} data-mpo-option-count={this.props.item.option_count}></span>
                        </span>
                    </span>
                </span>
                : null*/}
                </div>
            </li>
        );

    }
}

export default ProductListItem;