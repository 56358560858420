import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import {
    updateObject,
    logger,
    jsDateToday,
    unixDatetimeToDateAuEng,
    unixDatetimeToDate,
    attachDeviceInfoToData, isCordova
} from '../../shared/utility';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import {mpoOneSignal} from "../../lib/OneSignal";

class DeliveryStop extends Component {

    constructor (props) {
        super(props);
        //logger("DeliveryStop");
        //logger(props);

        this.state = {
            deliveryStopsOnDate: null,
            deliveryStopsCalendar: null,
            deliveryStopsInvalidDays: null,
            deliveryStopId: this.props.fulfilmentOptions.hasOwnProperty('deliveryStopId') ? this.props.fulfilmentOptions.deliveryStopId : 0,
            deliveryStopDesc: this.props.fulfilmentOptions.hasOwnProperty('deliveryStopDesc') ? this.props.fulfilmentOptions.deliveryStopDesc : ""
        }
    }

    onInit = (event, inst) => {
        //logger('DeliveryStop onInit');
        // logger(inst);
        // logger(inst.getVal(true));
        let datetime = null;
        let datetimeVal = null;

        if (this.props.fulfilmentOptions.datetimeVal === null) {
            const val = inst.getVal(true);
            datetime = val.getTime();
            datetimeVal = unixDatetimeToDateAuEng(datetime);
            this.props.updateStateWithFulfilmentOptions({
                ...this.props.fulfilmentOptions,
                asap: false,
                datetime: datetime,
                datetimeVal: datetimeVal
            });
        } else {
            datetime = this.props.fulfilmentOptions.datetime;
        }

        this.getDeliveryStops(datetime);
    }

    onSet = (event, inst) => {
        const datetime = inst.getVal().getTime();
        const datetimeVal = event.valueText;

        this.props.updateStateWithFulfilmentOptions({
            ...this.props.fulfilmentOptions,
            asap: false,
            datetime: datetime,
            datetimeVal: datetimeVal
        });

        this.getDeliveryStops(datetime);
    }

    getDeliveryStops = (datetime = null) => {

        const data = {
            RequestAction: 'DeliveryStops',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            fulfilment_date: datetime === null ? unixDatetimeToDate(this.props.fulfilmentOptions.datetime) : unixDatetimeToDate(datetime)
        };

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    let validStopId = false;
                    let deliveryStopId = this.state.deliveryStopId;
                    let deliveryStopDesc = this.state.deliveryStopDesc;
                    const deliveryStops = response.data.Response;
                    const deliveryStopsOnDate = deliveryStops.hasOwnProperty('stops_on_date') ? deliveryStops.stops_on_date : null;
                    const deliveryStopsCalendar = deliveryStops.hasOwnProperty('stops_calendar') ? deliveryStops.stops_calendar : null;
                    const deliveryStopsInvalidDays = deliveryStops.hasOwnProperty('stops_invalid_days') ? deliveryStops.stops_invalid_days : null;
                    if (deliveryStopId > 0 && deliveryStopsOnDate && deliveryStopsOnDate.length > 0) {
                        // check current stop id exists in new list of stops
                        for (let stop in deliveryStopsOnDate) {
                            if (parseInt(deliveryStopsOnDate[stop].delivery_stop_id,10) === parseInt(deliveryStopId,10)) {
                                validStopId = true;
                                break;
                            }
                        }
                    }
                    if (!validStopId) {
                        if (deliveryStopsOnDate && deliveryStopsOnDate.length > 0) {
                            deliveryStopId = deliveryStopsOnDate[0].delivery_stop_id;
                            deliveryStopDesc = deliveryStopsOnDate[0].display_label;
                        } else {
                            deliveryStopId = 0;
                            deliveryStopDesc = "";
                        }
                    }
                    const updatedState = updateObject(this.state, {
                        deliveryStopsOnDate: deliveryStopsOnDate,
                        deliveryStopsCalendar: deliveryStopsCalendar,
                        deliveryStopsInvalidDays: deliveryStopsInvalidDays
                    });
                    this.setState(updatedState);

                    if (deliveryStopId !== this.state.deliveryStopId || deliveryStopId !== this.props.fulfilmentOptions.deliveryStopId) {
                        this.setDeliveryStopId(deliveryStopId, deliveryStopDesc);
                    }
                } else {
                    let errorMsg = response.data.Response[0];
                    mobiscroll.toast({message: errorMsg, color: 'danger'});
                    mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);
                }
            })
        .catch(error => {
            logger(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    radioChange = (event) => {
        logger(event);
        this.setDeliveryStopId(parseInt(event.target.value,10), event.target.getAttribute("data-stop-desc"));
    }

    setDeliveryStopId = (id, desc) => {
        const updatedState = updateObject(this.state, {
            deliveryStopId: id,
            deliveryStopDesc: desc
        });
        this.setState(updatedState);

        this.props.updateStateWithFulfilmentOptions({
            ...this.props.fulfilmentOptions,
            deliveryStopId: id,
            deliveryStopDesc: desc
        });

        // this.updateCheckout to validate address and get delivery fee
        this.updateCheckout({'delivery_stop_id': id});
    }

    updateCheckout = (checkout_options) => {

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'update_checkout',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            checkout_options: checkout_options,
            component: 'DeliveryStop'
        };
        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        //console.log('updateCheckout', data);
        axiosInstance.post(null, data)
            .then(response => {
                if (response.data.ResponseCode === "SUCCESS") {
                    if (parseInt(response.data.Response.affects_cart_total, 10) === 1) {
                        if (this.props.reloadCart !== undefined && typeof(this.props.reloadCart) === 'function') {
                            this.props.reloadCart();
                        }
                    }
                } else {
                    let errorMsg = response.data.Response[0];
                    mobiscroll.toast({message: errorMsg, color: 'danger'});
                    mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);

                    if (errorMsg.includes('Session expired')) {
                        this.props.updateStateWithCustomer({id: 0, status: 0});
                    }
                }
            })
            .catch(error => {
                logger(error);
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
            });

    }

    getDeliveryStopsJsx = () => {
        if (this.state.deliveryStopsOnDate === null || this.state.deliveryStopsOnDate.length === 0) {
            return <div className="mbsc-padding">
                <span className="empty icon fas fa-times-circle" style={{color: "red"}}></span> <span className="mbsc-txt-s">No delivery locations available for the chosen date</span>
            </div> 
        } else {
            const deliveryStops = this.state.deliveryStopsOnDate.map(stop => {
                return <mobiscroll.Radio key={stop.delivery_stop_id} name="delivery_stop" value={stop.delivery_stop_id} data-stop-desc={stop.display_label} checked={parseInt(this.state.deliveryStopId,10) === parseInt(stop.delivery_stop_id,10)} onChange={this.radioChange}>
                    {stop.display_label}
                    <span className="mbsc-desc">{stop.cut_off_time_str} {stop.available < 20 ? "(stop almost full)" : null}</span>
                </mobiscroll.Radio>
            });

            return <mobiscroll.FormGroup>
                <mobiscroll.FormGroupTitle>Delivery Location</mobiscroll.FormGroupTitle>
                <div className="mbsc-padding" style={{paddingTop: 0}}>
                    <span className="mbsc-txt-s">Choose an available delivery location on {this.props.fulfilmentOptions.datetimeVal}:</span>
                </div> 
                {deliveryStops}
            </mobiscroll.FormGroup>
        }
    }

    render = () => {

        const deliveryStopsJsx = this.getDeliveryStopsJsx();

        return (
            <mobiscroll.FormGroup inset className="mpo-checkout-form">

                    <mobiscroll.FormGroupTitle>Delivery Date</mobiscroll.FormGroupTitle>

                    <div className="mbsc-padding" style={{paddingTop: 0}}>
                        <span className="mbsc-txt-s">Choose an available delivery date</span>
                    </div> 

                    <mobiscroll.Calendar 
                        display="inline"
                        dateFormat="dd/mm/yy"
                        type="hidden"
                        controls={['calendar']}
                        yearChange={false}
                        min={jsDateToday()}
                        colors={this.state.deliveryStopsCalendar}
                        invalid={this.state.deliveryStopsInvalidDays}
                        onInit={this.onInit}
                        onSet={this.onSet}
                        value={this.props.fulfilmentOptions.datetimeVal}
                    />

                    {deliveryStopsJsx}

            </mobiscroll.FormGroup> 
        )

    }

}

const mapStateToProps = state => {
    return {
        merchant: state.menu.merchant,
        fulfilmentType: state.menu.fulfilmentType,
        fulfilmentOptions: state.menu.fulfilmentOptions,
        cart: state.cart,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCheckout: (checkout) => dispatch(actions.setCheckoutAction(checkout)),
        updateStateWithCustomer: (customer) => dispatch(actions.setCustomerAction(customer, null, null))
    }
}

//export default withRouter(DeliveryStop);
export default withRouter( connect(mapStateToProps, mapDispatchToProps)(DeliveryStop) );

