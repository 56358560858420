import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import { mpoAccount } from '../../lib/Account';
import { mpoAppStore } from '../../lib/AppStore';
import { updateObject, isCordova, attachDeviceInfoToData } from '../../shared/utility';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";

class AccountFeedback extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            submitting: false,
            submitted: false,
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            feedback: {
                ease_of_use: 0, 
                speed: 0,
                overall: 0,
                comments: ""
            },
            appstore: {
                show_review_prompt: false,
                review_prompt_text: '',
                review_app_url: '',
                show_download_prompt: false,
                download_prompt_text: '',
                download_app_url: ''
            }
        }
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Feedback',Sentry.Severity.Info);
        mpoAccount.getAccount(this.onGetAccount, 'Feedback');
    }

    onGetAccount = (response) => {

        if (response.data.ResponseCode === "AUTH") {
            this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
        } else if (response.data.ResponseCode === "SUCCESS") {
            if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', Sentry.Severity.Warning);
            } else {
                this.setState({
                    isLoading: false,
                    modules: {
                        standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                        rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                        topup: response.data.Response.account.is_topup_enabled === 1,
                        facebook: response.data.Response.customer.is_facebook_connected,
                        apple: response.data.Response.customer.is_apple_connected,
                        google: response.data.Response.customer.is_google_connected
                    }
                });
            }
        } else {
            //response.data.ResponseCode === "ERROR"
        }
    }

    onRatingChangeEaseOfUse = (val) => {
        this.setState({feedback: updateObject(this.state.feedback, {
                ease_of_use: val
            })
        });
    }

    onRatingChangeSpeed = (val) => {
        this.setState({feedback: updateObject(this.state.feedback, {
                speed: val
            })
        });
    }

    onRatingChangeOverall = (val) => {
        this.setState({feedback: updateObject(this.state.feedback, {
                overall: val
            })
        });
    }

    onCommentsChange = (e) => {
        this.setState({feedback: updateObject(this.state.feedback, {
                comments: e.target.value
            })
        });
    }

    submitFeedback = () => {

        if (this.state.feedback.speed === 0 ||
            this.state.feedback.speed === 0 ||
            this.state.feedback.overall === 0) {
                mobiscroll.alert({
                    title: 'Feedback',
                    message: 'Tap on the stars to rate your experiences between 1 (poor) and 5 (excellent)'
                });
                return;
        }

        this.setState({
            submitting: true
        });

        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: 'Sending feedback...', duration: 5000, display: 'center', color: 'info'});

        const data = {
            RequestAction: 'Feedback',
            ease_of_use: this.state.feedback.ease_of_use,
            speed: this.state.feedback.speed,
            overall: this.state.feedback.overall,
            comments: this.state.feedback.comments,
        };

        attachDeviceInfoToData(data);
        mpoSentry.addBreadcrumb('action',data.RequestAction,Sentry.Severity.Info);

        axiosInstance.post(null, data)
        .then(response => {
            mobiscroll.notification.dismiss();

            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-1)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-1)', Sentry.Severity.Warning);
            } else if (response.data.ResponseCode === "SUCCESS") {
                //mpoAccount.getAccount(this.onGetAccount);

                /*
                if (response.data.Response.hasOwnProperty('app_store')) {
                    if (isCordova() && parseInt(response.data.Response.app_store.show_review_prompt,10) === 1) {
                        mpoAppStore.inviteUserToReviewApp(response.data.Response.app_store.review_prompt_text, response.data.Response.app_store.review_app_url);
                    } else if (!isCordova() && parseInt(response.data.Response.app_store.show_download_prompt,10) === 1) {
                        mpoAppStore.inviteUserToDownloadApp(response.data.Response.app_store.download_prompt_text, response.data.Response.app_store.download_app_url);
                    }
                }
                */

                if (response.data.Response.hasOwnProperty('app_store') && 
                    ((isCordova() && parseInt(response.data.Response.app_store.show_review_prompt,10) === 1) ||
                     (!isCordova() && parseInt(response.data.Response.app_store.show_download_prompt,10) === 1))) {
                    this.setState({
                        submitted: true,
                        appstore: {
                            show_review_prompt: isCordova() && parseInt(response.data.Response.app_store.show_review_prompt,10) === 1,
                            review_prompt_text: response.data.Response.app_store.review_prompt_text,
                            review_app_url: response.data.Response.app_store.review_app_url,
                            show_download_prompt: !isCordova() && parseInt(response.data.Response.app_store.show_download_prompt,10) === 1,
                            download_prompt_text: response.data.Response.app_store.download_prompt_text,
                            download_app_url: response.data.Response.app_store.download_app_url
                        }
                    });
                } else {
                    mobiscroll.toast({message: 'Feedback sent, thank you!', color: 'success'});
                }

            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }
        })
        .catch(error => {
            mobiscroll.notification.dismiss();
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
            this.setState({
                submitting: false
            });   
        });

    }

    render = () => {
        if (this.state.isLoading) {
            return (
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">Loading...</div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        if (this.state.submitted && (this.state.appstore.show_review_prompt || this.state.appstore.show_download_prompt)) {
            const isIOS = mobiscroll.platform.name === 'ios';
            const appStoreName = isIOS ? "Apple App" : "Google Play";
            let appStoreIcon = "empty icon fas ";
            if (isIOS) {
                appStoreIcon += "fa-apple"; 
            } else { //if (mobiscroll.platform.name === 'android') {
                appStoreIcon += "fa-google-play";
            }

            return (
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{`${process.env.REACT_APP_APP_TITLE} Feedback`}</title>
                        </Helmet>
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">Feedback</div>
                            <div className="mbsc-padding">
                                {this.state.appstore.show_review_prompt ? this.state.appstore.review_prompt_text : this.state.appstore.download_prompt_text }
                            </div>

                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                {this.state.appstore.show_review_prompt ? 
                                <mobiscroll.Button block={true} data-icon={appStoreIcon} onClick={(e) => { e.preventDefault(); mpoAppStore.reviewApp(this.state.appstore.review_app_url); }}>Review on {appStoreName} Store</mobiscroll.Button>
                                :
                                <mobiscroll.Button block={true} data-icon={appStoreIcon} onClick={(e) => { e.preventDefault(); mpoAppStore.downloadApp(this.state.appstore.download_app_url); }}>Download our App</mobiscroll.Button>
                                }
                            </div>

                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        return (
            <React.Fragment>
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{`${process.env.REACT_APP_APP_TITLE} Feedback`}</title>
                        </Helmet>
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Feedback</div>
                            <mobiscroll.Note color="info">
                                We're always listening to feedback to help us improve and make the app work better for you.<br />
                                Please rate your experience of using the app and let us know if you have any comments or suggestions for improvement.
                            </mobiscroll.Note>

                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <p>Tap on the stars to rate your experiences between 1 (poor) and 5 (excellent):</p>
                            </div>

                            <mobiscroll.Rating val="right" min={0} max={5} value={this.state.feedback.ease_of_use} step={1} template="{value}/{max}" onChange={this.onRatingChangeEaseOfUse}>
                                Ease of use
                            </mobiscroll.Rating>

                            <mobiscroll.Rating val="right" min={0} max={5} value={this.state.feedback.speed} step={1} template="{value}/{max}" onChange={this.onRatingChangeSpeed}>
                                Speed
                            </mobiscroll.Rating>

                            <mobiscroll.Rating val="right" min={0} max={5} value={this.state.feedback.overall} step={1} template="{value}/{max}" onChange={this.onRatingChangeOverall}>
                                Overall
                            </mobiscroll.Rating>

                            <mobiscroll.Textarea labelStyle="stacked" icon="pencil" iconAlign="left" placeholder="Type your feedback here" value={this.state.comments} onChange={this.onCommentsChange}>Comments/Suggestions</mobiscroll.Textarea>

                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} disabled={this.state.submitting} onClick={(e) => { e.preventDefault(); this.submitFeedback(); }}>Send Feedback</mobiscroll.Button>
                            </div>
                        </div>
                    </div>
                </mobiscroll.Form>

                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/feedback';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountFeedback));
