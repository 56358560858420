import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import { mpoAccount } from '../../lib/Account';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";
import {isCordova, openWindow} from "../../shared/utility";

const termsUrl = process.env.REACT_APP_APP_TERMS_URL !== undefined && process.env.REACT_APP_APP_TERMS_URL !== null && process.env.REACT_APP_APP_TERMS_URL !== "" ? process.env.REACT_APP_APP_TERMS_URL : null;

class AccountWithdraw extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            withdrawing: false,
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            topup: {
                currency_sign: '', 
                balance: 0.00,
                balance_held: 0.00,
                balance_debit: 0.00,
                balance_transferable: 0.00,
                has_saved_card: false,
                auto_topup_amount: 0.00
            }
        }

    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Withdraw',Sentry.Severity.Info);
        mpoAccount.getAccount(this.onGetAccount, 'Withdraw');
    }

    onGetAccount = (response) => {

        if (response.data.ResponseCode === "AUTH") {
            this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
        } else if (response.data.ResponseCode === "SUCCESS") {
            if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', Sentry.Severity.Warning);
            } else {
                this.setState({
                    isLoading: false,
                    modules: {
                        standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                        rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                        topup: response.data.Response.account.is_topup_enabled === 1,
                        facebook: response.data.Response.customer.is_facebook_connected,
                        apple: response.data.Response.customer.is_apple_connected,
                        google: response.data.Response.customer.is_google_connected
                    },
                    topup: {
                        currency_sign: response.data.Response.account.currency_sign, 
                        balance: parseFloat(response.data.Response.customer.balance),
                        balance_held: parseFloat(response.data.Response.customer.balance_held),
                        balance_debit: parseFloat(response.data.Response.customer.balance_debit),
                        balance_transferable: parseFloat(response.data.Response.customer.balance_transferable),
                        has_saved_card: response.data.Response.customer.has_saved_card,
                        auto_topup_amount: parseFloat(response.data.Response.customer.auto_topup_amount)
                    },
                });
            }
        } else {
            //response.data.ResponseCode === "ERROR"
        }
    }

    showConfirmRequestRefund = () => {
        mobiscroll.confirm({
            title: 'Withdraw Funds',
            message: 'Are you sure you wish to withdraw all transferable funds from your account?',
            okText: 'Yes',
            cancelText: 'No',
            callback: (res) => {
                if (res) {
                    this.requestRefund();
                }
            }
        });
    }

    requestRefund = () => {

        this.setState({
            withdrawing: true
        });

        const data = {
            RequestAction: 'WithdrawFunds',
        };

        axiosInstance.post(null, data)
        .then(response => {
            if (response.data.ResponseCode === "AUTH") {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-1)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-1)', Sentry.Severity.Warning);
            } else if (response.data.ResponseCode === "SUCCESS") {
                mobiscroll.toast({message: 'Your request has been sent', color: 'success'});
                mpoSentry.addBreadcrumb('withdraw','Withdrawal request sent',Sentry.Severity.Info);
                mpoAccount.getAccount(this.onGetAccount, 'Withdraw');
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }
        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    render = () => {
        if (this.state.isLoading) {
            return (
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">Loading...</div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        return (
            <React.Fragment>
                <mobiscroll.Form 
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{`${process.env.REACT_APP_APP_TITLE} Billing`}</title>
                        </Helmet>

                        {this.state.modules.topup || this.state.topup.balance > 0 ?
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Withdraw Funds</div>
                            <mobiscroll.Note color="info">
                                <div className="mbsc-txt-s" style={{textAlign: "left"}}>
                                    <ul>
                                        <li>Withdrawals can only be reimbursed to your original payment method.</li>
                                        <li>Only eligible payments that have been processed within the past 60 days can be refunded.</li>
                                        <li>Store credits are non-transferable and therefore not eligible to be withdrawn here.</li>
                                        {this.props.user.customer.hasOwnProperty('country_code') && this.props.user.customer.country_code === 'AU' ?
                                        <li>If you received a store credit for a product or service as a result of a "major problem" according to Australian Consumer Law, you may contact the store directly to arrange a refund.</li>
                                        :null}
                                        <li>Our full terms are available {termsUrl === null ?
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.props.history.push('/terms');
                                                }}>here</a>
                                                :
                                                (isCordova() ?
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            openWindow(termsUrl, '_system', '');
                                                        }}>here</a>
                                                        :
                                                        <a href={termsUrl} target="_blank">here</a>
                                                )
                                            }.
                                        </li>
                                    </ul>
                                </div>
                            </mobiscroll.Note>

                            <mobiscroll.Input
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance).toFixed(2)}>Credit</mobiscroll.Input>
                            {this.state.topup.balance_debit > 0 ? 
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance_debit).toFixed(2)}>Debit</mobiscroll.Input>
                            : null }
                            {this.state.topup.balance !== this.state.topup.balance_transferable ?
                            <mobiscroll.Input
                                disabled={true}
                                type="text"
                                defaultValue={this.state.topup.currency_sign+parseFloat(this.state.topup.balance_transferable).toFixed(2)}>Transferable</mobiscroll.Input>
                            : null }

                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} disabled={this.state.withdrawing || this.state.topup.balance_transferable <= 0} onClick={(e) => { e.preventDefault(); this.showConfirmRequestRefund(); }}>Request Refund</mobiscroll.Button>
                            </div>

                            <p className="mbsc-align-center mbsc-txt-s" style={{padding: "1em"}}>
                                Withdrawal requests will be reviewed and processed within 2 business days.<br />
                                Card refunds can take up to 10 days to show on your bank statement due to varying processing times between card issuers.
                            </p>

                        </div>
                        : null}
                    </div>
                </mobiscroll.Form>

                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/withdraw';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountWithdraw));
