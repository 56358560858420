import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import { validateCardCSC } from '../../shared/validate';
import { updateObject, logger, isCordova, attachDeviceInfoToData, openWindow, isGoogleMapsLoaded, isIframe } from '../../shared/utility';
import AccountBottomNav from '../../components/account/BottomNav';
import { mpoAccount } from '../../lib/Account';
import { mpoOneSignal } from '../../lib/OneSignal';
import { mpoPayPal } from '../../lib/PayPal';
import { mpoCoinbase } from "../../lib/Coinbase";
import { client as braintree } from 'braintree-web';
import queryString from 'query-string';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import {Helmet} from "react-helmet";
import {ApplePayButton} from "react-apple-pay-button";
import {mpoApplePay} from "../../lib/ApplePay";
import GooglePayButton from "@google-pay/button-react";
import {mpoGooglePay} from "../../lib/GooglePay";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';
const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
let applePayAvailable = false;
let googlePayAvailable = false;

class AccountTopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            toppingUp: false,
            placesSearchOptions: {
                componentRestrictions: {'country': ['AU','NZ']},
                types: ['address']
            },
            account: {},
            customer: {},
            modules: {
                standing_orders: false,
                rewards: false,
                topup: false,
                facebook: false,
                apple: false
            },
            topup: {
                balance: "0.00",
                balance_debit: "0.00",
                balance_transferable: "0.00",
                amount: debugMode ? "1.00" : "30.00",
                payment_method_id: "CC",
                billing_address: "",
                billing_address_id: 0,
                card_name: "",
                card_number: "",
                card_expiry: "",
                card_expiry_date: "",
                card_csc: "",
                save_card: true,
                auto_topup: true
            }
        };
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Topup',Sentry.Severity.Info);
        mpoAccount.getAccount(this.onGetAccount, 'Topup');
        if (!isGoogleMapsLoaded()) {
            mobiscroll.toast({message: 'Google maps not loaded, try again', color: 'danger'});
            mpoSentry.captureMessage('Google maps not loaded', Sentry.Severity.Warning);
        }
    }

    onGetAccount = (response) => {

        if (response.data.ResponseCode === "AUTH") {
            this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
        } else if (response.data.ResponseCode === "SUCCESS") {
            if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', Sentry.Severity.Warning);
            } else {
                //console.log(this.state);

                if (parseInt(response.data.Response.account.is_apple_pay_enabled,10) === 1) {
                    applePayAvailable = mpoApplePay.isApplePayAvailable(0, response.data.Response.account.apple_pay_merchant_id, response.data.Response.account.merchant_name, response.data.Response.account.country_code, response.data.Response.account.currency_code);
                } else {
                    applePayAvailable = false;
                }

                if (parseInt(response.data.Response.account.is_google_pay_enabled,10) === 1) {
                    googlePayAvailable = mpoGooglePay.isGooglePayAvailable(0, response.data.Response.account.google_pay_gateway, response.data.Response.account.google_pay_gateway_id, response.data.Response.account.google_pay_merchant_id, response.data.Response.account.merchant_name, response.data.Response.account.country_code, response.data.Response.account.currency_code);
                } else {
                    googlePayAvailable = false;
                }

                let defaultPaymentMethodId = response.data.Response.customer.has_saved_card ? "SAVED" :
                    (applePayAvailable ? "APPLE" :  (googlePayAvailable ? "GOOGLE" : "CC"));

                if (this.props.hasOwnProperty('location') && this.props.location !== undefined &&
                    this.props.location.hasOwnProperty('search') && this.props.location.search !== undefined &&
                    this.props.location.search !== "") {
                    // returning from paypal
                    const ppValues = queryString.parse(this.props.location.search);
                    logger(ppValues);
                    if (ppValues.result) {
                        logger(ppValues.result);
                        defaultPaymentMethodId = "PP";
                        mpoSentry.addBreadcrumb('topup','PayPal ' + ppValues.result,Sentry.Severity.Info);

                        switch (ppValues.result) {
                            case 'success':
                                mobiscroll.toast({message: 'Thank you for your payment', color: 'success'});
                                break;
                            case 'cancel':
                                mobiscroll.toast({message: "Payment cancelled", color: 'danger'});
                                break;
                            case 'error':
                                let ppErrMsg = "Unknown error PET001";
                                if (ppValues.msg && ppValues.msg !== "") {
                                    ppErrMsg = ppValues.msg;
                                }
                                mobiscroll.toast({message: ppErrMsg, color: 'danger'});
                                break;
                            default:
                                mobiscroll.toast({message: "Unknown result "+ppValues.result+' PRT001', color: 'danger'});
                        }
                    }
                }

                this.setState({
                    isLoading: false,
                    customer: response.data.Response.customer,
                    account: response.data.Response.account,
                    topup: {
                        balance: response.data.Response.account.currency_sign+parseFloat(response.data.Response.customer.balance).toFixed(2),
                        balance_debit: response.data.Response.account.currency_sign+parseFloat(response.data.Response.customer.balance_debit).toFixed(2),
                        amount: response.data.Response.customer.auto_topup_amount !== "0.00" ? response.data.Response.customer.auto_topup_amount : (debugMode ? "1.00" : "30.00"),
                        payment_method_id: defaultPaymentMethodId,
                        billing_address: "",
                        billing_address_id: response.data.Response.customer.billing_addresses.length > 0 ? parseInt(response.data.Response.customer.billing_addresses[0].id,10) : 0,
                        card_name: "",
                        card_number: "",
                        card_expiry: "",
                        card_expiry_date: "",
                        card_csc: "",
                        save_card: true,
                        auto_topup: true
                    },
                    modules: {
                        standing_orders: response.data.Response.account.is_standing_orders_enabled === 1,
                        rewards: response.data.Response.account.is_loyalty_schemes_enabled === 1,
                        topup: response.data.Response.account.is_topup_enabled === 1 && response.data.Response.account.payment_methods.length > 0,
                        facebook: response.data.Response.customer.is_facebook_connected,
                        apple: response.data.Response.customer.is_apple_connected,
                        google: response.data.Response.customer.is_google_connected
                    }
                });
            }
        } else {
            //response.data.ResponseCode === "ERROR"
        }

    }

    getPaymentNavItemsJsx = () => {
        return this.state.account.payment_methods.map((item) => {
            //console.log('item', item);
            if (item.code === 'APPLE' && !applePayAvailable) return;
            if (item.code === 'GOOGLE' && !googlePayAvailable) return;
            let icon = "empty icon fas fa-"+item.icon;
            const itemDesc = /*(item.code === 'APPLE' || item.code === 'GOOGLE') ? null :*/ item.desc;
            return <mobiscroll.NavItem
                        id={item.id} key={item.id} 
                        icon={icon} 
                        isActive={() => this.state.topup.payment_method_id === item.code}
                        selected={this.state.topup.payment_method_id === item.code}
                        replace={true}
                        onClick={this.selectPaymentMethod.bind(null, item.code)} >
                        {itemDesc}
                    </mobiscroll.NavItem>
        });
    }

    selectPaymentMethod = (selectedPaymentMethodCode) => {
        this.setState({topup: updateObject(this.state.topup, {
                payment_method_id: selectedPaymentMethodCode
            })
        });
    }

    onFieldChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = fieldName === 'card_name' ? e.target.value.toUpperCase() : e.target.value;
        //logger('onFieldChange '+fieldName+'='+fieldValue);
        this.setState({topup: updateObject(this.state.topup, {
                [fieldName]: fieldValue
            })
        });
    }

    onCardNumberChange = (e, inst) => {
        this.setState({topup: updateObject(this.state.topup, {
                card_number: e.valueText
            })
        });
    }

    onCardExpiryChange = (e, inst) => {
        //console.log('onCardExpiryChange', e, inst);
        this.setState({topup: updateObject(this.state.topup, {
                card_expiry: e.valueText,
                card_expiry_date: inst.getVal()
            })
        });
    }

    onCardCSCChange = (e, inst) => {
        //logger('onCardCSCChange card_csc='+e.valueText);
        this.setState({topup: updateObject(this.state.topup, {
                card_csc: e.valueText
            })
        });
    }

    onSaveCardChange = (e) => {
        this.setState({topup: updateObject(this.state.topup, {
                save_card: e.target.checked,
                auto_topup: e.target.checked
            })
        });
    }

    onAutoTopupChange = (e) => {
        this.setState({topup: updateObject(this.state.topup, {
                save_card: this.state.topup.save_card || e.target.checked,
                auto_topup: e.target.checked
            })
        });
    }

    setTopupAmount = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.setState({topup: updateObject(this.state.topup, {
                amount: inst.getVal()
            })
        });
    }

    setBillingAddress = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.setState({topup: updateObject(this.state.topup, {
                billing_address_id: parseInt(inst.getVal(),10)
            })
        });
    }

    onBillingAddressChange = (address) => {
        this.setState({topup: updateObject(this.state.topup, {
                billing_address: address,
                billing_geo: {},
                billing_latlng: {},
                billing_address1: "",
                billing_address2: "",
                billing_city: "",
                billing_state: "",
                billing_postcode: "",
                billing_country: ""
            })
        });
    }

    onBillingAddressSelect = (address) => {
        //console.log(address);
        this.onBillingAddressChange(address);
        geocodeByAddress(address)
            .then(results => {
                //console.log('geo', results); 
                const addr = {
                    billing_geo: {},
                    billing_latlng: {},
                    billing_address1: "",
                    billing_address2: "",
                    billing_city: "",
                    billing_state: "",
                    billing_postcode: "",
                    billing_country: "",
                };
                if (results !== undefined && results[0] !== undefined) {
                    addr.billing_geo = results[0];
                    if (results[0].address_components !== undefined) {
                        for (let a of results[0].address_components) {
                            switch (a['types'][0]) {
                                case 'street_number':
                                case 'route':
                                    if (addr.billing_address1 !== "") {
                                        addr.billing_address1 += " ";
                                    }
                                    addr.billing_address1 += a['short_name'];
                                    break;
                                case 'locality':
                                    addr.billing_city = a['short_name'];
                                    break;
                                case 'administrative_area_level_1':
                                    addr.billing_state = a['short_name'];
                                    break;
                                case 'postal_code':
                                    addr.billing_postcode = a['short_name'];
                                    break;
                                case 'country':
                                    addr.billing_country = a['short_name'];
                                    break;
                            }
                        }
                    }
                    this.setState({topup: updateObject(this.state.topup, addr)});
        
                    getLatLng(results[0])
                        .then(latLng => {
                            //console.log('Success2', latLng)
                            this.setState({topup: updateObject(this.state.topup, {
                                    billing_latlng: latLng
                                })
                            });
                        })
                        .catch(error => {
                            //console.error('Error2', error)
                            mpoSentry.captureException(error);
                        });
                }
            })
            .catch(error => {
                //console.error('Error1', error)
                mpoSentry.captureException(error);
            });
    }
    
    onAddressError = (status, clearSuggestions) => {
        mpoSentry.captureMessage('Google Maps API returned error with status: '+status, Sentry.Severity.Warning);
        clearSuggestions();
    }

    getPaymentTabContentJsx = (selectedPaymentMethodCode) => {
        //console.log('getPaymentTabContentJsx', selectedPaymentMethodCode);
        let paymentTabContent = null;
        let ccNotice = '';
        let ccNotice2 = null;
        let ccNotice3 = null;
        let noteColor = 'info';

        switch (selectedPaymentMethodCode) {
            case 'CC':
                const showBillingAddressDropdown = parseInt(this.state.account.is_billing_address_required, 10) === 1 && this.state.customer.billing_addresses.length > 0;
                const showBillingAddressLookup = parseInt(this.state.account.is_billing_address_required, 10) === 1 && (!showBillingAddressDropdown || this.state.topup.billing_address_id === 0);

                let billingAddressDropdown = null;
                if (showBillingAddressDropdown) {
                    billingAddressDropdown = <label>
                        Billing address
                        <mobiscroll.Select
                            select="single"
                            value={this.state.topup.billing_address_id}
                            placeholder="Choose address or enter new one"
                            onSet={this.setBillingAddress}
                        >
                            <option key={0} value={0}>New billing address</option>
                            {this.state.customer.billing_addresses.map((addr, idx) => <option key={addr.id} value={addr.id}>{addr.address}</option>)}
                        </mobiscroll.Select>
                    </label>
                }

                let saveCardJsx = <mobiscroll.Checkbox checked={this.state.topup.save_card} onChange={this.onSaveCardChange}>
                        Save card to my account
                        <span className="mbsc-desc">We use a PCI compliant payment gateway to securely store and process credit/debit cards.</span>
                    </mobiscroll.Checkbox>

                let autoTopupJsx = <mobiscroll.Checkbox checked={this.state.topup.auto_topup} onChange={this.onAutoTopupChange}>
                        Automatic topup
                        <span className="mbsc-desc">Automatically topup by this amount whenever your account balance goes below {this.state.account.currency_sign}5?<br />Ideal for regular users. Paying at the Checkout is recommended for infrequent users.</span>
                    </mobiscroll.Checkbox>

                paymentTabContent = <div>
                    <mobiscroll.Input 
                        labelStyle="stacked"
                        value={this.state.topup.card_name}
                        onChange={this.onFieldChange}
                        style={{textTransform: "upperCase"}}
                        name="cc-name"
                        autoComplete="cc-name"
                        data-fieldname="card_name">Name on Card</mobiscroll.Input>

                    {/*
                    <mobiscroll.Numpad
                        fill="ltr"
                        template="dddd dddd dddd dddd"
                        allowLeadingZero={true}
                        validate={validateCardNumber}
                        placeholder=""
                        showOnFocus={true}
                        onSet={this.onCardNumberChange}>
                        <mobiscroll.Input
                            labelStyle="stacked"
                            value={this.state.topup.card_number}
                            onChange={this.onFieldChange}
                            name="cc-number"
                            autoComplete="cc-number"
                            data-fieldname="card_number">Card Number
                        </mobiscroll.Input>
                    </mobiscroll.Numpad>
                    */}
                    <mobiscroll.Input
                        labelStyle="stacked"
                        value={this.state.topup.card_number}
                        onChange={this.onFieldChange}
                        name="cc-number"
                        type="number"
                        autoComplete="cc-number"
                        maxLength={20}
                        //pattern="^[0-9]{13,16}$"
                        data-fieldname="card_number">Card Number</mobiscroll.Input>

                    <label>
                    Card Expiry
                        <mobiscroll.Date 
                        labelStyle="stacked" 
                            value={this.state.topup.card_expiry_date} 
                            min={new Date()}
                            max={new Date(2099, 11, 1)}
                            dateFormat="mm/yyyy"
                            //returnFormat="locale"
                            name="cc-exp"
                            autoComplete="cc-exp"
                            showOnFocus={true}
                            onSet={this.onCardExpiryChange} 
                            />
                    </label>
                    {/*
                    <mobiscroll.Input
                        labelStyle="stacked"
                        value={this.state.topup.card_expiry}
                        onChange={this.onFieldChange}
                        name="cc-exp"
                        type="number"
                        autoComplete="cc-exp"
                        maxLength={7}
                        placeHolder="mm/yyyy"
                        //pattern="^(0[1-9]|1[0-2])\/([0-9]{4})$"
                        data-fieldname="card_expiry">Card Expiry</mobiscroll.Input>
                    */}

                    <mobiscroll.Numpad
                        fill="ltr"
                        template="dddd"
                        allowLeadingZero={true}
                        validate={validateCardCSC}
                        placeholder=""
                        showOnFocus={true}
                        onSet={this.onCardCSCChange}>
                        <mobiscroll.Input 
                            labelStyle="stacked"
                            value={this.state.topup.card_csc}
                            onChange={this.onFieldChange}
                            name="cc-csc"
                            autoComplete="cc-csc"
                            data-fieldname="card_csc">Security Code (CVC)</mobiscroll.Input>
                    </mobiscroll.Numpad>
                    {/*
                    <mobiscroll.Input
                        labelStyle="stacked"
                        value={this.state.topup.card_csc}
                        onChange={this.onFieldChange}
                        name="cc-csc"
                        type="number"
                        autoComplete="cc-csc"
                        maxLength={4}
                        placeHolder="3 or 4 digits on back of card"
                        //pattern="^[0-9]{3,4}$"
                        data-fieldname="card_csc">Security Code (CVC)</mobiscroll.Input>
                    */}

                    {billingAddressDropdown}

                    {showBillingAddressLookup ? 
                    <PlacesAutocomplete
                        value={this.state.topup.billing_address}
                        onChange={this.onBillingAddressChange}
                        onSelect={this.onBillingAddressSelect}
                        onError={this.onAddressError}
                        searchOptions={this.state.placesSearchOptions}
                        shouldFetchSuggestions={this.state.topup.billing_address.length > 3}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <React.Fragment>
                            <mobiscroll.Input 
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                                labelStyle="stacked" 
                                placeholder="Start typing" 
                                type="text" 
                                name="searchBillingAddress">{showBillingAddressDropdown ? "New billing address" : "Billing address"}</mobiscroll.Input>
                            <div className="autocomplete-dropdown-container">
                            {loading && <div className="suggestion-item">Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active ? 'suggestion-item suggestion-item-active': 'suggestion-item';
                                //console.log(suggestion);
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                                );
                            })}
                            </div>
                        </React.Fragment>
                        )}
                    </PlacesAutocomplete>
                    : null }

                    {saveCardJsx}

                    {autoTopupJsx}
                </div>;
                break;
            case 'SAVED':
                if (this.state.customer.has_saved_card) {
                    ccNotice = '';
                    if (this.state.customer.credit_card_expired === 1) {
                        //ccNotice = 'Your saved credit card has expired.'
                    } else if ((this.state.customer.credit_card_type !== '') && (this.state.customer.credit_card_ending !== '')) {
                        ccNotice = 'You have chosen to pay with your '+this.state.customer.credit_card_type+' ending '+this.state.customer.credit_card_ending;
                        if ((this.state.customer.credit_card_type === 'AMEX' && this.state.account.surcharge_percent_amex > 0) ||
                            (this.state.customer.credit_card_type !== 'AMEX' && this.state.account.surcharge_percent_cc > 0)) {
                            ccNotice += '. A small surcharge may apply';    
                        }
                        ccNotice += '.';
                    } else {
                        ccNotice = 'You have chosen to pay with your saved credit/debit card.';
                    }
                    if (ccNotice !== '') {
                        ccNotice = <span className="mbsc-txt-s">{ccNotice}</span>;
                    }

                    if (this.state.customer.credit_card_notice !== '') {
                        ccNotice2 = <mobiscroll.Note color="danger">{this.state.customer.credit_card_notice}</mobiscroll.Note>
                    }
                    if (parseInt(this.state.customer.credit_card_update,10) === 1) {
                        ccNotice3 = <mobiscroll.Note color="info">Use the New Card option now and tick the box to securely save the card. We use a PCI compliant payment gateway to securely store and process credit/debit cards.</mobiscroll.Note>
                    }
                } else {
                    ccNotice = <mobiscroll.Note color="info">You do not have a saved credit/debit card. Use the New Card option now and tick the box to securely save the card. We use a PCI compliant payment gateway to securely store and process credit/debit cards.</mobiscroll.Note>
                }

                paymentTabContent = <div>
                    {ccNotice}
                    {ccNotice2}
                    {ccNotice3}
                </div>;
                break;

            case 'PP':
                if (isIframe()) {
                    noteColor = 'danger';
                    ccNotice = 'PayPal is unavailable from within an iFrame, please choose another payment method.';
                } else {
                    ccNotice = 'You have chosen to pay with PayPal.';
                    if (this.state.account.surcharge_percent_paypal > 0) {
                        ccNotice += ' A small surcharge may apply.';
                    }
                    ccNotice += ' Click Pay with PayPal to proceed.';
                    ccNotice2 = 'With PayPal you can pay using your PayPal account or any Australian credit or debit card. A PayPal account is not required to pay using a credit or debit card.';
                }

                paymentTabContent = <div>
                    <mobiscroll.Note color={noteColor}>
                    {ccNotice}<br/>
                    {ccNotice2}
                    </mobiscroll.Note>
                </div>;
                break;

            case 'CB':
                if (isIframe()) {
                    ccNotice = 'Cryptocurrency payments are unavailable from within an iFrame, please choose another payment method.';
                // } else if (isCordova()) {
                //     ccNotice = 'Cryptocurrency payments are unavailable from within the app at this time. Please choose another payment method or use our web ordering page.';
                } else {
                    ccNotice = 'You can now securely topup your account with Bitcoin, Ethereum, Litecoin and more. Click Pay with Crypto to proceed. Payments are processed by Coinbase Commerce and you can use your credit whenever you make a purchase to speed up the checkout process.';
                }
                ccNotice = <mobiscroll.Note color="info">{ccNotice}</mobiscroll.Note>
                paymentTabContent = <div>
                    {ccNotice}
                </div>;
                break;

            case 'APPLE':
                if (debugMode) {
                    paymentTabContent = <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                        <p>Available: {applePayAvailable ? "Yes" : "No"}</p>
                        <ApplePayButton className="apple-login-btn" onClick={(e) => {
                            e.preventDefault();
                            mpoApplePay.commencePayment(1.00, 'Test payment', this._wallet_payment_callback)
                        }} theme="dark">
                            {"TEST Top Up with"}
                        </ApplePayButton>
                    </div>;
                } else if (!applePayAvailable) {
                    paymentTabContent = <div>
                        <mobiscroll.Note color="info">Apple Pay is currently unavailable, please choose another payment method.</mobiscroll.Note>
                    </div>;
                }
                break;

            case 'GOOGLE':
                if (debugMode) {
                    // https://www.npmjs.com/package/@google-pay/button-react
                    paymentTabContent = <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                        <p>Available: {googlePayAvailable ? "Yes" : "No"}</p>
                        <button onClick={(e) => { e.preventDefault(); if (!this.state.toppingUp) { this.doTopup(); } }} data-enhanced="false" className={"gpay-button black pay en"} />
                        <GooglePayButton
                            environment="TEST"
                            buttonSizeMode={"fill"}
                            buttonType={"pay"}
                            paymentRequest={{
                                apiVersion: 2,
                                apiVersionMinor: 0,
                                allowedPaymentMethods: [
                                    {
                                        type: 'CARD',
                                        parameters: {
                                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                            allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                        },
                                        tokenizationSpecification: {
                                            type: 'PAYMENT_GATEWAY',
                                            parameters: {
                                                gateway: mpoGooglePay._gateway,
                                                gatewayMerchantId: mpoGooglePay._gateway_id,
                                            },
                                        },
                                    },
                                ],
                                merchantInfo: {
                                    merchantId: mpoGooglePay._merchant_id,
                                    merchantName: mpoGooglePay._merchant_name,
                                },
                                transactionInfo: {
                                    totalPriceStatus: 'FINAL',
                                    totalPriceLabel: 'Total',
                                    totalPrice: this.state.topup.amount,
                                    currencyCode: mpoGooglePay._currency_code,
                                    countryCode: mpoGooglePay._country_code,
                                },
                            }}
                            onLoadPaymentData={paymentRequest => {
                                //console.log('load payment data', paymentRequest);
                                this._wallet_payment_callback(true, paymentRequest.paymentMethodData.tokenizationData.token, 'Google', this.state.topup.amount);
                            }}
                        />
                    </div>;
                } else if (!googlePayAvailable) {
                    paymentTabContent = <div>
                        <mobiscroll.Note color="info">Google Pay is currently unavailable, please choose another payment method.</mobiscroll.Note>
                    </div>;
                }
                break;

            default:
                paymentTabContent = <div>
                    <mobiscroll.Note color="danger">Please choose a payment method.</mobiscroll.Note>
                </div>;
        }

        return paymentTabContent;

    }

    doTopup = (nonce = null) => {

        if (this.state.topup.payment_method_id === 'PP' && isIframe()) {
            mobiscroll.toast({message: "PayPal is unavailable from within an iFrame, please choose another payment method.", duration: 6000, color: 'danger'});
            return;
        }

        if (this.state.topup.payment_method_id === 'CB' && isIframe()) {
            mobiscroll.toast({message: "Cryptocurrency payments are unavailable from within an iFrame, please choose another payment method.", duration: 6000, color: 'danger'});
            return;
        }

        if (this.state.topup.payment_method_id === 'APPLE') {
            mpoApplePay.commencePayment(this.state.topup.amount, 'Topup payment', this._wallet_payment_callback);
            return;
        }

        if (this.state.topup.payment_method_id === 'GOOGLE') {
            mpoGooglePay.commencePayment(this.state.topup.amount, 'Topup payment', this._wallet_payment_callback);
            return;
        }

        this.setState({
            toppingUp: true
        });
        mpoSentry.addBreadcrumb('topup','doTopup',Sentry.Severity.Info);
        mobiscroll.notification.dismiss();
        const isBraintreeEnabled = parseInt(this.state.account.is_braintree_payments_enabled,10) === 1;

        let data = null;
        if (this.state.topup.payment_method_id === 'PP') {
            mobiscroll.toast({
                message: 'Requesting PayPal payment...',
                duration: 3000,
                display: 'center',
                color: 'info'
            });
            data = {
                RequestAction: 'TopupPayPal',
            }
        } else if (this.state.topup.payment_method_id === 'CB') {
            mobiscroll.toast({
                message: 'Requesting Coinbase payment...',
                duration: 3000,
                display: 'center',
                color: 'info'
            });
            let returnurl = window.location.protocol + '//' + window.location.hostname + '/#' + this.props.history.location.pathname;
            if (process.env.NODE_ENV !== 'production') {
                if (window.location.port !== 80 && window.location.port !== 443) {
                    returnurl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/#' + this.props.history.location.pathname;
                }
            }
            data = {
                RequestAction: 'TopupCoinbase',
                return_url: returnurl
            }
        } else if (this.state.topup.payment_method_id === 'SAVED') {
            mobiscroll.toast({message: 'Requesting Card payment...', duration: 3000, display: 'center', color: 'info'});
            data = {
                RequestAction: 'TopupExistingCard',
            }
        } else if (isBraintreeEnabled && nonce === null) {
            // https://developers.braintreepayments.com/guides/client-sdk/setup/javascript/v3
            mobiscroll.toast({message: 'Contacting Braintree Payments...', duration: 3000, display: 'center', color: 'info'});
            braintree.create({
                authorization: this.state.account.braintree_tokenization_key
            }, (err, client) => {
                logger(err);
                //logger(client);
                if (client) {
                    client.request({
                        endpoint: 'payment_methods/credit_cards',
                        method: 'post',
                        data: {
                            creditCard: {
                                number: this.state.topup.card_number,
                                expirationDate: this.state.topup.card_expiry,
                                cvv: this.state.topup.card_csc,
                                billingAddress: {
                                    postalCode: this.state.topup.billing_postcode
                                }
                            }
                        }
                    }, (err, response) => {
                        logger(err);
                        //logger(response);
                        if (err) {
                            mobiscroll.toast({message: "Error contacting Braintree Payments", duration: 6000, color: 'danger'});
                        } else {
                            // Send response.creditCards[0].nonce to your server
                            this.doTopup(response.creditCards[0].nonce);
                        }
                    });
                }
            });
            return;
        } else {
            mobiscroll.toast({message: 'Requesting Card payment...', duration: 3000, display: 'center', color: 'info'});
            data = {
                RequestAction: 'TopupNewCard',
                card_name: this.state.topup.card_name,
                card_number: this.state.topup.card_number,
                card_expiry: this.state.topup.card_expiry,
                card_csc: this.state.topup.card_csc,
                save_card: this.state.topup.save_card ? 1 : 0,
                auto_topup: this.state.topup.auto_topup ? 1 : 0,
                billing_address_id: this.state.topup.billing_address_id,
                billing_address_str: this.state.topup.billing_address,
                billing_address1: this.state.topup.billing_address1,
                billing_address2: this.state.topup.billing_address2,
                billing_city: this.state.topup.billing_city,
                billing_state: this.state.topup.billing_state,
                billing_postcode: this.state.topup.billing_postcode,
                billing_country: this.state.topup.billing_country,
                billing_geo: this.state.topup.billing_geo,
                billing_latlng: this.state.topup.billing_latlng,
                braintree_nonce: isBraintreeEnabled ? nonce : null
            }
        }
        data.topup_amount = this.state.topup.amount;

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        //logger(data);
        axiosInstance.post(null, data)
        .then(response => {
            logger(response);
            mobiscroll.notification.dismiss();
            if (response.data.ResponseCode === "SUCCESS") {
                if (response.data.Response.hasOwnProperty('payment_method') && response.data.Response.payment_method === "PP" &&
                    response.data.Response.hasOwnProperty('payment_redirect') && response.data.Response.payment_redirect !== "") {
                    mpoSentry.addBreadcrumb('topup', 'PayPal redirect', Sentry.Severity.Info);
                    //logger(response.data.Response.payment_redirect);

                    if (isCordova()) {
                        logger('pg app - open inapp browser');
                        mpoPayPal._inapp_browser_result = null;
                        mpoPayPal._inapp_browser = openWindow(response.data.Response.payment_redirect, '_blank', 'location=no', isCustomApp);
                        mpoPayPal._inapp_browser.addEventListener('loadstop', mpoPayPal._in_app_browser_load_stop);
                        mpoPayPal._inapp_browser.addEventListener('loaderror', mpoPayPal._in_app_browser_load_error);
                        mpoPayPal._inapp_browser.addEventListener('exit', this._in_app_browser_exit_paypal);
                    } else {
                        logger('web app - open in current window');
                        mobiscroll.notification.dismiss();
                        mobiscroll.toast({
                            message: "Contacting PayPal (patience)...",
                            duration: 15000,
                            display: 'center',
                            color: 'info'
                        });
                        let returnurl = window.location.protocol + '//' + window.location.hostname + '/#' + this.props.history.location.pathname;
                        if (process.env.NODE_ENV !== 'production') {
                            if (window.location.port !== 80 && window.location.port !== 443) {
                                returnurl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/#' + this.props.history.location.pathname;
                            }
                        }
                        returnurl = encodeURIComponent(returnurl);
                        logger(returnurl);
                        const cancelurl = returnurl;
                        const errorurl = returnurl;
                        const paypalurl = response.data.Response.payment_redirect + '&returnurl=' + returnurl + '&cancelurl=' + cancelurl + '&errorurl=' + errorurl;
                        logger(paypalurl);
                        window.location.replace(paypalurl);
                    }
                } else if (response.data.Response.hasOwnProperty('payment_method') && response.data.Response.payment_method === "CB" &&
                        response.data.Response.hasOwnProperty('payment_redirect') && response.data.Response.payment_redirect !== "") {
                    mpoSentry.addBreadcrumb('topup','Coinbase redirect',Sentry.Severity.Info);
                    if (isCordova()) {
                        mpoCoinbase._inapp_browser_result = null;
                        mpoCoinbase._inapp_browser = openWindow(response.data.Response.payment_redirect, '_blank', 'location=no', isCustomApp);
                        mpoCoinbase._inapp_browser.addEventListener('loadstop', mpoCoinbase._in_app_browser_load_stop);
                        mpoCoinbase._inapp_browser.addEventListener('loaderror', mpoCoinbase._in_app_browser_load_error);
                        mpoCoinbase._inapp_browser.addEventListener('exit', this._in_app_browser_exit_coinbase);
                    } else {
                        mobiscroll.notification.dismiss();

                        // would prefer to open in iframe overlay
                        /*
                        mobiscroll.toast({
                            message: "Contacting Coinbase...",
                            duration: 15000,
                            display: 'center',
                            color: 'info'
                        });
                        window.location.replace(response.data.Response.payment_redirect);
                         */

                        mobiscroll.toast({
                            message: "Opening Coinbase...",
                            duration: 5000,
                            display: 'center',
                            color: 'info'
                        });
                        openWindow(response.data.Response.payment_redirect, '_system', '')
                        this.setState({
                            toppingUp: false
                        });

                    }
                } else {
                    this.setState({
                        toppingUp: false
                    });
                    mobiscroll.toast({message: 'Thank you for your payment', color: 'success'});
                    mpoAccount.getAccount(this.onGetAccount, 'Topup');
                }
            } else {
                this.setState({
                    toppingUp: false
                });
                let errorMsg = response.data.Response[0];
                mobiscroll.toast({message: errorMsg, duration: 6000, color: 'danger'});
                mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);
            }
        })
        .catch(error => {
            mobiscroll.notification.dismiss();
            logger(error);
            this.setState({
                toppingUp: false
            });
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });
    }

    _wallet_payment_callback = (success, wallet_data, wallet, amount) => {

        if (success) {
            //logger(wallet_data);
            if (wallet_data) {
                // send to backend to process payment
                const data = {
                    RequestAction: 'Topup'+wallet+'Pay', // Apple or Google
                    topup_amount: amount,
                    wallet_data: wallet_data,
                };

                axiosInstance.post(null, data)
                    .then(response => {
                        //console.log(response);
                        if (response.data.ResponseCode === "AUTH") {
                            if (wallet === 'Apple') mpoApplePay.failedPayment('Session expired');
                            mobiscroll.toast({message: 'Session expired, sign in to try again (MSG TUAP-AUTH-0)', color: 'danger'});
                            mpoSentry.captureMessage('Session expired (MSG TUAP-AUTH-0)', Sentry.Severity.Warning);
                        } else if (response.data.ResponseCode === "SUCCESS") {
                            if (wallet === 'Apple') mpoApplePay.successPayment();
                            mobiscroll.toast({message: 'Thank you for your payment', color: 'success'});
                            mpoAccount.getAccount(this.onGetAccount, 'Topup');
                        } else {
                            if (wallet === 'Apple') mpoApplePay.failedPayment(response.data.Response[0]);
                            mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                            mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                        }
                    })
                    .catch(error => {
                        logger(error);
                        if (wallet === 'Apple') mpoApplePay.failedPayment('Unknown error');
                        mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                        mpoSentry.captureException(error);
                    });

            } else {
                mobiscroll.toast({message: 'Thank you for your payment', color: 'success'});
            }
        } else {
            if (wallet_data) {
                mobiscroll.toast({message: wallet_data, color: 'danger'});
            }
        }

    }

    _in_app_browser_exit_paypal = (event) => {
        logger('Topup._in_app_browser_exit_paypal() called');
        logger(event);
        mpoPayPal._inapp_browser.removeEventListener('loadstop', mpoPayPal._in_app_browser_load_stop);
        mpoPayPal._inapp_browser.removeEventListener('loaderror', mpoPayPal._in_app_browser_load_error);
        mpoPayPal._inapp_browser.removeEventListener('exit', this._in_app_browser_exit_paypal);
        mpoPayPal._inapp_browser = null;
        mobiscroll.notification.dismiss();

        logger('mpoPayPal._inapp_browser_result = ' + mpoPayPal._inapp_browser_result);
        switch (mpoPayPal._inapp_browser_result) {
            case 'pp_success':
                //all good, show success
                mobiscroll.toast({message: 'Thank you for your payment', color: 'success'});
                mpoAccount.getAccount(this.onGetAccount, 'Topup');
                break;
            case 'pp_cancel':
                //nothing to do, user knows they cancelled
                mpoSentry.captureMessage('Action cancelled', Sentry.Severity.Warning);
                mobiscroll.toast({message: 'Action cancelled', color: 'info'});
                break;
            case 'pp_error':
                //fetch and display error to user
                var errMsg = "An error occurred while attempting to authenticate with PayPal";
                mpoSentry.captureMessage(errMsg, Sentry.Severity.Warning);
                mobiscroll.toast({message: errMsg, color: 'error'});
                break;
            default:
                // user cancelled
                // var defaultMsg = "An unknown result was specified";
                // mpoSentry.captureMessage(defaultMsg, Sentry.Severity.Warning);
                // mobiscroll.toast({message: defaultMsg, color: 'error'});
        }
        mpoPayPal._inapp_browser_result = null;
        this.setState({
            toppingUp: false
        });
    }

    _in_app_browser_exit_coinbase = (event) => {
        logger('Topup._in_app_browser_exit_coinbase() called');
        logger(event);
        mpoCoinbase._inapp_browser.removeEventListener('loadstop', mpoCoinbase._in_app_browser_load_stop);
        mpoCoinbase._inapp_browser.removeEventListener('loaderror', mpoCoinbase._in_app_browser_load_error);
        mpoCoinbase._inapp_browser.removeEventListener('exit', this._in_app_browser_exit_coinbase);
        mpoCoinbase._inapp_browser = null;
        mobiscroll.notification.dismiss();

        // coinbase doesn't redirect back to the app, so there will be no result at this point;
        //  instead, coinbase will communicate with the backend via webhook and we'll notify the customer when the credit is validated and applied
        logger('mpoCoinbase._inapp_browser_result = ' + mpoCoinbase._inapp_browser_result);
        /*
        switch (mpoCoinbase._inapp_browser_result) {
            case 'cb_success':
                //all good, show success
                mobiscroll.toast({message: 'Thank you for your payment. You will receive an email once the payment has been verified on the blockchain and credited to your account.', color: 'success'});
                mpoAccount.getAccount(this.onGetAccount);
                break;
            case 'cb_cancel':
                //nothing to do, user knows they cancelled
                mpoSentry.captureMessage('Action cancelled', Sentry.Severity.Warning);
                mobiscroll.toast({message: 'Action cancelled', color: 'info'});
                break;
            case 'cb_error':
                //fetch and display error to user
                var errMsg = "An error occurred";
                mpoSentry.captureMessage(errMsg, Sentry.Severity.Warning);
                mobiscroll.toast({message: errMsg, color: 'error'});
                break;
            default:
                var defaultMsg = "An unknown result was specified";
                mpoSentry.captureMessage(defaultMsg, Sentry.Severity.Warning);
                mobiscroll.toast({message: defaultMsg, color: 'error'});
        }
        */
        mpoCoinbase._inapp_browser_result = null;
        this.setState({
            toppingUp: false
        });
    }

    render = () => {

        const paymentNavItems = !this.state.isLoading ? this.getPaymentNavItemsJsx() : null;
        const paymentTabContent = !this.state.isLoading ? this.getPaymentTabContentJsx(this.state.topup.payment_method_id) : null;

        const currencySign = this.state.account.currency_sign;
        const topupAmounts = [
            {id: 1, val: "10.00", amount: currencySign+"10.00"},
            {id: 2, val: "20.00", amount: currencySign+"20.00"},
            {id: 3, val: "30.00", amount: currencySign+"30.00"},
            {id: 4, val: "40.00", amount: currencySign+"40.00"},
            {id: 5, val: "50.00", amount: currencySign+"50.00"},
            {id: 6, val: "100.00", amount: currencySign+"100.00"},
            {id: 7, val: "150.00", amount: currencySign+"150.00"},
            {id: 8, val: "200.00", amount: currencySign+"200.00"},
            {id: 9, val: "250.00", amount: currencySign+"250.00"},
        ];
        if (debugMode) {
            topupAmounts.unshift({id: 0, val: "1.00", amount: currencySign+"1.00"},);
        }

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_TITLE} Topup`}</title>
                </Helmet>
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                {this.state.isLoading ?
                    <div className="mbsc-form-group">
                        <div className="mbsc-form-group-title">Loading...</div>
                    </div>
                :
                <React.Fragment>
                    {this.state.modules.topup ? 
                    <React.Fragment>
                        <mobiscroll.FormGroup inset className="mpo-checkout-form">
                            <div className="mbsc-form-group-title">Account Topup</div>
                            <label>
                                Topup amount
                                <mobiscroll.Select
                                    select="single"
                                    value={this.state.topup.amount}
                                    onSet={this.setTopupAmount}
                                >
                                    {topupAmounts.map((amt, idx) => <option key={amt.id} value={amt.val}>{amt.amount}</option>)}
                                </mobiscroll.Select>
                            </label>
                        </mobiscroll.FormGroup>
                        <mobiscroll.FormGroup inset className="mpo-checkout-form">
                            <div className="mbsc-form-group-title">Payment</div>
                            <mobiscroll.TabNav display="inline">
                                {paymentNavItems}
                            </mobiscroll.TabNav>
                        </mobiscroll.FormGroup>
                        <mobiscroll.FormGroup inset className="mpo-checkout-form">
                            {paymentTabContent}
                        </mobiscroll.FormGroup>

                        <div className="mbsc-btn-group-block">
                            {this.state.topup.payment_method_id === 'PP' ?
                            <React.Fragment>
                                {/*
                                <div style={{textAlign: "center"}}>
                                    <a href="#" onClick={(e) => { e.preventDefault(); this.doTopup(true); }}><img src="./img/paypal-checkout-logo-large.png" /></a>
                                </div>
                                */}
                                <mobiscroll.Button onClick={(e) => { e.preventDefault(); this.doTopup(); }} disabled={this.state.toppingUp} color="success" style={{color: '#fff'}}>Pay with PayPal</mobiscroll.Button>
                            </React.Fragment>
                            :
                            (this.state.topup.payment_method_id === 'APPLE' ?
                            <ApplePayButton className="apple-login-btn" onClick={(e) => { e.preventDefault(); if (!this.state.toppingUp) { this.doTopup(); } }} theme="dark">
                                {"Top Up with"}
                            </ApplePayButton>
                            :
                            (this.state.topup.payment_method_id === 'GOOGLE' ?
                                (isCordova() ?
                                    <button onClick={(e) => { e.preventDefault(); if (!this.state.toppingUp) { this.doTopup(); } }} data-enhanced="false" className={"gpay-button black pay en"} />
                                        :
                                    <GooglePayButton
                                        environment="PRODUCTION"
                                        buttonSizeMode={"fill"}
                                        buttonType={"pay"}
                                        paymentRequest={{
                                            apiVersion: 2,
                                            apiVersionMinor: 0,
                                            allowedPaymentMethods: [
                                                {
                                                    type: 'CARD',
                                                    parameters: {
                                                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                                        allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                                    },
                                                    tokenizationSpecification: {
                                                        type: 'PAYMENT_GATEWAY',
                                                        parameters: {
                                                            gateway: mpoGooglePay._gateway,
                                                            gatewayMerchantId: mpoGooglePay._gateway_id,
                                                        },
                                                    },
                                                },
                                            ],
                                            merchantInfo: {
                                                merchantId: mpoGooglePay._merchant_id,
                                                merchantName: mpoGooglePay._merchant_name,
                                            },
                                            transactionInfo: {
                                                totalPriceStatus: 'FINAL',
                                                totalPriceLabel: 'Total',
                                                totalPrice: this.state.topup.amount,
                                                currencyCode: mpoGooglePay._currency_code,
                                                countryCode: mpoGooglePay._country_code,
                                            },
                                        }}
                                        onLoadPaymentData={paymentRequest => {
                                            //console.log('load payment data', paymentRequest);
                                            this._wallet_payment_callback(true, paymentRequest.paymentMethodData.tokenizationData.token, 'Google', this.state.topup.amount);
                                        }}
                                    />
                                )
                            :
                            (this.state.topup.payment_method_id === 'CB' ?
                            <React.Fragment>
                                <mobiscroll.Button onClick={(e) => { e.preventDefault(); this.doTopup(); }} disabled={this.state.toppingUp} color="success" style={{color: '#fff'}}>Pay with Crypto</mobiscroll.Button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="mbsc-align-center">Payment will appear on your statement as <span style={{fontWeight: 'bold'}}>{this.state.account.merchant_name}</span></div>
                                <mobiscroll.Button onClick={(e) => { e.preventDefault(); this.doTopup(); }} disabled={this.state.toppingUp} color="success" style={{color: '#fff'}}>Pay Now</mobiscroll.Button>
                            </React.Fragment>
                            )
                            )
                            )
                            }
                            {!isCustomApp && this.state.topup.payment_method_id === 'PP' && this.state.account.surcharge_percent_paypal > 0 ?
                            <div className="mbsc-align-center mbsc-txt-s">A small surcharge may apply to payments made through PayPal</div>
                            : null }
                            {this.state.topup.payment_method_id === 'CB' ?
                            <div className="mbsc-align-center mbsc-txt-s">Cryptocurrency payments can take several minutes to be validated by the relevant blockchain. You will receive a notification from us when the transaction has been completed and your account has been credited.</div>
                            : null }

                            {this.state.topup.payment_method_id === 'CB' && isCordova() && !mpoOneSignal.IsRegistered() && mobiscroll.platform.name === 'ios' ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); mpoOneSignal.DisplayRegisterPopup(); }}>Enable Push Notifications</mobiscroll.Button>
                            </div>
                            : null}

                        </div> 

                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Account Balance</div>
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                value={this.state.topup.balance}>Credit</mobiscroll.Input>
                            {this.state.topup.balance_debit !== "$0.00" ? 
                            <mobiscroll.Input 
                                disabled={true}
                                type="text" 
                                defaultValue={this.state.topup.balance_debit}>Debit</mobiscroll.Input>
                            : null }
                        </div>

                    </React.Fragment>
                    :
                    <div className="mbsc-empty">
                        <h3>Currently unavailable</h3>
                        <p>Please pay at checkout</p>
                    </div>
                    }
                </React.Fragment>
                }
                </mobiscroll.Form>

                {!this.state.isLoading ?
                <AccountBottomNav showRewards={this.state.modules.rewards} showTopup={this.state.modules.topup} thirdPartyConnected={this.state.modules.facebook || this.state.modules.apple || this.state.modules.google} />
                : null }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/topup';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountTopup));
