import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import {attachDeviceInfoToData, isCordova, nl2br} from '../../shared/utility';
import {mpoOneSignal} from "../../lib/OneSignal";

class OrderItem extends Component {

    repeatOrder = () => {
        mobiscroll.notification.dismiss();
        mobiscroll.toast({message: 'Loading...', duration: 3000, display: 'center', color: 'info'});
        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'repeat_order',
            store_id: this.props.merchantId,
            menu_id: this.props.menuId,
            order_id: this.props.item.id,
            component: 'OrderItem'
        };
        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }
        axiosInstance.post(null, data)
            .then(response => {
                //logger(response);
                mobiscroll.notification.dismiss();
                if (response.data.ResponseCode === "SUCCESS") {
                    //this.props.history.push('/cart');
                    const location = {
                        pathname: '/checkout',
                        state: { fromRepeatOrder: true }
                    }
                    this.props.history.push(location);
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                }
            })
            .catch(error => {
                //logger(error);
                mobiscroll.notification.dismiss();
                mobiscroll.toast({message: 'Error OI1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });            
    }

    render = () => {
        //console.log(this.props);
        let style = this.props.borderPos === "none" ? {padding: "0.5em 0"} : (this.props.borderPos === "top" ? {padding: "0.5em 0", borderTop: "#efeff4 solid 1px", marginTop: "1em"} : {padding: "0.5em 0", borderBottom: "#efeff4 solid 1px"});
        return (
            <div key={this.props.item.id} style={style}>
                <span className="mbsc-txt-s mbsc-pull-right"><mobiscroll.Button flat={true} onClick={(e) => { e.preventDefault(); this.repeatOrder(); }}>Repeat Order</mobiscroll.Button></span>
                <span className="empty icon fas fa-file-alt"></span> <span className="mbsc-txt-s" dangerouslySetInnerHTML={{__html: nl2br(this.props.item.items_desc)}}></span>
                {/*<span className="mbsc-desc">${this.props.item.sub_total}</span>*/}
            </div>
        );
    }
}

export default withRouter(OrderItem);